import { HiMenuAlt2 } from "react-icons/hi";
import useStore from "../store";

import Search from "./search";
import NavBarDropDown from "./navbarDropDown";
import Notifications from "./notifications";
import userStore from "../store/user";
import { useQuery } from "react-query";
import { getData } from "../../utils/api";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const NavBar = () => {
  const navigate = useNavigate();

  const smallNav = useStore((state) => state.smallNav);
  const setShowMobileNav = useStore((state) => state.setNavState);

  const setSignedInUser = userStore((state) => state.setSignedInUser);
  const setSignedInUserLoading = userStore(
    (state) => state.setSignedInUserLoading,
  );

  const { isLoading, isError, error } = useQuery({
    queryKey: ["user"],
    queryFn: async () =>
      getData("/admin/fetch_details", localStorage.getItem("evently")),
    onSuccess: (data) => {
      const { first_name, last_name, position } = data.data.data;

      setSignedInUser({
        initials: `${first_name[0]}${last_name[0]}`,
        lastName: last_name,
        firstName: first_name,
        referenceIndicator: position,
      });
    },
  });

  useEffect(() => {
    setSignedInUserLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (isError && error.response && error.response.status === 401) {
      localStorage.removeItem("evently");
      toast.error("Session has expired, please sign in.");
      navigate("/sign-in");
      return;
    }

    if (isError && error.response) {
      const message = `Navbar Component: ${error.response.status}:\n\n ${error.response.data.message}`;
      toast.error(message);
    }

    if (isError && !error.response) toast.error(error.message);
  }, [isError]);

  return (
    <div className="border-l py-5">
      <div className="max-w-[80rem] mx-auto h-full w-full grid grid-rows-[max-content_1fr] lg:px-5 px-0">
        <div className="grid lg:grid-cols-[2fr_max-content] grid-cols-1 lg:items-center items-stretch justify-between  border-gray-300 lg:px-4 px-4 relative gap-y-5">
          <div className="lg:block hidden w-full">
            <Search />
          </div>

          <div className="flex lg:justify-end justify-between w-full">
            <span
              onClick={() => setShowMobileNav(!smallNav)}
              className="cursor-pointer lg:hidden flex "
            >
              <HiMenuAlt2 className="text-[2.4rem]" />
            </span>

            <div className="flex items-center justify-center gap-4">
              <Notifications />
              <NavBarDropDown />
            </div>
          </div>

          {/* Small Screens */}
          <div className="lg:hidden block">
            <Search />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
