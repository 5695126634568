import { create } from "zustand";

import eventsStore from "./events";

const searchStore = create((set) => ({
  searchResult: {
    currentValue: "",
    events: [],
    requests: [],
    found: false,
  },

  search: (searchValue, events, requests) =>
    set(() => {
      const filteredEvents = events.filter((item) =>
        item.event_title.toLowerCase().includes(searchValue),
      );

      const filteredRequests = requests.filter((item) =>
        item.event_title.toLowerCase().includes(searchValue),
      );

      return {
        searchResult: {
          events: filteredEvents,
          requests: filteredRequests,
          currentValue: searchValue,
          found:
            Boolean(filteredEvents.length) || Boolean(filteredRequests.length)
              ? true
              : false,
        },
      };
    }),
}));

export default searchStore;
