import { BiCaretRight, BiPlus } from "react-icons/bi";
import imagePropOne from "../../../assets/images/dashboard-prop-1.svg";
import imagePropTwo from "../../../assets/images/dashboard-prop-2.svg";
import imagePropThree from "../../../assets/images/dashboard-prop-3.svg";
import imagePropFour from "../../../assets/images/dashboard-prop-4.svg";
import Button from "../../common/button";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import TruncateText from "../../common/truncateText";
import userStore from "../../store/user";
import useStore from "../../store";
import statsStore from "../../store/stats";
import { useQuery } from "react-query";
import { toast } from "react-hot-toast";
import { getData } from "../../../utils/api";

const Stats = () => {
  const user = userStore((state) => state.signedInUser);
  const adsList = useStore((state) => state.adsList);
  const setAdsList = useStore((state) => state.setAdsList);
  const showAds = useStore((state) => state.showAds);
  const navigate = useNavigate();
  const signedInUserLoading = userStore((state) => state.signedInUserLoading);

  const statsLoading = statsStore((state) => state.statsLoading);
  const setStatsLoading = statsStore((state) => state.setStatsLoading);
  const stats = statsStore((state) => state.stats);
  const setStats = statsStore((state) => state.setStats);
  const [currentSlide, setCurrentSlide] = useState(0);

  const [currentIndex, setCurrentIndex] = useState(0);

  // Use useEffect to handle changes in items or currentIndex

  const { isLoading } = useQuery({
    queryKey: ["counter"],
    queryFn: async () =>
      getData("/admin/event_counter", localStorage.getItem("evently")),
    onSuccess: (data) => {
      const { event_in_progress, event_archive, event_completed } =
        data.data.data;

      setStats({
        approved: event_in_progress,
        archive: event_archive,
        events: {
          completed: event_completed,
          lastMonth: 0,
        },
      });
    },

    onError: (error) => {
      if (error.response) {
        const message = `Stats Component: ${error.response.status}:\n\n ${error.response.data.message}`;
        toast.error(message);
      }

      if (!error.response) toast.error(error.message);
    },
  });

  const adsQuery = useQuery({
    queryKey: ["ads"],
    queryFn: async () =>
      getData("/admin/fetch_ads", localStorage.getItem("evently")),
    onSuccess: (data) => {
      setAdsList(data.data.data);
    },
  });

  useEffect(() => {
    setStatsLoading(isLoading);
  }, [isLoading]);

  const status = [
    {
      id: 1,
      count: stats.events.completed,
      label: "Event completed",
      icon: imagePropTwo,
      cta: null,
      detail: {
        count: stats.events.lastMonth,
        label: "since last month",
      },
    },
    {
      id: 2,
      count: stats.approved,
      label: "In progress",
      cta: { path: "/events", label: "See events" },
      icon: imagePropThree,
    },
    {
      id: 3,
      count: stats.archive,
      label: "Archive",
      cta: { path: "/archive", label: "Check archive" },
      icon: imagePropFour,
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === adsList.length - 1 ? 0 : prevIndex + 1,
      );
    }, 10000); // Adjust the delay as needed

    return () => clearInterval(interval);
  }, [currentIndex, adsList]);

  // Handle items being loaded or changed asynchronously
  useEffect(() => {
    // Reset the currentIndex when items change
    setCurrentIndex(0);
  }, [adsList]);

  // Render the current item
  const currentItem = adsList[currentIndex];

  return (
    <section
      className={`grid lg:grid-rows-[max-content_1fr] ${
        showAds === "yes" ? "gap-12" : "lg:gap-12 gap-0"
      }`}
    >
      <section
        className={`grid ${
          showAds === "yes" ? "lg:grid-cols-2" : "lg:grid-cols-1"
        } grid-cols-1 gap-8 `}
      >
        <span
          onClick={() => navigate("/create-event")}
          className="lg:hidden fixed bottom-10 right-10 z-30 h-[3rem] w-[3rem] text-3xl rounded-full flex items-center justify-center bg-yellow-600 cursor-pointer shadow-lg"
        >
          <BiPlus />
        </span>

        {!signedInUserLoading && (
          <div className="bg-white rounded-lg lg:grid hidden grid-cols-[1fr_10rem] items-center ">
            <div className="p-7 flex flex-col gap-1">
              <h6 className="h7 font-[600]">
                Hello,{" "}
                <TruncateText
                  className="text-green-600 inline"
                  wordLength={2}
                  text={`${user.firstName} ${user.lastName}`}
                />{" "}
                🥳
              </h6>
              <p className="text-gray-700 text-xs">
                What do you want to do today?
              </p>

              <div className="mt-7">
                <Button
                  variant="filled"
                  colorScheme="primary"
                  size="md"
                  icon={<BiPlus />}
                  onClick={() => navigate("/create-event")}
                >
                  Create Event
                </Button>
              </div>
            </div>

            <div className="flex items-end justify-end h-full">
              <img src={imagePropOne} />
            </div>
          </div>
        )}

        {signedInUserLoading && !adsQuery.isLoading && (
          <div className="grid lg:grid-cols-2 grid-cols-1 items-center gap-5 col-span-2">
            <div
              className={`animate-pulse bg-gray-600 rounded-xl h-[12rem] lg:block hidden`}
            ></div>

            <div
              className={`animate-pulse bg-gray-600 rounded-xl h-[12rem]`}
            ></div>
          </div>
        )}

        {!signedInUserLoading && showAds === "yes" && (
          <div className="flex flex-col gap-2">
            <div
              className={`bg-yellow-600 overflow-hidden rounded-lg w-full lg:h-[12rem] h-[10rem]`}
            >
              <Link target="_blank" to={currentItem?.ad_url}>
                <img
                  className="w-full h-full object-cover"
                  src={currentItem?.ad_image}
                />
              </Link>
            </div>

            <div className="flex items-center justify-center gap-2">
              {adsList.map((item, index) => (
                <span
                  key={index}
                  onClick={() => setCurrentIndex(index)}
                  className={`cursor-pointer h-[6px] w-[2.5rem] rounded ${
                    index === currentIndex ? "bg-green-600" : "bg-green-200"
                  }`}
                ></span>
              ))}
            </div>
          </div>
        )}
      </section>

      {!statsLoading && (
        <section className="grid lg:grid-cols-3 grid-cols-1 gap-8">
          {status.map((status) => (
            <article
              key={status.id}
              className="rounded-xl border-l-8 border-green-600 bg-white p-5 grid grid-cols-[1fr_max-content]"
            >
              <div className="flex flex-col gap-5">
                <div className="flex flex-col gap-2">
                  <h6 className="font-[600]">{status.count}</h6>
                  <p className="text-xs text-gray-700">{status.label}</p>
                </div>
                {status.cta && (
                  <Link to={status.cta.path}>
                    <div className="flex items-center justify-start gap-1 text-green-600 text-sm font-[600]">
                      <span>{status.cta.label}</span>
                      <BiCaretRight />
                    </div>
                  </Link>
                )}
                {status.detail && (
                  <div className="flex items-center justify-start gap-1  text-sm font-[600]"></div>
                )}
              </div>

              <div className="flex items-center justify-center">
                <div className="flex items-center justify-center bg-gray-300 rounded-full h-[3rem] w-[3rem]">
                  <img src={status.icon} alt="" />
                </div>
              </div>
            </article>
          ))}
        </section>
      )}

      {statsLoading && (
        <div className="grid lg:grid-cols-3 grid-cols-1 items-center gap-5">
          <div
            className={`animate-pulse bg-gray-600 rounded-xl  h-[9rem]`}
          ></div>
          <div
            className={`animate-pulse bg-gray-600 rounded-xl  h-[9rem]`}
          ></div>
          <div
            className={`animate-pulse bg-gray-600 rounded-xl  h-[9rem]`}
          ></div>
        </div>
      )}
    </section>
  );
};

export default Stats;
