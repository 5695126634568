export const getSidBarList = () => sideBarList;

import { BiSolidCog, BiSolidNotepad } from "react-icons/bi";
import { AiFillThunderbolt } from "react-icons/ai";
import { IoLogOut } from "react-icons/io5";
import { GoHomeFill } from "react-icons/go";
// import { HiArchive } from 'react-icons/hi';

const sideBarList = [
  {
    id: 1,
    path: "/",
    label: "Dashboard",
    icon: GoHomeFill,
  },
  {
    id: 2,
    path: "/events",
    label: "Events",
    icon: BiSolidNotepad,
  },
  {
    id: 3,
    path: "/requests",
    label: "Event Controller",
    icon: AiFillThunderbolt,
  },
  // {
  //   id: 4,
  //   path: "/archive",
  //   label: "Archive",
  //   icon: HiArchive,
  // },
  {
    id: 6,
    path: "/settings",
    label: "Settings",
    icon: BiSolidCog,
  },
  {
    id: 7,
    path: "/sign-out",
    label: "Signout",
    icon: IoLogOut,
  },
];

export const statusList = [
  {
    id: 1,
    label: "Pending",
    textColor: "text-gray-800",
    bgColor: "bg-gray-200",
  },
  { id: 2, label: "Live", textColor: "text-white", bgColor: "bg-green-400" },
  {
    id: 3,
    label: "In Progress",
    textColor: "text-blue-900",
    bgColor: "bg-blue-200",
  },
  {
    id: 4,
    label: "On Hold",
    textColor: "text-yellow-700",
    bgColor: "bg-yellow-100",
  },
  {
    id: 5,
    label: "Approved",
    textColor: "text-green-600",
    bgColor: "bg-green-100",
  },
  {
    id: 6,
    label: "Rejected",
    textColor: "text-red-600",
    bgColor: "bg-red-100",
  },
  {
    id: 7,
    label: "In Planning",
    textColor: "text-blue-900",
    bgColor: "bg-blue-200",
  },
  {
    id: 8,
    label: "Archived",
    textColor: "text-gray-800",
    bgColor: "bg-gray-300",
  },
];
