import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FiSearch, FiX } from "react-icons/fi";

const Search = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = ({ currentTarget }) => {
    if (currentTarget.value) return setSearchTerm(currentTarget.value);

    setSearchTerm("");
  };

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      navigate(`/search/${searchTerm.toLowerCase()}`);
      setSearchTerm("");
    }
  };

  return (
    <div className="flex lg:w-[80%]">
      <div className="relative w-full bottom-0">
        <span className="absolute left-5 top-1/2 transform -translate-y-1/2 text-gray-700 text-xl">
          <FiSearch />
        </span>
        <input
          type="text"
          value={searchTerm}
          onChange={handleChange}
          onKeyUp={handleKeyUp}
          placeholder="Search for anything"
          className="outline-none bg-gray-300 py-4 px-12 text-md w-full rounded-lg border border-transparent text-md focus:border-green-400"
        />
        {searchTerm && (
          <span
            onClick={() => setSearchTerm("")}
            className="cursor-pointer absolute right-5 flex items-center justify-center h-[2rem] w-[2rem] bg-gray-600 rounded-full top-1/2 transform -translate-y-1/2   text-lg"
          >
            <FiX />
          </span>
        )}
      </div>
    </div>
  );
};

export default Search;
