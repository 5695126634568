import { create } from "zustand";

const eventDetailStore = create((set) => ({
  eventDetailLoading: false,
  editEventId: "",
  eventDetail: {
    date: "",
    uuid: "",
    time: "",
    tags: [],
    image: "",
    title: "",
    speakers: [],
    location: "",
    attendees: [],
    status: "",
    requester: "",
    description: "",
    seatingStyle: "",
  },

  setEditEventId: (id) => set({ editEventId: id }),
  setEventDetail: (detail) => set({ eventDetail: detail }),
  setEventDetailLoading: (value) => set({ eventDetailLoading: value }),
}));

export default eventDetailStore;
