import { useEffect, useState } from "react";

const status = {
  internal: {
    textColor: "text-green-600",
    bgColor: "bg-green-100",
    label: "Internal",
  },
  corporate: {
    textColor: "text-yellow-700",
    bgColor: "bg-yellow-100",
    label: "Corporate",
  },
  external: {
    textColor: "text-gray-800",
    bgColor: "bg-gray-300",
    label: "External",
  },
  general: {
    textColor: "text-blue-700",
    bgColor: "bg-blue-200",
    label: "General",
  },
};

const Category = ({ variant }) => {
  const [currentStatus, setCurrentStatus] = useState({
    tag_name: "Internal",
  });

  useEffect(() => {
    if (variant) setCurrentStatus(variant);
  }, []);

  return (
    <span
      className={`py-1 px-3 rounded-md block  text-center text-[10px] uppercase font-[700] even:bg-yellow-200 even:text-yellow-900 bg-green-200 text-green-900`}
    >
      {currentStatus.tag_name}
    </span>
  );
};

export default Category;
