import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "../../common/button";
import { FiSearch, FiX } from "react-icons/fi";

import _ from "lodash";
import { useEffect, useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { BsDashCircleFill } from "react-icons/bs";
import createEventStore from "../../store/createEvent";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteData, getData, postData } from "../../../utils/api";
import eventDetailStore from "../../store/eventDetail";

const InvitePeople = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [searchTerm, setSearchTerm] = useState("");
  const currentSearchTerm = createEventStore(
    (state) => state.currentSearchTerm,
  );
  const setCurrentSearchTerm = createEventStore(
    (state) => state.setCurrentSearchTerm,
  );
  const editEventId = eventDetailStore((state) => state.editEventId);

  const attendeeList = createEventStore((state) => state.attendeeList);
  const setAttendeeList = createEventStore((state) => state.setAttendeeList);

  const setAttendeeListLoading = createEventStore(
    (state) => state.setAttendeeListLoading,
  );
  const attendeeListLoading = createEventStore(
    (state) => state.attendeeListLoading,
  );

  const token = localStorage.getItem("evently");
  const mutation = useMutation({
    mutationFn: (data) => {
      return postData(
        "/admin/search_attendees",
        { search_keyword: data },
        token,
      );
    },
  });

  useQuery({
    queryKey: ["getAttendees"],
    queryFn: async () =>
      await getData(`/admin/fetch_attendees/${eventId}`, token),
    onSuccess: (data) => setAttendeeList(data.data.data || []),
  });

  const addAttendeeMutation = useMutation({
    mutationFn: (data) => {
      return postData(
        "/admin/add_attendees",
        {
          event_requests_id: editEventId,
          users_id: data,
        },
        token,
      );
    },
    onSuccess: () => {
      setSearchTerm("");
      queryClient.invalidateQueries({ queryKey: ["getAttendees"] });
    },
  });

  const deleteAttendeeMutation = useMutation({
    mutationFn: (data) => {
      return deleteData(
        `/admin/remove_attendee/${data.uuid}/${eventId}`,
        token,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getAttendees"] });
    },
  });

  const handleChange = ({ currentTarget }) => {
    if (currentTarget.value)
      return setSearchTerm(currentTarget.value.toLowerCase());
    else setSearchTerm("");
  };

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      setCurrentSearchTerm(searchTerm);
      mutation.mutate(searchTerm);
    }
  };

  useEffect(() => {
    setAttendeeListLoading(mutation.isLoading);
  }, [mutation.isLoading]);

  const canProceed = Boolean(attendeeList.length);

  return (
    <section className="overflow-y-auto lg:rounded-xl scrollbar-thumb-gray-600 lg:w-[40rem] w-full m-auto lg:h-[90%] h-screen scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-full grid items-center">
      <div className="bg-white lg:rounded-xl z-30 flex flex-col gap-5 lg:mr-3 p-5 px-10 lg:h-[max-content] h-screen">
        <nav className="flex justify-between border-b border-gray-300 py-5">
          <h6 className="font-[600]">Invite People</h6>

          <span
            className="h-[3rem] w-[3rem] bg-gray-300 flex justify-center items-center rounded-full text-xl cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <FiX />
          </span>
        </nav>

        <div className="mb-4 flex flex-col gap-2 relative w-full bottom-0">
          <span className="absolute left-5 top-1/2 transform -translate-y-1/2 text-gray-700 text-xl">
            <FiSearch />
          </span>
          <input
            type="text"
            value={searchTerm}
            onChange={handleChange}
            onKeyUp={handleKeyUp}
            placeholder="Search by First Name, Last Name or Email"
            className="outline-none bg-gray-300 py-4 px-12 text-md w-full rounded-lg border border-transparent text-md focus:border-green-400"
          />
          {searchTerm && (
            <span
              onClick={() => setSearchTerm("")}
              className="cursor-pointer absolute right-5 flex items-center justify-center h-[2rem] w-[2rem] bg-red-400 text-white rounded-full top-1/2 transform -translate-y-1/2   text-xl"
            >
              <FiX />
            </span>
          )}

          {searchTerm && attendeeListLoading && (
            <div className="absolute shadow-lg top-[110%] z-10 rounded-xl left-0 w-full h-[10rem] bg-white flex items-center justify-center">
              <p className="text-sm text-gray-700">Loading...</p>
            </div>
          )}

          {searchTerm && !attendeeListLoading && (
            <div className="absolute p-5 shadow-lg top-[110%] rounded-xl z-20 left-0 w-full h-[10rem] bg-white overflow-y-auto gap-5 scrollbar-track-gray-100 scrollbar-thin  scrollbar-rounded-full">
              {!Boolean(mutation.data?.data.length) && (
                <div className="flex items-center justify-center h-full  bg-white">
                  {currentSearchTerm && (
                    <p className="text-sm text-gray-700">
                      No user found for "{currentSearchTerm}"
                    </p>
                  )}

                  {!currentSearchTerm && (
                    <p className="text-sm text-gray-700">
                      Press enter when you're done typing
                    </p>
                  )}
                </div>
              )}

              {Boolean(mutation.data?.data.length) &&
                mutation.data?.data.map((item) => (
                  <div
                    key={item.id}
                    className="flex items-center justify-between"
                  >
                    <div className="flex items-center justify-start gap-4">
                      <span className="flex items-center justify-center h-[3rem] w-[3rem] rounded-full bg-yellow-600 text-xl font-[700]">
                        {item.first_name[0]}
                        {item.last_name[0]}
                      </span>
                      <span className="font-[600] capitalize">
                        {item.first_name} {item.last_name} {item.position}
                      </span>
                    </div>

                    {!addAttendeeMutation.isLoading && (
                      <Button
                        onClick={() => addAttendeeMutation.mutate(item.id)}
                        variant="filled"
                        colorScheme="primary"
                        size="sm"
                      >
                        Add
                      </Button>
                    )}
                  </div>
                ))}
            </div>
          )}
        </div>

        <div className="flex gap-5  min-h-[8rem]">
          {!Boolean(attendeeList.length) && (
            <div className="flex w-full flex-col gap-3 items-center justify-center">
              <p className="text-sm text-gray-700 max-w-[35ch] text-center leading-6">
                No attendees yet, try adding one.
              </p>
            </div>
          )}

          {Boolean(attendeeList.length) &&
            attendeeList.slice(0, 5).map((item) => (
              <article
                key={item.id}
                className="w-[max-content] flex flex-col gap-2"
              >
                <span className="grid place-items-center h-[4rem] w-[4rem] rounded-full bg-yellow-600 text-xl font-[700] relative">
                  <span>
                    {item.attendee_first_name[0]}
                    {item.attendee_last_name[0]}
                  </span>
                  <span
                    onClick={() => deleteAttendeeMutation.mutate(item)}
                    className="absolute right-0 bottom-[5px] cursor-pointer text-red-500"
                  >
                    <BsDashCircleFill />
                  </span>
                </span>
                <p className="flex flex-col justify-center text-sm items-center">
                  <span className="font-[800]">{item.attendee_first_name}</span>
                  <span className="text-gray-700 font-[500]">
                    {item.attendee_last_name}
                  </span>
                </p>
              </article>
            ))}

          {Boolean(attendeeList.length >= 5) && (
            <article className="w-[max-content] flex flex-col gap-2">
              <span className="grid place-items-center h-[4rem] w-[4rem] rounded-full bg-gray-600 text-xl font-[700] relative text-gray-700">
                +{attendeeList.length - 5}
              </span>
            </article>
          )}
        </div>

        {/* <div className="flex lg:flex-row flex-col items-center justify-start gap-2">
          <div className="flex items-center gap-1 text-sm">
            <FaInfoCircle className="text-lg text-red-500" />
            <p className="text-sm">
              Invites can also be sent to people outside your organization.
            </p>
          </div>
          <Link>
            <span className="font-[700] text-green-700">Learn more</span>
          </Link>
        </div> */}

        <div className="flex items-center justify-end gap-4 mt-2 pb-8 min-h-[6rem]">
          {canProceed && (
            <Button
              onClick={() => navigate(-1)}
              variant="filled"
              size="lg"
              colorScheme="danger"
            >
              Finish
            </Button>
          )}
        </div>
      </div>
    </section>
  );
};

export default InvitePeople;
