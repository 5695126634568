import { useNavigate, useParams } from "react-router-dom";
import Button from "../../common/button";
import { FiX } from "react-icons/fi";
import * as Yup from "yup";

import _ from "lodash";
import { useEffect } from "react";
import { BsDashCircleFill } from "react-icons/bs";
import createEventStore from "../../store/createEvent";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteData, getData, postData } from "../../../utils/api";
import { useFormik } from "formik";
import eventDetailStore from "../../store/eventDetail";

const InviteSpeakers = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { eventId } = useParams();
  const editEventId = eventDetailStore((state) => state.editEventId);

  const speakersList = createEventStore((state) => state.speakersList);
  const setSpeakersList = createEventStore((state) => state.setSpeakersList);

  const setSpeakersListLoading = createEventStore(
    (state) => state.setSpeakersListLoading,
  );

  const token = localStorage.getItem("evently");
  const mutation = useMutation({
    mutationFn: (data) => {
      return postData(
        "/admin/search_attendees",
        { search_keyword: data },
        token,
      );
    },
  });

  useQuery({
    queryKey: ["getSpeakers"],
    queryFn: async () =>
      await getData(`/admin/fetch_speakers/${eventId}`, token),
    onSuccess: (data) => setSpeakersList(data.data.data || []),
  });

  const addSpeakerMutation = useMutation({
    mutationFn: (data) => {
      return postData(
        "/admin/invite_speaker",
        {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          position: data.position,
          event_requests_id: editEventId,
        },
        token,
      );
    },
    onSuccess: () => {
      formik.setFieldValue("first_name", "");
      formik.setFieldValue("last_name", "");
      formik.setFieldValue("email", "");
      formik.setFieldValue("position", "");
      queryClient.invalidateQueries({ queryKey: ["getSpeakers"] });
    },
  });

  const deleteSpeakerMutation = useMutation({
    mutationFn: (data) => {
      return deleteData(`/admin/remove_speaker/${data.uuid}/${eventId}`, token);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["getSpeakers"] });
    },
  });

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      position: "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Required"),
      last_name: Yup.string().required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      position: Yup.string().min(1).max(10),
    }),
    onSubmit: (values) => {
      addSpeakerMutation.mutate(values);
    },
  });

  useEffect(() => {
    setSpeakersListLoading(mutation.isLoading);
  }, [mutation.isLoading]);

  const canProceed = Boolean(speakersList.length);

  const getFieldStyle = (error) => {
    return `form-input bg-gray-200 px-5 py-4 outline-none border focus:border-green-600 rounded-md w-full text-md 
    scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-full
    ${
      formik.touched[error] && formik.errors[error]
        ? "border-red-600 focus:border-red-600"
        : "border-transparent"
    }`;
  };

  return (
    <section className="overflow-y-auto lg:rounded-xl scrollbar-thumb-gray-600 lg:w-[50rem] w-full m-auto lg:h-[90%] h-screen scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-full grid items-center">
      <div className="bg-white lg:rounded-xl z-30 flex flex-col gap-5 lg:mr-3 p-5 px-8 lg:h-[max-content] h-[100%]">
        <nav className="flex justify-between border-b border-gray-300 py-5">
          <h6 className="font-[600]">Invite Speakers</h6>

          <span
            className="h-[3rem] w-[3rem] bg-gray-300 flex justify-center items-center rounded-full text-xl cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <FiX />
          </span>
        </nav>

        <div className="">
          <form onSubmit={formik.handleSubmit}>
            <div className="grid gap-4 grid-cols-1">
              <div className="mb-4">
                <label htmlFor="first_name" className="block mb-1 font-medium">
                  First Name
                </label>
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  placeholder="eg. John"
                  className={getFieldStyle("first_name")}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.first_name}
                />
                {formik.touched.first_name && formik.errors.first_name ? (
                  <div className="text-red-500">{formik.errors.first_name}</div>
                ) : null}
              </div>

              <div className="mb-4">
                <label htmlFor="last_name" className="block mb-1 font-medium">
                  Last Name
                </label>
                <input
                  type="text"
                  id="last_name"
                  name="last_name"
                  placeholder="eg. Doe"
                  className={getFieldStyle("last_name")}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.last_name}
                />
                {formik.touched.last_name && formik.errors.last_name ? (
                  <div className="text-red-500">{formik.errors.last_name}</div>
                ) : null}
              </div>
            </div>

            <div className="grid gap-4 grid-cols-1">
              <div className="mb-4">
                <label htmlFor="email" className="block mb-1 font-medium">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="eg. john.doe@somewhere.com"
                  className={getFieldStyle("email")}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="text-red-500">{formik.errors.email}</div>
                ) : null}
              </div>

              <div className="mb-10">
                <label htmlFor="position" className="block mb-1 font-medium">
                  Position{" "}
                  <span className="text-gray-700 font-[300]">Optional</span>
                </label>
                <input
                  type="text"
                  id="position"
                  name="position"
                  placeholder="eg. IMT"
                  className={getFieldStyle("position")}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.position}
                />
                {formik.touched.position && formik.errors.position ? (
                  <div className="text-red-500">{formik.errors.position}</div>
                ) : null}
              </div>
            </div>

            <div className="flex gap-5 mb-5   min-h-[8rem]">
              {!Boolean(speakersList.length) && (
                <div className="flex w-full flex-col gap-3 items-center justify-center">
                  <p className="text-sm text-gray-700 max-w-[35ch] text-center leading-6">
                    No speakers yet, try adding one.
                  </p>
                </div>
              )}

              {Boolean(speakersList.length) &&
                speakersList.slice(0, 5).map((item) => (
                  <article
                    key={item.id}
                    className="w-[max-content] flex flex-col gap-2"
                  >
                    <span className="grid place-items-center h-[4rem] w-[4rem] rounded-full bg-yellow-600 text-xl font-[700] relative">
                      <span>
                        {item.first_name[0]}
                        {item.last_name[0]}
                      </span>
                      {!deleteSpeakerMutation.isLoading && (
                        <span
                          onClick={() => deleteSpeakerMutation.mutate(item)}
                          className="absolute right-0 bottom-[5px] cursor-pointer text-red-500"
                        >
                          <BsDashCircleFill />
                        </span>
                      )}
                    </span>
                    <p className="flex flex-col justify-center text-sm items-center">
                      <span className="font-[800]">{item.first_name}</span>
                      <span className="text-gray-700 font-[500]">
                        {item.last_name}
                      </span>
                    </p>
                  </article>
                ))}

              {Boolean(speakersList.length >= 5) && (
                <article className="w-[max-content] flex flex-col gap-2">
                  <span className="grid place-items-center h-[4rem] w-[4rem] rounded-full bg-gray-600 text-xl font-[700] relative text-gray-700">
                    +{speakersList.length - 5}
                  </span>
                </article>
              )}
            </div>

            <div className="flex items-center justify-end gap-4 mt-2 lg:pb-8 pb-[10rem]">
              <Button
                onClick={formik.handleSubmit}
                isLoading={addSpeakerMutation.isLoading}
                variant="outlined"
                size="lg"
                type="submit"
                colorScheme="primary"
              >
                Add speaker
              </Button>

              {canProceed && (
                <Button
                  onClick={() => navigate(-1)}
                  variant="filled"
                  size="lg"
                  colorScheme="danger"
                >
                  Finish
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default InviteSpeakers;
