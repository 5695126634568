import _ from "lodash";
import Button from "../common/button";

const makeWordsBold = (text, classNames) => {
  let classIndex = 0;
  const pattern = /'(.*?)'/g;
  const boldText = text.replace(pattern, (match, word) => {
    const className = classNames[classIndex] || "";
    classIndex++;

    if (classIndex === classNames.length) {
      // For the last bolded word, wrap it with single quotes
      return `<strong class="${className}">“${word}”</strong>`;
    } else {
      return `<strong class="${className}">${word}</strong>`;
    }
  });
  return _.unescape(boldText);
};

const BoldWordsInText = ({ text, classNames, variant }) => {
  const boldedText = makeWordsBold(text, classNames);

  return (
    <div
      className={`${variant && "text-2xl leading-8"}`}
      dangerouslySetInnerHTML={{ __html: boldedText }}
    />
  );
};

const NotificationCard = ({ item, onView, onShow, variant }) => {
  const isRead = Boolean(Number(item.is_read));
  return (
    <div
      className={`w-full cursor-pointer ${
        isRead ? "transparent" : !variant ? "bg-gray-300" : "transparent"
      }  rounded-md grid  p-5 gap-4 ${
        variant ? "grid-cols-1" : "grid-cols-[max-content_1fr]"
      }`}
    >
      <span
        className={`${
          variant ? "h-[5rem] w-[5rem] text-3xl" : "h-[3rem] w-[3rem] text-lg"
        } rounded-full overflow-hidden flex items-center justify-center bg-gray-300 `}
      >
        <div className="flex items-center justify-center h-full w-full font-[600] bg-yellow-600 ">
          {item.receivers_name.split(" ")[0][0]}
          {item.receivers_name.split(" ")[1][0]}
        </div>
      </span>

      <div>
        <BoldWordsInText
          variant={variant}
          text={item.message}
          classNames={["font-[600]", "font-[600] text-green-600", "font-[600]"]}
        />

        {!variant && (
          <div className="grid gap-4 mt-5 mb-5">
            <Button
              onClick={onShow}
              colorScheme="gray"
              size="md"
              variant="filled"
            >
              Open notification
            </Button>
            {!isRead && (
              <Button
                onClick={onView}
                colorScheme="success"
                size="md"
                variant="filled"
              >
                Set as viewed
              </Button>
            )}
            {isRead && (
              <Button
                onClick={onView}
                colorScheme="success"
                size="md"
                variant="outlined"
              >
                Set as unviewed
              </Button>
            )}
          </div>
        )}

        <div className="flex justify-end">
          {!isRead && (
            <span className="block bg-yellow-600 h-[.8rem] w-[.8rem] rounded-full"></span>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotificationCard;
