import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./tailwindcss.css";
import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "react-query";
import axios from "axios";
import { CustomProvider } from "rsuite";
import "rsuite/dist/rsuite.min.css";
const token = localStorage.getItem("evently");

const query = async ({ queryKey }) => {
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: "application/json",
  };
  const { data } = await axios.get(
    `https://api.eventlyhq.net/v1/${queryKey[0]}`,
    { headers },
  );
  return data;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { queryFn: query },
  },
});

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <ChakraProvider>
      <QueryClientProvider client={queryClient}>
        <CustomProvider>
          <App />
        </CustomProvider>
      </QueryClientProvider>
    </ChakraProvider>
  </React.StrictMode>,
);
