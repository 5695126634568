import Status from "../../common/status";
import { BiSolidTrash } from "react-icons/bi";
import tickImage from "../../../assets/images/tick-circle.svg";
import { useEffect, useState } from "react";
import Confirm from "./confirm";
import requestsStore from "../../store/requests";
import { useMutation, useQueryClient } from "react-query";
import { deleteData, postData } from "../../../utils/api";

const Option = ({ request, onClose }) => {
  const queryClient = useQueryClient();
  const [status, setStatus] = useState(request.event_status.toLowerCase());

  const [isLoading, setIsLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(null);

  const requestUpdateLoading = requestsStore(
    (state) => state.requestUpdateLoading,
  );

  const updateRequestMutation = useMutation({
    mutationFn: (data) => {
      return postData(
        "/admin/toggle_event_action",
        { event_uuid: data.id, action: data.status },
        localStorage.getItem("evently"),
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["counter"], exact: true });
      queryClient.invalidateQueries({
        queryKey: ["requestTable"],
        exact: true,
      });
      queryClient.invalidateQueries({
        queryKey: ["notifications"],
        exact: true,
      });
    },
  });

  const deleteRequestMutation = useMutation({
    mutationFn: (id) => {
      return deleteData(
        `/admin/delete_event/${id}`,
        localStorage.getItem("evently"),
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["counter"], exact: true });
      queryClient.invalidateQueries({
        queryKey: ["requestTable"],
        exact: true,
      });
      queryClient.invalidateQueries({
        queryKey: ["notifications"],
        exact: true,
      });
    },
  });

  useEffect(() => {
    if (!requestUpdateLoading) setShowConfirm(null);
    setIsLoading(requestUpdateLoading);
  }, [updateRequestMutation.isLoading, deleteRequestMutation.isLoading]);

  const handleDelete = async () => {
    deleteRequestMutation.mutate(request.uuid);
    onClose();
  };

  const handleChangeStatus = (status) => {
    setStatus(status.toLowerCase());
    updateRequestMutation.mutate({ id: request.uuid, status: status });
    onClose();
  };

  const renderStatus = (statusName, onClick) => {
    return (
      <div
        className="w-full grid grid-cols-[70%_30%] cursor-pointer"
        onClick={() => onClick()}
      >
        <Status variant={statusName} />

        <div className="flex justify-end">
          {status === statusName && (
            <img src={tickImage} className="w-[1.3rem]" />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="shadow-xl rounded-md absolute lg:bottom-0 bottom-[-100%] right-0 w-[15rem] bg-white px-5 py-2 z-40">
      <Confirm
        onShow={showConfirm}
        isLoading={isLoading}
        isDelete={showConfirm?.type}
        onAccept={showConfirm?.onAccept}
        onClose={() => setShowConfirm(null)}
        title={showConfirm?.title}
        description={showConfirm?.description}
      />

      <div>
        <span className="block border-b border-gray-300 py-4 text-sm text-black">
          Options
        </span>

        <span
          onClick={() =>
            setShowConfirm({
              title: "Delete Request",
              description: "This action is not reversible",
              onAccept: () => {
                handleDelete();
              },
              type: "delete",
            })
          }
          className="flex items-center justify-start py-3 gap-2 cursor-pointer hover:text-red-500 text-red-600"
        >
          <span className="h-[1.8rem] w-[1.8rem] text-lg bg-gray-300 flex items-center justify-center rounded-full">
            <BiSolidTrash />
          </span>
          <p className="font-[600] text-[1rem]">Delete</p>
        </span>
      </div>

      <div>
        <span className="block border-b border-gray-300 py-4 text-sm text-black">
          Change Status
        </span>

        <div className="flex flex-col items-start justify-start py-4 gap-2 h-[10rem] overflow-y-auto scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-full">
          {renderStatus("live", () => {
            if (status !== "live")
              return setShowConfirm({
                title: "Request Status Change",
                description: "",
                onAccept: () => {
                  handleChangeStatus("Live");
                },
              });
          })}

          {renderStatus("in progress", () => {
            if (status !== "in progress")
              return setShowConfirm({
                title: "Request Status Change",
                description: "",
                onAccept: () => {
                  handleChangeStatus("In Progress");
                },
              });
          })}

          {renderStatus("on hold", () => {
            if (status !== "on hold")
              return setShowConfirm({
                title: "Request Status Change",
                description: "",
                onAccept: () => {
                  handleChangeStatus("On Hold");
                },
              });
          })}

          {renderStatus("approved", () => {
            if (status !== "approved")
              return setShowConfirm({
                title: "Request Status Change",
                description: "",
                onAccept: () => {
                  handleChangeStatus("Approved");
                },
              });
          })}

          {renderStatus("rejected", () => {
            if (status !== "rejected")
              return setShowConfirm({
                title: "Request Status Change",
                description: "",
                onAccept: () => {
                  handleChangeStatus("Rejected");
                },
              });
          })}

          {renderStatus("in planning", () => {
            if (status !== "in planning")
              return setShowConfirm({
                title: "Request Status Change",
                description: "",
                onAccept: () => {
                  handleChangeStatus("In Planning");
                },
              });
          })}

          {renderStatus("archive", () => {
            if (status !== "archive")
              return setShowConfirm({
                title: "Request Status Change",
                description: "",
                onAccept: () => {
                  handleChangeStatus("Archive");
                },
              });
          })}
        </div>
      </div>
    </div>
  );
};

export default Option;
