import { useField, ErrorMessage } from "formik";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { FiAlertCircle } from "react-icons/fi";

const InputField = ({ iconStart, iconEnd, password, ...props }) => {
  const [field, meta] = useField(props);

  const isError = meta.touched && meta.error;

  return (
    <div className="flex flex-col gap-2">
      <label
        htmlFor={props.name}
        id={props.name}
        className="font-[900] text-xs"
      >
        {props.label}
      </label>
      <div className="relative">
        {iconStart && (
          <div className="absolute left-4 top-1/2 transform -translate-y-1/2 text-green-600">
            {iconStart}
          </div>
        )}

        <input
          {...field}
          {...props}
          className={`bg-gray-200 px-10 py-4 outline-none border focus:border-green-600 rounded-md w-full text-md ${
            isError
              ? "border-red-600 focus:border-red-600"
              : "border-transparent"
          }`}
        />

        {!isError && password && (
          <div
            onClick={password.handler}
            className="absolute right-4 top-1/2 transform -translate-y-1/2 text-green-600 cursor-pointer text-xl"
          >
            {password.value && <FaEyeSlash />}
            {!password.value && <FaEye />}
          </div>
        )}

        {!isError && iconEnd && !password && (
          <div className="absolute right-4 top-1/2 transform -translate-y-1/2 text-green-600">
            {iconEnd}
          </div>
        )}

        {isError && (
          <div className="absolute right-4 top-1/2 transform -translate-y-1/2 text-green-600">
            <FiAlertCircle className="text-red-600" />
          </div>
        )}
      </div>
      <ErrorMessage
        {...props}
        component="div"
        className="text-sm text-red-500"
      />
    </div>
  );
};

export default InputField;
