import { create } from "zustand";

const showAds =
  localStorage.getItem("evently-showAds") === null
    ? "yes"
    : localStorage.getItem("evently-showAds");

export const useStore = create((set) => ({
  smallNav: false,
  showAds,
  token: localStorage.getItem("evently"),
  setNavState: (value) => set({ smallNav: value }),
  setShowAds: (value) => set({ showAds: value }),

  adsList: [],
  setAdsList: (value) => set({ adsList: value }),
}));

export default useStore;
