import { useFormik } from "formik";
import * as Yup from "yup";

import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useOutsideClick } from "@chakra-ui/react";
import Basic from "./basic";
import SeatingType from "./seatingType";
import EditSeating from "./editSeating";
import UploadImage from "./uploadImage";
import { useMutation, useQueryClient } from "react-query";
import { postData } from "../../../utils/api";
import InvitePeople from "./invitePeople";
import InviteSpeakers from "./inviteSpeakers";
import createEventStore from "../../store/createEvent";
import InviteExternalPeople from "./inviteExternalPeople";
import ViewPeople from "./viewPeople";
import InviteExternalSpeakers from "./inviteExternalSpeakers";
import BulkInvite from "./bulkInvite";

const maxFileSize = 5 * 1024 * 1024;

const validationSchema = Yup.object().shape({
  event_title: Yup.string().required("Required"),
  event_date: Yup.date().required("Required"),
  event_time: Yup.string().required("Required"),
  event_location: Yup.string().required("Required"),
  event_venue: Yup.string().required("Required"),
  event_description: Yup.string().min(1).max(1000).required("Required"),
  event_request_divisions: Yup.string().required("Required"),
  event_request_department: Yup.string().required("Required"),
  event_category: Yup.string().required("Required"),
  event_seating_style: Yup.string().required("Required"),
  // event_no_of_seats: Yup.number().min(1).integer(),
  // event_reserved_seats: Yup.string()
  //   .notRequired()
  //   .test(
  //     'valid-chars',
  //     'Only numbers, spaces, and commas are allowed',
  //     (value) => {
  //       // Check for empty or whitespace-only value
  //       if (!value || /^\s*$/.test(value)) {
  //         return true; // Passes the test for empty value
  //       }
  //       // Check for valid characters
  //       return /^[0-9 ,]*$/.test(value);
  //     }
  //   ),
  // event_no_of_tables: Yup.number().min(1).integer(),
  // event_reserved_tables: Yup.string()
  //   .notRequired()
  //   .test(
  //     'valid-chars',
  //     'Only numbers, spaces, and commas are allowed',
  //     (value) => {
  //       // Check for empty or whitespace-only value
  //       if (!value || /^\s*$/.test(value)) {
  //         return true; // Passes the test for empty value
  //       }
  //       // Check for valid characters
  //       return /^[0-9 ,]*$/.test(value);
  //     }
  //   ),
  event_image: Yup.mixed()
    .test(
      "fileSize",
      "Image size is too large",
      (value) => !value || (value && value.size <= maxFileSize),
    )
    .test(
      "fileType",
      "Invalid file format. Only images are allowed.",
      (value) => !value || (value && value.type.startsWith("image/")),
    )
    .required(),
});

const CreateEvent = ({ tabNumber }) => {
  const navigate = useNavigate();
  const ref = useRef();
  const queryClient = useQueryClient();

  const [current, setCurrent] = useState(1);

  useOutsideClick({
    ref: ref,
    handler: () => {
      navigate(1);
    },
  });

  const token = localStorage.getItem("evently");

  const mutation = useMutation({
    mutationFn: (data) => {
      return postData("/admin/create_event", data, token);
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["notifications"],
        exact: true,
      }),
  });

  const setSpeakersList = createEventStore((state) => state.setSpeakersList);
  const setAttendeeList = createEventStore((state) => state.setAttendeeList);

  const handleSubmit = (values) => {
    // let data = {};

    // if (values.event_seating_style === 'Banquet Style')
    //   data = {
    //     ...values,
    //     event_reserved_tables: values.event_reserved_seats
    //       .replace(/\s/g, '')
    //       .split(',')
    //       .join(','),
    //     event_no_of_tables: values.event_no_of_seats,
    //     event_no_of_seats: null,
    //     event_reserved_seats: null,
    //   };

    // if (values.event_seating_style === 'Theatre Style')
    //   data = {
    //     ...values,
    //     event_no_of_seats: values.event_no_of_seats,
    //     event_reserved_seats: values.event_reserved_seats
    //       .replace(/\s/g, '')
    //       .split(',')
    //       .join(','),
    //     event_no_of_tables: null,
    //     event_reserved_tables: null,
    //   };

    // mutation.mutate(data);
    mutation.mutate(values);
  };

  const handleFinish = () => {
    const id = localStorage.getItem("current-event-created-uuid");
    localStorage.removeItem("current-event-created-uuid");
    localStorage.removeItem("current-event-created-id");
    setSpeakersList([]);
    setAttendeeList([]);

    navigate(`/event-detail/${id}${location.pathname}`);
  };

  useEffect(() => {
    if (tabNumber) setCurrent(tabNumber);

    let pathname = "";
    if (location.pathname.includes("create-event")) pathname = "/create-event";

    if (mutation.isSuccess) {
      localStorage.setItem("current-event-created-id", mutation.data.data.id);
      localStorage.setItem(
        "current-event-created-uuid",
        mutation.data.data.uuid,
      );
      navigate(`/event-detail/${mutation.data.data.uuid}${pathname}`);
    }
  }, [mutation.isLoading]);

  const formik = useFormik({
    initialValues: {
      event_title: "",
      event_date: "",
      event_time: "",
      event_venue: "",
      event_location: "",
      event_description: "",
      event_request_divisions: "",
      event_request_department: "",
      event_category: "",
      event_seating_style: "",
      // event_no_of_seats: '',
      // event_reserved_seats: '',
      // event_no_of_tables: '',
      // event_reserved_tables: '',
      event_image: null,
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const getFieldStyle = (error) => {
    return `form-input bg-gray-200 px-5 py-4 outline-none border focus:border-green-600 rounded-md w-full text-md 
    scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-full appearance-none
    ${
      formik.touched[error] && formik.errors[error]
        ? "border-red-600 focus:border-red-600"
        : "border-transparent"
    }`;
  };

  const location = useLocation();

  return (
    <>
      <section className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-20 flex overflow-hidden">
        {current === 1 && (
          <Basic
            formik={formik}
            getFieldStyle={getFieldStyle}
            onNext={() => setCurrent(current + 1)}
          />
        )}
        {current === 2 && (
          <SeatingType
            formik={formik}
            onNext={() => setCurrent(current + 2)}
            onPrev={() => setCurrent(current - 1)}
          />
        )}
        {/* {current === 3 && (
          <EditSeating
            formik={formik}
            getFieldStyle={getFieldStyle}
            onNext={() => setCurrent(current + 1)}
            onPrev={() => setCurrent(current - 1)}
          />
        )} */}
        {current === 4 && (
          <UploadImage
            onSubmit={formik.submitForm}
            isLoading={mutation.isLoading}
            isSuccess={mutation.isSuccess}
            formik={formik}
            onNext={() => setCurrent(current + 1)}
            onPrev={() => setCurrent(current - 2)}
          />
        )}
        {current === 5 && (
          <InvitePeople
            formik={formik}
            onNext={() => setCurrent(current + 1)}
          />
        )}
        {current === 6 && (
          <InviteSpeakers
            formik={formik}
            getFieldStyle={getFieldStyle}
            onFinish={handleFinish}
          />
        )}
        {current === 7 && <InviteExternalPeople formik={formik} />}
        {current === 8 && <ViewPeople />}
        {current === 9 && <InviteExternalSpeakers formik={formik} />}
        {current === 10 && <BulkInvite formik={formik} />}
      </section>
    </>
  );
};

export default CreateEvent;
