import icon from "../../assets/images/evently-icon.png";
import { BiLoaderAlt } from "react-icons/bi";

const Loading = ({ text }) => {
  return (
    <div className="fixed top-0 left-0 bg-white w-full z-20 h-screen flex flex-col gap-5 items-center justify-center">
      <div className="relative  flex items-center justify-center">
        <BiLoaderAlt className="animate-spin h-[8rem] w-[8rem] text-green-600" />
        <img className="absolute z-20 w-[50%]" src={icon} />
      </div>

      <span className="h8">
        Just a sec, {text || "We're processing things"}
      </span>
    </div>
  );
};

export default Loading;
