import { useFormik } from "formik";
import * as Yup from "yup";

import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useOutsideClick } from "@chakra-ui/react";
import Basic from "./basic";
// import SeatingType from "./seatingType";
// import EditSeating from "./editSeating";
import UploadImage from "./uploadImage";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getData, postData } from "../../../utils/api";

const validationSchema = Yup.object().shape({
  event_title: Yup.string().required("Required"),
  event_uuid: Yup.string().required("Required"),
  event_date: Yup.date().required("Required"),
  event_time: Yup.string().required("Required"),
  event_location: Yup.string().required("Required"),
  event_venue: Yup.string().required("Required"),
  event_description: Yup.string().min(1).max(2000).required("Required"),
  event_request_divisions: Yup.string().required("Required"),
  event_request_department: Yup.string().required("Required"),
  event_category: Yup.string().required("Required"),
  // event_seating_style: Yup.string().required('Required'),
  // event_no_of_seats: Yup.number().notRequired().min(1).integer(),
  // event_reserved_seats: Yup.string()
  //   .notRequired()
  //   .test(
  //     'valid-chars',
  //     'Only numbers, spaces, and commas are allowed',
  //     (value) => {
  //       // Check for empty or whitespace-only value
  //       if (!value || /^\s*$/.test(value)) {
  //         return true; // Passes the test for empty value
  //       }
  //       // Check for valid characters
  //       return /^[0-9 ,]*$/.test(value);
  //     }
  //   ),
  // event_no_of_tables: Yup.number().notRequired().min(1).integer(),
  // event_reserved_tables: Yup.string()
  //   .notRequired()
  //   .test(
  //     'valid-chars',
  //     'Only numbers, spaces, and commas are allowed',
  //     (value) => {
  //       // Check for empty or whitespace-only value
  //       if (!value || /^\s*$/.test(value)) {
  //         return true; // Passes the test for empty value
  //       }
  //       // Check for valid characters
  //       return /^[0-9 ,]*$/.test(value);
  //     }
  //   ),
  event_image: Yup.mixed()
    // .test(
    //   "fileSize",
    //   "Image size is too large",
    //   (value) => !value || (value && value.size <= maxFileSize)
    // )
    .test(
      "fileType",
      "Invalid file format. Only images are allowed.",
      (value) => {
        if (!value) return false;
        if (value && value.type && value.type.startsWith("image/")) return true;
        if (value && value.startsWith("https://")) return true;
      },
    )
    .notRequired(),
});

const EditEvent = () => {
  const navigate = useNavigate();
  const ref = useRef();
  const queryClient = useQueryClient();
  const { eventId } = useParams();

  const [current, setCurrent] = useState(1);

  useOutsideClick({
    ref: ref,
    handler: () => {
      navigate(1);
    },
  });

  const token = localStorage.getItem("evently");

  const mutation = useMutation({
    mutationFn: (data) => {
      return postData("/admin/edit_event", data, token);
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["notifications"],
        exact: true,
      }),
  });

  const editEventQuery = useQuery({
    queryKey: ["editEventQuery"],
    enabled: false,
    queryFn: async () => await getData(`/admin/event_detail/${eventId}`, token),
    onSuccess: (data) => {
      return formik.setValues({
        event_uuid: eventId,
        event_title: data.data.data.event_title,
        event_date: data.data.data.event_date,
        event_time: data.data.data.event_time,
        event_venue: data.data.data.event_venue,
        event_location: data.data.data.event_location,
        event_description: data.data.data.event_description,
        event_request_divisions: data.data.data.event_request_division,
        event_request_department: data.data.data.event_request_department,
        event_category: data.data.data.tags[0].tag_name,
        // event_seating_style: data.data.data.event_seating_style,
        // event_no_of_seats: data.data.data.event_no_of_seats,
        // event_no_of_tables: data.data.data.event_no_of_tables,
        // event_reserved_seats: data.data.data.event_reserved_seats,
        // event_reserved_tables: data.data.data.event_reserved_tables,
        event_image: data.data.data.event_image,
      });
    },
  });

  const handleSubmit = (values) => {
    // let data = {};

    // if (values.event_seating_style === 'Banquet Style')
    //   data = {
    //     ...values,
    //     event_reserved_tables: values.event_reserved_seats
    //       ? values.event_reserved_seats.replace(/\s/g, '').split(',').join(',')
    //       : null,
    //     event_no_of_tables: values.event_no_of_seats,
    //     event_no_of_seats: null,
    //     event_reserved_seats: null,
    //   };

    // if (values.event_seating_style === 'Theatre Style')
    //   data = {
    //     ...values,
    //     event_no_of_seats: values.event_no_of_seats,
    //     event_reserved_seats: values.event_reserved_seats
    //       .replace(/\s/g, '')
    //       .split(',')
    //       .join(','),
    //     event_no_of_tables: null,
    //     event_reserved_tables: null,
    //   };

    mutation.mutate(values);
  };

  useEffect(() => {
    let pathname = "";

    if (location.pathname.includes("edit-event")) pathname = "/edit-event";

    if (mutation.isSuccess) {
      navigate(`/event-detail/${eventId}${pathname}`);
    }
  }, [mutation.isLoading]);

  useEffect(() => {
    editEventQuery.refetch();
  }, []);

  const formik = useFormik({
    initialValues: {
      event_uuid: eventId,
      event_title: "",
      event_date: "",
      event_time: "",
      event_venue: "",
      event_location: "",
      event_description: "",
      event_request_divisions: "",
      event_request_department: "",
      event_category: "",
      // event_seating_style: '',
      // event_no_of_tables: null,
      // event_no_of_seats: null,
      // event_reserved_tables: null,
      // event_reserved_seats: null,
      event_image: null,
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const getFieldStyle = (error) => {
    return `form-input bg-gray-200 px-5 py-4 outline-none border focus:border-green-600 rounded-md w-full text-md 
    scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-full appearance-none
    ${
      formik.touched[error] && formik.errors[error]
        ? "border-red-600 focus:border-red-600"
        : "border-transparent"
    }`;
  };

  return (
    <>
      <section className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-20 flex">
        {current === 1 && (
          <Basic
            formik={formik}
            getFieldStyle={getFieldStyle}
            onNext={() => setCurrent(current + 1)}
          />
        )}
        {/* {current === 2 && (
          <SeatingType
            formik={formik}
            onNext={() => setCurrent(current + 1)}
            onPrev={() => setCurrent(current - 1)}
          />
        )} */}
        {/* {current === 3 && (
          <EditSeating
            formik={formik}
            getFieldStyle={getFieldStyle}
            onNext={() => setCurrent(current + 1)}
            onPrev={() => setCurrent(current - 1)}
          />
        )} */}
        {current === 2 && (
          <UploadImage
            onSubmit={formik.submitForm}
            isLoading={mutation.isLoading}
            isSuccess={mutation.isSuccess}
            formik={formik}
            onNext={() => setCurrent(current + 1)}
            onPrev={() => setCurrent(current - 1)}
          />
        )}
      </section>
    </>
  );
};

export default EditEvent;
