import { useNavigate } from "react-router-dom";
import Button from "../../common/button";
import { FiX } from "react-icons/fi";
import { useEffect } from "react";

import _ from "lodash";
import { useQuery, useMutation } from "react-query";
import { getData, postData } from "../../../utils/api";
import createEventStore from "../../store/createEvent";
import { toast } from "react-hot-toast";

const eventCategories = ["Internal", "Corporate", "Exhibitions/Conferences"];

const Basic = ({ getFieldStyle, onNext, formik }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("evently");

  const locations = createEventStore((state) => state.locations);
  const setLocations = createEventStore((state) => state.setLocations);

  const departments = createEventStore((state) => state.departments);
  const setDepartments = createEventStore((state) => state.setDepartments);

  const divisions = createEventStore((state) => state.divisions);
  const setDivisions = createEventStore((state) => state.setDivisions);

  const fetchStates = useQuery({
    queryKey: ["fetchStates"],
    queryFn: async () => await getData("/admin/fetch_ng_states", token),
    onSuccess: (data) => setLocations(data.data.data),
  });

  const fetchDivisions = useQuery({
    queryKey: ["fetchDivisions"],
    queryFn: async () => await getData("/admin/fetch_divisions", token),
    onSuccess: (data) => setDivisions(data.data.data),
  });

  const fetchDepartment = useMutation({
    mutationFn: (division) => {
      return postData(
        `/admin/fetch_department_on_divisions`,
        { division },
        localStorage.getItem("evently"),
      );
    },
    onSuccess: (data) => {
      setDepartments(data.data);
    },
  });

  useEffect(() => {
    if (fetchStates.isError && fetchStates.error.response)
      toast.error(
        `Fetch Location (states): ${fetchStates.error.response.status}:\n\n ${fetchStates.error.response.data.message}`,
      );

    if (fetchStates.isError) toast.error(fetchStates.error.message);

    if (fetchDivisions.isError && fetchDivisions.error.response)
      toast.error(
        `Fetch Department: ${fetchDivisions.error.response.status}:\n\n ${fetchDivisions.error.response.data.message}`,
      );

    if (fetchDivisions.isError) toast.error(fetchDivisions.error.message);
  }, [fetchDepartment.isError, fetchStates.isError, fetchDivisions.isError]);

  const canProceed =
    formik.values.event_title &&
    formik.values.event_date &&
    formik.values.event_time &&
    formik.values.event_category &&
    formik.values.event_location &&
    formik.values.event_venue &&
    formik.values.event_request_department &&
    formik.values.event_request_divisions &&
    formik.values.event_description;

  return (
    <section className="overflow-hidden grid items-center lg:w-[60rem] w-full m-auto lg:h-[90%] h-[100%] scrollbar-thumb-gray-600scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-full">
      <div className="bg-white top-0 lg:rounded-xl rounded-none z-30 flex flex-col gap-5 lg:mr-3 py-5 px-8 relative overflow-y-auto h-[100%] scrollbar-thumb-gray-600scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-full">
        <nav className="flex justify-between border-b border-gray-300 py-5">
          <h6 className="font-[600]">Create Event</h6>

          <span
            className="h-[3rem] w-[3rem] bg-gray-300 flex justify-center items-center rounded-full text-xl cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <FiX />
          </span>
        </nav>

        <div className="mb-4 flex flex-col gap-2">
          <label htmlFor="event_title" className="block mb-1 font-medium">
            Event Title
          </label>
          <input
            type="text"
            id="event_title"
            placeholder="eg. PD Medallion Awards"
            name="event_title"
            onBlur={formik.handleBlur}
            className={getFieldStyle("event_title")}
            onChange={formik.handleChange}
            value={formik.values.event_title}
          />
          {formik.touched.event_title && formik.errors.event_title && (
            <div className="text-red-600">{formik.errors.event_title}</div>
          )}
        </div>

        <div className="grid grid-cols-1 gap-4 w-full">
          <div className="mb-4 flex flex-col gap-2">
            <label htmlFor="event_date" className="block mb-1 font-medium">
              Date
            </label>
            <input
              type="date"
              id="event_date"
              placeholder="eg. PD Medallion Awards"
              name="event_date"
              onBlur={formik.handleBlur}
              className={getFieldStyle("event_date")}
              onChange={formik.handleChange}
              value={formik.values.event_date}
            />
            {formik.touched.event_date && formik.errors.event_date && (
              <div className="text-red-600">{formik.errors.event_date}</div>
            )}
          </div>

          <div className="mb-4 flex flex-col gap-2">
            <label htmlFor="event_time" className="block mb-1 font-medium">
              Time
            </label>
            <input
              type="time"
              id="event_time"
              placeholder="eg. PD Medallion Awards"
              name="event_time"
              onBlur={formik.handleBlur}
              className={getFieldStyle("event_time")}
              onChange={formik.handleChange}
              value={formik.values.event_time}
            />
            {formik.touched.event_time && formik.errors.event_time && (
              <div className="text-red-600">{formik.errors.event_time}</div>
            )}
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4 w-full">
          <div>
            <div className="mb-4 flex flex-col gap-2">
              <label
                htmlFor="event_category"
                className="mb-1 font-medium flex gap-1 items-center"
              >
                <span>Category</span>
              </label>

              <select
                id="event_category"
                name="event_category"
                onBlur={formik.handleBlur}
                className={getFieldStyle("event_category")}
                onChange={formik.handleChange}
                value={formik.values.event_category}
              >
                <option value="" disabled>
                  Select
                </option>
                {eventCategories.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </select>

              {formik.touched.event_category &&
                formik.errors.event_category && (
                  <div className="text-red-600">
                    {formik.errors.event_category}
                  </div>
                )}
            </div>
          </div>

          <div className="mb-4 flex flex-col gap-2">
            <label
              htmlFor="event_request_divisions"
              className="block mb-1 font-medium"
            >
              Division
            </label>

            {fetchDivisions.isLoading && (
              <div className="animate-pulse rounded-xl bg-gray-600 w-full h-[4rem]"></div>
            )}

            {!fetchDivisions.isLoading && (
              <select
                id="event_request_divisions"
                name="event_request_divisions"
                onBlur={formik.handleBlur}
                className={getFieldStyle("event_request_divisions")}
                onChange={(event) => {
                  const division = event.target.value;
                  formik.setFieldValue("event_request_divisions", division);
                  fetchDepartment.mutate(division);
                }}
                value={formik.values.event_request_divisions}
              >
                <option value="" disabled defaultChecked>
                  Select
                </option>
                {divisions.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            )}

            {formik.touched.event_request_divisions &&
              formik.errors.event_request_divisions && (
                <div className="text-red-600">
                  {formik.errors.event_request_divisions}
                </div>
              )}
          </div>

          <div className="mb-4 flex flex-col gap-2">
            <label
              htmlFor="event_request_department"
              className="block mb-1 font-medium"
            >
              Department
            </label>
            {fetchDepartment.isLoading && (
              <div className="animate-pulse rounded-xl bg-gray-600 w-full h-[4rem]"></div>
            )}
            {!fetchDepartment.isLoading && (
              <select
                id="event_request_department"
                name="event_request_department"
                onBlur={formik.handleBlur}
                className={getFieldStyle("event_request_department")}
                onChange={formik.handleChange}
                value={formik.values.event_request_department}
              >
                <option value="" disabled>
                  Select
                </option>
                {departments.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            )}
            {formik.touched.event_request_department &&
              formik.errors.event_request_department && (
                <div className="text-red-600">
                  {formik.errors.event_request_department}
                </div>
              )}
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4 w-full">
          <div className="mb-4 flex flex-col gap-2">
            <label htmlFor="event_location" className="block mb-1 font-medium">
              Location
            </label>
            <select
              id="event_location"
              name="event_location"
              onBlur={formik.handleBlur}
              className={getFieldStyle("event_location")}
              onChange={formik.handleChange}
              value={formik.values.event_location}
            >
              <option value="" disabled>
                Select
              </option>
              {locations.map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>
            {formik.touched.event_location && formik.errors.event_location && (
              <div className="text-red-600">{formik.errors.event_location}</div>
            )}
          </div>

          <div className="mb-4 flex flex-col gap-2">
            <label htmlFor="event_venue" className="block mb-1 font-medium">
              Venue
            </label>
            <input
              type="text"
              id="event_venue"
              placeholder="eg. Stadium Hall"
              name="event_venue"
              onBlur={formik.handleBlur}
              className={getFieldStyle("event_venue")}
              onChange={formik.handleChange}
              value={formik.values.event_venue}
            />
            {formik.touched.event_venue && formik.errors.event_venue && (
              <div className="text-red-600">{formik.errors.event_venue}</div>
            )}
          </div>
        </div>

        <div className="mb-4">
          <label
            htmlFor="event_description"
            className="mb-1 font-medium flex gap-1"
          >
            <span>Description</span>
            <span className="text-gray-700 font-[400]">(1,000 characters)</span>
          </label>
          <textarea
            id="event_description"
            name="event_description"
            className={getFieldStyle("event_description")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.event_description}
          />
          {formik.touched.event_description &&
            formik.errors.event_description && (
              <div className="text-red-600">
                {formik.errors.event_description}
              </div>
            )}
        </div>

        <div className="flex items-center justify-end gap-3 mt-2 lg:pb-8 pb-[10rem]">
          <Button
            onClick={() => navigate(-1)}
            variant="outlined"
            size="lg"
            colorScheme="gray"
          >
            Cancel
          </Button>
          {canProceed && (
            <Button
              onClick={onNext}
              variant="filled"
              size="lg"
              colorScheme="primary"
            >
              Continue
            </Button>
          )}
        </div>
      </div>
    </section>
  );
};

export default Basic;
