import { create } from "zustand";

const eventsStore = create((set) => ({
  events: [],
  eventsLoading: false,

  setEvents: (events) => set({ events: events }),
  setEventsLoading: (events) => set({ eventsLoading: events }),
}));

export default eventsStore;
