import usePageTitle from "../hooks/pageTitle";
import _ from "lodash";

import EventsCard from "./events/eventsCard";
import Controller from "../common/controller";

import archivesStore from "../store/archives";
import Pagination from "../common/pagination";
import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import { getData } from "../../utils/api";
import { toast } from "react-hot-toast";
import moment from "moment";

const Archive = () => {
  usePageTitle("Evently — Archive");
  const token = localStorage.getItem("evently");

  const archives = archivesStore((state) => state.archives);
  const setArchives = archivesStore((state) => state.setArchives);
  const archiveLoading = archivesStore((state) => state.archivesLoading);
  const setArchivesLoading = archivesStore((state) => state.setArchivesLoading);

  const [list, setList] = useState(archives);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const totalPages = Math.ceil(list.length / itemsPerPage);
  const paginatedData = _.chunk(list, itemsPerPage)[currentPage - 1] || [];

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const { isLoading, isError, error } = useQuery({
    queryKey: ["archives"],
    queryFn: async () => await getData("/admin/fetch_archive_events", token),
    onSuccess: (data) => setArchives(data.data.data),
  });

  useEffect(() => {
    setArchivesLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (isError && error.response) {
      const message = `Archive Tab: ${error.response.status}:\n\n ${error.response.data.message}`;
      toast.error(message);
    }

    if (isError && !error.response) toast.error(error.message);
  }, [isError]);

  useEffect(() => {
    setList(archives);
  }, [archives]);

  const handleChange = (event) => {
    const type = event.currentTarget.name;
    const value = event.currentTarget.value;

    if (!value) return setList(archives);

    if (value && type === "month") {
      const newList = archives.filter((item) => {
        const month = moment(item.event_date).format("MMMM").toLowerCase();
        return month === value;
      });

      setList(newList);
    }

    if (value && type === "year") {
      const newList = archives.filter((item) => {
        const year = moment(item.event_date).format("YYYY");
        return year === value;
      });

      setList(newList);
    }
  };

  return (
    <div className="relative pt-10 pb-40 min-h-full w-full bg-gray-300 first-letter: grid grid-rows-[max-content_max-content_1fr]">
      <div className="max-w-[80rem] mx-auto lg:px-10 px-5 h-full w-full grid grid-rows-[max-content_1fr]">
        <div className="pb-5">
          <Controller onFilterChanged={handleChange}>
            <span className="text-gray-700 flex gap-2 items-center justify-center">
              <h5 className="font-[600] text-black">Archive</h5>
            </span>
          </Controller>
        </div>

        {!Boolean(paginatedData.length) && archiveLoading && (
          <div
            className={`animate-pulse bg-gray-600 rounded-xl w-full col-span-2 min-h-screen`}
          ></div>
        )}

        {!archiveLoading && Boolean(paginatedData.length) && (
          <div className="flex flex-col gap-3 py-2 pb-5 grid-rows-[max-content]">
            {paginatedData.map((item) => (
              <EventsCard small data={item} />
            ))}
          </div>
        )}

        {!archiveLoading && !Boolean(paginatedData.length) && (
          <div className="min-h-screen flex flex-col gap-3 items-center justify-center">
            <h5 className="font-[600]">No Archive</h5>
            <p className="text-sm text-gray-700 max-w-[35ch] text-center leading-6">
              All passed events will be stored here when they are completed.
            </p>
          </div>
        )}

        {/* Pagination */}
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default Archive;
