import { useEffect, useState } from "react";

const status = {
  live: {
    textColor: "text-white",
    bgColor: "bg-green-400",
    label: "Live",
  },
  rejected: {
    textColor: "text-red-600",
    bgColor: "bg-red-100",
    label: "Rejected",
  },
  approved: {
    textColor: "text-green-600",
    bgColor: "bg-green-100",
    label: "Approved",
  },
  archive: {
    textColor: "text-gray-800",
    bgColor: "bg-gray-300",
    label: "Archived",
  },
  inProgress: {
    textColor: "text-blue-900",
    bgColor: "bg-blue-200",
    label: "In Progress",
  },
  hold: {
    textColor: "text-yellow-700",
    bgColor: "bg-yellow-100",
    label: "On Hold",
  },
  pending: {
    textColor: "text-gray-800",
    bgColor: "bg-gray-300",
    label: "Pending",
  },
  inPlanning: {
    textColor: "text-blue-100",
    bgColor: "bg-blue-800",
    label: "In Planning",
  },
};

const Status = ({ variant }) => {
  const [currentStatus, setCurrentStatus] = useState(status["hold"]);

  useEffect(() => {
    let check = "hold";

    if (variant === "live") check = "live";
    if (variant === "archive") check = "archive";
    if (variant === "approved") check = "approved";
    if (variant === "in progress") check = "inProgress";
    if (variant === "on hold") check = "hold";
    if (variant === "pending") check = "pending";
    if (variant === "rejected") check = "rejected";
    if (variant === "in planning") check = "inPlanning";

    if (variant) setCurrentStatus(status[check]);
  }, [variant]);

  return (
    <span
      className={`${currentStatus.bgColor} ${currentStatus.textColor} py-2 rounded-md block text-center text-sm  w-full lg:w-[8rem] px-5`}
    >
      {currentStatus.label}
    </span>
  );
};

export default Status;
