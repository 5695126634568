import { useEffect, useState } from "react";
import { BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";

import EventsCard from "./events/eventsCard";
import TableBody from "./requests/tbody";
import TableBodyResponsive from "./requests/tbodyResponsive";
import searchStore from "../store/search";
import eventsStore from "../store/events";
import requestsStore from "../store/requests";
import Button from "../common/button";

const SearchPage = () => {
  const { term } = useParams();
  const [showEventsResult, setShowEventsResult] = useState(true);
  const [showRequestsResult, setShowRequestsResult] = useState(true);

  const searchResult = searchStore((state) => state.searchResult);
  const search = searchStore((state) => state.search);
  const events = eventsStore((state) => state.events);
  const requests = requestsStore((state) => state.requests);

  const navigate = useNavigate();

  useEffect(() => {
    search(term, events, requests);
  }, [term]);

  const handleGoHome = () => {
    navigate(`/`);
  };

  return (
    <div className="flex">
      {term && (
        <div className="overflow-y-auto left-0 bg-gray-300 z-5 lg:px-10 px-5 pt-5 lg:pb-10 pb-[100px] h-[90vh] w-full scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-full">
          <div className="max-w-[80rem] mx-auto">
            {!searchResult.found && searchResult.currentValue === term && (
              <div className="flex flex-col   min-h-screen  items-center justify-center gap-5">
                <h5 className="font-[600]">Oops, No Result Found</h5>
                {term && (
                  <p className="text-gray-700 text-sm">
                    There was no result found for{" "}
                    <span className="font-[600] capitalize text-black">
                      {term}
                    </span>
                    .
                  </p>
                )}

                <Button
                  size="sm"
                  variant="filled"
                  colorScheme="danger"
                  onClick={handleGoHome}
                >
                  Go home
                </Button>
              </div>
            )}

            {searchResult.found && (
              <section className="py-4">
                <header className="flex items-center justify-between gap-5 mb-10">
                  <h6 className="font-[600]">
                    Results Found for &ldquo;
                    <span className="text-green-600 capitalize">
                      {searchResult.currentValue}
                    </span>
                    &rdquo;
                  </h6>

                  <Button
                    size="md"
                    variant="filled"
                    colorScheme="danger"
                    onClick={handleGoHome}
                  >
                    Go home
                  </Button>
                </header>

                <section
                  className={`grid grid-rows-${
                    showEventsResult ? "[max-content_20rem]" : "[max-content]"
                  } mt-6 gap-3`}
                >
                  <div
                    className="flex items-center gap-2 font-[600] cursor-pointer"
                    onClick={() => setShowEventsResult(!showEventsResult)}
                  >
                    <span className="h8">
                      Events{" "}
                      <span className="text-green-600">
                        {searchResult.events.length}
                      </span>
                    </span>
                    {showEventsResult ? (
                      <BsCaretDownFill className="text-green-600" />
                    ) : (
                      <BsCaretUpFill className="text-green-600" />
                    )}
                  </div>

                  {showEventsResult && Boolean(searchResult.events.length) && (
                    <div className="flex flex-col gap-5 ">
                      {searchResult.events.map((item) => (
                        <EventsCard small data={item} />
                      ))}
                    </div>
                  )}
                </section>

                <section
                  className={`grid grid-rows-${
                    showRequestsResult ? "[max-content_20rem]" : "[max-content]"
                  } mt-6 gap-3`}
                >
                  <div
                    className="flex items-center gap-2 font-[600]  cursor-pointer"
                    onClick={() => setShowRequestsResult(!showRequestsResult)}
                  >
                    <span className="h8">
                      Requests{" "}
                      <span className="text-green-600">
                        {searchResult.requests.length}
                      </span>
                    </span>
                    {showRequestsResult ? (
                      <BsCaretDownFill className="text-green-600" />
                    ) : (
                      <BsCaretUpFill className="text-green-600" />
                    )}
                  </div>

                  {showRequestsResult &&
                    Boolean(searchResult.requests.length) && (
                      <div className="bg-white rounded-lg py-4 px-8 flex flex-col gap-5">
                        <table className="lg:table hidden">
                          <TableBody normalList={searchResult.requests} />
                        </table>

                        <table className="lg:hidden table">
                          <TableBodyResponsive
                            normalList={searchResult.requests}
                          />
                        </table>
                      </div>
                    )}
                </section>
              </section>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchPage;
