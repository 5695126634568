import { useState } from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";

import { FaLock } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import usePageTitle from "../hooks/pageTitle";
import { toast } from "react-hot-toast";

import Logo from "../common/logo";
import Button from "../common/button";
import InputField from "../common/inputField";
import backgroundImage from "../../assets/images/login.png";
import { postData } from "../../utils/api";
import userStore from "../store/user";
import { useNavigate } from "react-router-dom";
import Loading from "../common/loading";

const SignIn = () => {
  usePageTitle("Evently — Sign in");
  const [isPassword, setIsPassword] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const initialValues = {
    email: "",
    password: "",
    // email: "bobbyjoseph108@gmail.com",
    // password: "bubbleplay",
  };

  const setSignedInUser = userStore((state) => state.setSignedInUser);
  const loggedInUserToken = localStorage.getItem("evently");

  const validationSchema = Yup.object({
    email: Yup.string().email().required("Email address is required"),
    password: Yup.string().min(4).max(100).required("Password is required"),
  });

  const handleSubmit = async (values) => {
    if (loggedInUserToken) {
      toast("A user is already signed in, please sign out to continue.");
      return navigate("/");
    }
    setIsLoading(true);

    try {
      const response = await postData("/admin/auth/authenticate", values);
      if (response?.data?.admin_details) {
        const { first_name, last_name, position } = response.data.admin_details;
        localStorage.setItem("evently", response.data.token);

        setSignedInUser({
          firstName: first_name,
          lastName: last_name,
          initials: `${first_name[0]}${last_name[0]}`,
          referenceIndicator: position,
        });
        setIsLoading(false);
        navigate("/");
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <main className="lg:h-screen h-full grid md:grid-cols-2 grid-cols-1">
      {isLoading && <Loading text="Signing you in" />}
      <section className="xl:w-[70%] lg:w-[70%] md:w-[80%] sm:w-[90%] mx-auto py-10">
        <header className="flex items-center justify-stretch  h-full">
          <header className="flex flex-col lg:w-[80rem] w-full mx-auto gap-[50px]">
            <Logo />
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form className="flex flex-col gap-12">
                <div className="flex flex-col gap-3">
                  <h5 className="font-[700]">Sign in</h5>
                  <p className="body-2 text-gray-700 w-[40ch] leading-7">
                    Welcome to Evently, please sign in to continue to the admin
                    dashboard.
                  </p>
                </div>

                <div className="flex flex-col gap-5">
                  <InputField
                    name="email"
                    type="email"
                    placeholder="e.g clifford.elega@nlng.com"
                    iconStart={<IoMail />}
                    label="Email Address"
                  />

                  <InputField
                    name="password"
                    type={isPassword ? "password" : "text"}
                    placeholder="Password"
                    iconStart={<FaLock />}
                    password={{
                      value: isPassword,
                      handler: () => setIsPassword(!isPassword),
                    }}
                    label="Password"
                  />
                </div>

                <div>
                  <Button
                    type="submit"
                    variant="filled"
                    isLoading={isLoading}
                    size="lg"
                    colorScheme="primary"
                  >
                    Continue
                  </Button>
                </div>
              </Form>
            </Formik>
          </header>
        </header>
      </section>

      <figure className="md:overflow-hidden md:block hidden overflow-visible">
        <img
          className="w-full md:h-full h-[50rem] object-cover"
          src={backgroundImage}
        />
      </figure>
    </main>
  );
};

export default SignIn;
