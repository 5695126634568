import PropTypes from "prop-types";
import TableHead from "./thead";
import TableBody from "./tbody";
import TableBodyResponsive from "./tbodyResponsive";
import { Link } from "react-router-dom";
import _ from "lodash";

import Controller from "../../common/controller";
import { useEffect, useState } from "react";
import requestsStore from "../../store/requests";
import { useQuery } from "react-query";
import { getData } from "../../../utils/api";
import Pagination from "../../common/pagination";
import { toast } from "react-hot-toast";
import moment from "moment";
import { statusList } from "../../../utils/data";

const RequestsTable = ({ isVariant }) => {
  const [currentStatus, setCurrentStatus] = useState(statusList.at(0));

  const requestsList = requestsStore((state) => state.requests);
  const requests = requestsList.slice(0, 5);
  const setRequests = requestsStore((state) => state.setRequests);
  const setRequestsLoading = requestsStore((state) => state.setRequestsLoading);

  const token = localStorage.getItem("evently");
  const requestsLoading = requestsStore((state) => state.requestsLoading);
  const [filteredList, setFilteredList] = useState(requests);
  const [list, setList] = useState(requests);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;
  const totalPages = Math.ceil(filteredList.length / itemsPerPage);
  const paginatedData =
    _.chunk(filteredList, itemsPerPage)[currentPage - 1] || [];
  const requestsBrief = isVariant && paginatedData.slice(0, 5);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const { isLoading, isError, error } = useQuery({
    queryKey: ["requestTable"],
    queryFn: async () => await getData("/admin/fetch_requested_events", token),
    onSuccess: (data) => setRequests(data.data.data),
  });

  useEffect(() => {
    setRequestsLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setList(requests);
    setFilteredList(
      requests.filter(
        (request) => request.event_status === currentStatus.label,
      ),
    );
  }, [requests]);

  useEffect(() => {
    setFilteredList(
      requests.filter(
        (request) => request.event_status === currentStatus.label,
      ),
    );
  }, [currentStatus]);

  useEffect(() => {
    if (isError && error.response) {
      const message = `Requests Tab: ${error.response.status}:\n\n ${error.response.data.message}`;
      toast.error(message);
    }
    if (isError && !error.response) toast.error(error.message);
  }, [isError]);

  const handleChange = (event) => {
    const type = event.currentTarget.name;
    const value = event.currentTarget.value;

    if (!value) return setList(requests);

    if (value && type === "month") {
      const newList = requests.filter((item) => {
        const month = moment(item.event_date).format("MMMM").toLowerCase();
        return month === value;
      });

      setList(newList);
    }

    if (value && type === "year") {
      const newList = requests.filter((item) => {
        const year = moment(item.event_date).format("YYYY");
        return year === value;
      });

      setList(newList);
    }
  };

  return (
    <section>
      <ul
        className={`mb-2 ${
          isVariant ? "hidden" : "grid"
        } grid-cols-3 md:grid-cols-7 text-sm font-medium text-center text-gray-500 border-b border-gray-600 $ rounded-xl overflow-hidden`}
      >
        {statusList.map((status) => (
          <button
            type="button"
            title={status.label}
            onClick={() => {
              setCurrentStatus(status);
            }}
            className={`${
              currentStatus.id === status.id && "bg-gray-400"
            } p-4 text-black  bg-gray-100 active flex gap-2 items-center justify-center`}
            key={status.id}
          >
            {status.label}

            <span
              className={`${status.bgColor} ${status.textColor} rounded-full h-[1.3rem] w-[1.3rem] flex items-center justify-center text-xs font-[900] p-2`}
            >
              {requests.filter((r) => r.event_status === status.label).length}
            </span>
          </button>
        ))}
      </ul>

      {/* Small Screens */}
      <div className="lg:hidden block mb-7">
        <Controller onFilterChanged={handleChange}>
          <span className="text-gray-700 flex gap-2 items-center justify-center">
            <h6 className=" font-[600] text-black">All Events</h6>
            <div className="lg:block hidden">
              {isVariant && <span>・</span>}
              {isVariant && (
                <Link to="/requests">
                  <span className="text-sm font-[600]">See all</span>
                </Link>
              )}
            </div>
          </span>
        </Controller>
      </div>

      {/* Large Screens */}
      <div
        className={`flex flex-col gap-3 lg:py-5 py-2 h-max-content pr-3 scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-full pb-5 bg-white rounded-lg lg:px-8 px-4 grid-rows-[max-content]`}
      >
        <div className="lg:block hidden">
          <Controller isVariant onFilterChanged={handleChange}>
            <span className="text-gray-700 flex gap-2 items-center justify-center">
              <span className="h7 font-[600] text-black">All Events</span>
              {isVariant && <span>・</span>}
              {isVariant && (
                <Link to="/requests">
                  <span className="text-sm font-[600]">See all</span>
                </Link>
              )}
            </span>
          </Controller>
        </div>

        {requestsLoading && (
          <>
            <div
              className={`animate-pulse bg-gray-600 rounded-xl w-full col-span-2 min-h-screen lg:mt-0 my-2`}
            ></div>
          </>
        )}

        {!requestsLoading && !isVariant && !paginatedData.length && (
          <div className="flex flex-col items-center justify-center gap-3 h-[10rem] w-full">
            <h6 className="font-[600]">No Requests</h6>
            <p className="text-gray-700 text-sm max-w-[35ch]">
              You currently do not have any requets
            </p>
          </div>
        )}

        {isVariant && !requestsLoading && !requestsBrief.length && (
          <div className="flex flex-col items-center justify-center gap-3 h-[10rem] w-full">
            <h6 className="font-[600]">No Requests</h6>
            <p className="text-gray-700 text-sm">
              You currently do not have any requets
            </p>
          </div>
        )}

        {/* Main requests table */}
        {!isVariant && !requestsLoading && Boolean(paginatedData.length) && (
          <>
            <table className="lg:table hidden">
              <TableHead />
              <TableBody
                isVariant={isVariant}
                variantList={requestsBrief}
                normalList={paginatedData}
              />
            </table>

            <table className="lg:hidden table">
              <TableBodyResponsive
                isVariant={isVariant}
                variantList={requestsBrief}
                normalList={paginatedData}
              />
            </table>
          </>
        )}

        {/* Brief Requests table */}
        {isVariant && !requestsLoading && Boolean(requestsBrief.length) && (
          <>
            <table className="lg:table hidden">
              <TableHead />
              <TableBody
                isVariant={isVariant}
                variantList={list}
                normalList={list}
              />
            </table>

            <table className="lg:hidden table">
              <TableBodyResponsive
                isVariant={isVariant}
                variantList={list}
                normalList={list}
              />
            </table>
          </>
        )}

        {isVariant && (
          <div className="flex items-center justify-center pt-2 font-[600] text-green-600">
            <Link to="/requests">See all</Link>
          </div>
        )}
      </div>

      {/* Pagination */}
      {!isVariant && !requestsLoading && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
    </section>
  );
};

export default RequestsTable;

RequestsTable.protoType = {
  isVariant: PropTypes.bool,
};
