import Logo from "../logo";
import List from "./list";
import Ad from "./ad";
import PropTypes from "prop-types";

const SideBar = ({ activePath }) => {
  return (
    <aside className="bg-white h-full gap-5 grid grid-rows-[max-content_1fr_1fr] w-full lg:py-0 py-20 ">
      <div className="py-5">
        <Logo />
      </div>

      <List activePath={activePath} />
      {/* <Ad /> */}
    </aside>
  );
};

export default SideBar;

SideBar.protoType = { activePath: PropTypes.string };
