const tableHead = () => {
  return (
    <thead>
      <tr className="text-gray-700 text-sm border-b border-gray-300 font-[600]  text-left">
        <th className="pb-8 py-2 font-[500]">Event title</th>
        <th className="pb-8 py-2 font-[500]">Date Created</th>
        <th className="pb-8 py-2 font-[500]">Request By</th>
        {/* <th className="pb-8 py-2 font-[500]">QR Code</th> */}
        <th className="pb-8 py-2 font-[500]">Status</th>
      </tr>
    </thead>
  );
};

export default tableHead;
