import { create } from "zustand";

const createEventStore = create((set) => ({
  lineManagers: [],
  lineManagersLoading: false,

  locations: [],

  departments: [],

  divisions: [],

  attendeeList: [],
  attendeeListLoading: false,

  speakersList: [],
  speakersListLoading: false,

  externalAttendeeEventId: "",
  externalAttendeeList: [],
  externalAttendeeListLoading: false,

  currentSearchTerm: "",
  speakersCurrentSearchTerm: "",

  setLocations: (locations) => set({ locations: locations }),
  setDepartments: (departments) => set({ departments: departments }),
  setDivisions: (divisions) => set({ divisions: divisions }),

  setLineManagers: (lineManagers) => set({ lineManagers: lineManagers }),
  setLineManagersLoading: (value) => set({ lineLoading: value }),

  setCurrentSearchTerm: (value) => set({ currentSearchTerm: value }),

  setAttendeeList: (attendees) => set({ attendeeList: attendees }),
  addToAttendeeList: (value) =>
    set((state) => ({ attendeeList: [...state.attendeeList, value] })),
  removeFromAttendeeList: (id) =>
    set((state) => {
      const foundId = state.attendeeList.findIndex((item) => item.uuid === id);

      const attendeeList = [...state.attendeeList];
      attendeeList.splice(foundId, 1);
      return {
        attendeeList: attendeeList,
      };
    }),
  setAttendeeListLoading: (value) => set({ attendeeListLoading: value }),

  setExternalAttendeeEventId: (id) => set({ externalAttendeeEventId: id }),
  setExternalAttendeeList: (attendees) =>
    set({ externalAttendeeList: attendees }),
  addToExternalAttendeeList: (value) =>
    set((state) => ({
      externalAttendeeList: [...state.externalAttendeeList, value],
    })),
  removeFromExternalAttendeeList: (id) =>
    set((state) => {
      const foundId = state.externalAttendeeList.findIndex(
        (item) => item.uuid === id,
      );

      const externalAttendeeList = [...state.externalAttendeeList];
      externalAttendeeList.splice(foundId, 1);
      return {
        externalAttendeeList: externalAttendeeList,
      };
    }),
  setExternalAttendeeListLoading: (value) =>
    set({ externalAttendeeListLoading: value }),

  setSpeakersCurrentSearchTerm: (value) =>
    set({ speakersCurrentSearchTerm: value }),
  setSpeakersList: (speakers) => set({ speakersList: speakers }),
  addToSpeakersList: (value) =>
    set((state) => ({ speakersList: [...state.speakersList, value] })),
  removeFromSpeakersList: (id) =>
    set((state) => {
      const foundId = state.speakersList.findIndex((item) => item.uuid === id);

      const speakersList = [...state.speakersList];
      speakersList.splice(foundId, 1);
      return {
        speakersList: speakersList,
      };
    }),
  setSpeakersListLoading: (value) => set({ speakersListLoading: value }),
}));

export default createEventStore;
