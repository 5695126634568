import { useRef } from "react";
import Button from "../../common/button";
import { FiX } from "react-icons/fi";
import { useOutsideClick } from "@chakra-ui/react";

const Confirm = ({
  title,
  description,
  isDelete,
  isLoading,
  onAccept,
  onClose,
  onShow,
}) => {
  const ref = useRef();
  useOutsideClick({
    ref: ref,
    handler: onClose,
  });

  return (
    <div>
      {onShow && (
        <section className="fixed top-0 left-0 w-[100vw] h-[100vh] bg-black bg-opacity-30 flex items-center justify-center ">
          <article
            ref={ref}
            className="bg-white py-5 pb-8 rounded-xl flex flex-col gap-10 items-center justify-center md:w-[30rem] max-w md:px-5 px-10"
          >
            <nav className="flex items-center justify-end w-full">
              <span
                onClick={onClose}
                className="text-xl text-gray-700 w-[2.5rem] h-[2.5rem] bg-gray-300 rounded-full cursor-pointer flex items-center justify-center"
              >
                <FiX />
              </span>
            </nav>
            {/* <figure className="rounded-full w-[5rem] h-[5rem] bg-gray-200"></figure> */}
            <h6 className={`${isDelete ? "text-red-600" : "text-gray-800"}`}>
              {title || "Delete Request"}
            </h6>
            <div className="text-center flex flex-col gap-1">
              <p className="text-gray-700 text-sm font-[200]">
                Are you sure you want to perfom this action?
              </p>
              <p className="text-gray-700 text-sm font-[200]">{description}</p>
            </div>

            <div className="grid grid-cols-2 gap-5 mt-2">
              <Button
                onClick={() => onClose()}
                flex
                size="md"
                variant="outlined"
                colorScheme="gray"
              >
                Cancel
              </Button>
              {isDelete ? (
                <Button
                  onClick={onAccept}
                  flex
                  size="md"
                  variant="filled"
                  colorScheme="danger"
                  isLoading={isLoading}
                >
                  {isLoading ? "Deleting" : "Delete"}
                </Button>
              ) : (
                <Button
                  onClick={onAccept}
                  flex
                  size="md"
                  variant="filled"
                  colorScheme="primary"
                  isLoading={isLoading}
                >
                  {isLoading ? "Changing" : "Confirm"}
                </Button>
              )}
            </div>
          </article>
        </section>
      )}
    </div>
  );
};

export default Confirm;
