import usePageTitle from "../../hooks/pageTitle";
import RequestsTable from "./requestsTable";

const Requests = ({ isVariant }) => {
  usePageTitle("Evently — Requests");

  return (
    <div className="pb-[6.3rem] min-h-full w-full bg-gray-300">
      <div className="max-w-[80rem] mx-auto py-10 lg:px-10 px-5 h-full w-full grid grid-rows-[max-content_1fr]">
        <RequestsTable isVariant={isVariant} />
      </div>
    </div>
  );
};

export default Requests;
