import { useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useQuery } from "react-query";

import usePageTitle from "../../hooks/pageTitle";
import Button from "../../common/button";
import { BiPlus } from "react-icons/bi";
import EventsCard from "./eventsCard";
import Controller from "../../common/controller";

import eventsStore from "../../store/events";
import { getData } from "../../../utils/api";
import Pagination from "../../common/pagination";

const Events = () => {
  usePageTitle("Evently — Events");
  const navigate = useNavigate();
  const token = localStorage.getItem("evently");
  const events = eventsStore((state) => state.events);
  const setEvents = eventsStore((state) => state.setEvents);
  const setEventsLoading = eventsStore((state) => state.setEventsLoading);
  const eventsLoading = eventsStore((state) => state.eventsLoading);

  const [list, setList] = useState(events);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;
  const totalPages = Math.ceil(list.length / itemsPerPage);
  //const paginatedData = _.chunk(list, itemsPerPage)[currentPage - 1] || [];
  const paginatedData = _.chunk(list || [], itemsPerPage)[currentPage - 1] || [];

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const { isLoading, isError, error } = useQuery({
    queryKey: ["events"],
    queryFn: async () => await getData("/admin/list_events", token),
    onSuccess: (data) => setEvents(data.data.data),
  });

  useEffect(() => {
    setEventsLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setList(events);
  }, [events]);

  useEffect(() => {
    if (isError && error.response) {
      const message = `Events Tab: ${error.response.status}:\n\n ${error.response.data.message}`;
      toast.error(message);
    }

    if (isError && !error.response) toast.error(error.message);
  }, [isError]);

  const handleChange = (event) => {
    const type = event.currentTarget.name;
    const value = event.currentTarget.value;

    if (!value) return setList(events);

    if (value && type === "month") {
      const newList = events.filter((item) => {
        const month = moment(item.event_date).format("MMMM").toLowerCase();
        return month === value;
      });

      setList(newList);
    }

    if (value && type === "year") {
      const newList = events.filter((item) => {
        const year = moment(item.event_date).format("YYYY");
        return year === value;
      });

      setList(newList);
    }
  };

  return (
    <div className="relative pt-10 pb-40 min-h-full w-full bg-gray-300 first-letter: grid grid-rows-[max-content_max-content_1fr]">
      <div className="max-w-[80rem] mx-auto lg:px-10 px-5 h-full w-full grid grid-rows-[max-content_1fr]">
        <div className="flex flex-col ">
          <div className="flex justify-between lg:flex-row flex-col gap-5 mb-10">
            <div>
              <h5 className="font-[600]">Events</h5>
              <div className="mt-3">
                <Button
                  variant="filled"
                  colorScheme="primary"
                  onClick={() => navigate("/create-event")}
                  size="md"
                  icon={<BiPlus />}
                >
                  Create Event
                </Button>
              </div>
            </div>

            <Controller onFilterChanged={handleChange} />
          </div>
        </div>

        {!eventsLoading && (
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5 items-start">
            {paginatedData?.map((item) => (
              <EventsCard key={item.uuid} data={item} />
            ))}
          </div>
        )}

        {eventsLoading && (
          <div
            className={`animate-pulse bg-gray-600 rounded-xl w-full col-span-2 min-h-screen`}
          ></div>
        )}

        {!eventsLoading && !Boolean(list.length) && (
          <div className="min-h-screen flex flex-col gap-3 items-center justify-center">
            <h5 className="font-[600]">No Events</h5>
            <p className="text-sm text-gray-700 max-w-[35ch] text-center">
              All events will be listed here when an event is created.
            </p>
          </div>
        )}

        {/* Pagination */}
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default Events;
