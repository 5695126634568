import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const usePageTitle = (title) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("evently")) navigate("/sign-in");
    document.title = title;
  }, [title]);
};

export default usePageTitle;
