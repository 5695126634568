import axios from "axios";
import { toast } from "react-hot-toast";

export const api = axios.create({
  baseURL: "https://api.eventlyhq.net/v1",
  timeout: 10000,
  withCredentials: true,
});

// Function to handle API errors
// export const handleApiError = (error) => {
//   if (error.response) {
//     //   // The request was made and the server responded with a status code
//     //   // that falls out of the range of 2xx
//     const { data, status } = error.response;
//     if (status === 401) {
//       localStorage.removeItem("evently");
//       toast.error("Session has expired, please sign in.");
//       location.replace("/");
//       return;
//     }
//     toast.error(`Error: ${status} - ${data.message}`);
//   } else if (error.request) {
//     // The request was made but no response was received
//     toast.error(
//       "No response from server. \n Also check your internet connection",
//     );
//   } else {
//     // Something happened in setting up the request that triggered an Error
//     toast.error("Error in request");
//   }
// };

export const handleApiError = (error) => {
  console.log('Full error object:', error);

  if (error.response) {
    const { data, status } = error.response;
    console.log('Response error:', status, data);
    if (status === 401) {
      localStorage.removeItem("evently");
      toast.error("Session has expired, please sign in.");
      location.replace("/");
      return;
    }
    toast.error(`Error: ${status} - ${data.message}`);
  } else if (error.request) {
    console.log('Request error:', error.request);
    console.log('Error config:', error.config);
    toast.error(
      "No response from server. \n Also check your internet connection",
    );
  } else {
    console.log('Error', error.message);
    toast.error("Error in request");
  }
};

// Function to perform GET request
export const getDataWithAuthorization = async (url, token) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };

    const response = await api.get(url, {
      headers,
    });

    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const postDataWithAuthorization = async (url, token) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    };

    const response = await api.post(url, { headers });
    toast.success(response.data.message);
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getData = async (url, token) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };

    return api.get(url, { headers });
    // toast.success(response.data.message);
    // return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

// Function to perform POST request
export const postData = async (url, payload, token) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    };

    const response = await api.post(url, payload, { headers });
    // toast.success(response.data.message);
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

// Function to perform PUT request
export const updateData = async (url, payload) => {
  try {
    const response = await api.put(url, payload);
    toast.success("Data updated successfully");
    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

// Function to perform DELETE request
export const deleteData = async (url, token) => {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    };

    const response = await api.delete(url, { headers });
    toast.success(response.data.message);

    return response.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};
