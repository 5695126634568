import { useNavigate } from "react-router-dom";
import Button from "../../common/button";
import { FiX } from "react-icons/fi";

import { FaCheckCircle, FaImage, FaInfoCircle } from "react-icons/fa";
import Loading from "./loading";

const UploadImage = ({ onPrev, formik, onSubmit, isLoading }) => {
  const navigate = useNavigate();

  const canProceed = formik.values.event_image;

  return (
    <>
      <section className="relative overflow-y-auto lg:rounded-xl scrollbar-thumb-gray-600 lg:w-[40rem] w-full m-auto lg:h-[90%] h-screen scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-full grid items-center">
        {isLoading && <Loading text="updating event" />}
        <div className="bg-white lg:rounded-xl z-30 flex flex-col gap-5 lg:mr-3 p-5 px-10 lg:h-[max-content] h-screen ">
          <nav className="flex justify-between border-b border-gray-300 py-5">
            <h6 className="font-[600]">Add a Photo</h6>

            <span
              className="h-[3rem] w-[3rem] bg-gray-300 flex justify-center items-center rounded-full text-xl cursor-pointer"
              onClick={() => navigate(-1)}
            >
              <FiX />
            </span>
          </nav>

          <div className="flex flex-col gap-5 relative">
            <input
              type="file"
              id="event_image"
              name="event_image"
              onChange={(event) =>
                formik.setFieldValue(
                  "event_image",
                  event.currentTarget.files[0],
                )
              }
              onBlur={formik.handleBlur}
              className="form-input absolute h-full w-full opacity-0 cursor-pointer"
            />

            {!formik.values.event_image && (
              <div
                className={`border-dashed border-2 ${
                  formik.errors.event_image
                    ? "border-red-500"
                    : "border-gray-500"
                } bg-gray-300 h-[20rem] p-5 rounded-md text-center flex flex-col gap-2 items-center justify-center cursor-pointer overflow-hidden`}
              >
                <FaImage className="text-3xl text-green-600" />
                <h6 className="h8 font-[700]">Upload Image</h6>
                <p className="text-sm text-gray-700 w-[25ch]">
                  Click here to select a photo from your computer.
                </p>
              </div>
            )}

            {formik.values.event_image && (
              <div className="bg-gray-300 h-[20rem] rounded-md cursor-pointer overflow-hidden">
                {Boolean(formik.values.event_image.type) &&
                  formik.values.event_image && (
                    <img
                      src={URL.createObjectURL(formik.values.event_image)}
                      alt="Uploaded"
                      className="w-full h-full object-cover"
                    />
                  )}

                {!Boolean(formik.values.event_image.type) &&
                  formik.values.event_image.includes(
                    "https://res.cloudinary.com/",
                  ) && (
                    <img
                      src={formik.values.event_image}
                      alt="Uploaded"
                      className="w-full h-full object-cover"
                    />
                  )}
              </div>
            )}

            {!formik.values.event_image && (
              <div className="border-dotted border-3 border-red-500 bg-gray-300 p-5 rounded-md text-center flex gap-2 items-center justify-center">
                <FaInfoCircle className="text-lg text-red-600" />
                {!formik.errors.event_image && (
                  <p className="text-sm">
                    Image size should not be above{" "}
                    <span className="font-[800]">5 MB</span>
                  </p>
                )}

                {formik.errors.event_image && (
                  <p className="text-sm">{formik.errors.event_image}</p>
                )}
              </div>
            )}

            {!formik.errors.event_image && formik.values.event_image && (
              <div className="border border-green-600 bg-green-100 p-5 rounded-md text-center flex gap-2 items-center justify-center">
                <FaCheckCircle className="text-lg text-green-600" />
                <p className="text-sm">Image uploaded successfully</p>
              </div>
            )}

            {formik.errors.event_image && (
              <p className="text-sm">{formik.errors.event_image}</p>
            )}
          </div>

          <div className="flex flex-wrap items-center justify-end gap-4 mt-2 pb-8">
            <Button
              onClick={onPrev}
              variant="outlined"
              size="lg"
              colorScheme="gray"
            >
              Back
            </Button>

            <Button
              onClick={() => formik.setFieldValue("event_image", undefined)}
              variant="outlined"
              size="lg"
              colorScheme="danger"
            >
              Remove
            </Button>

            {canProceed && (
              <Button
                onClick={onSubmit}
                isLoading={isLoading}
                size="lg"
                type="submit"
                variant="filled"
                colorScheme="primary"
              >
                Edit Event
              </Button>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default UploadImage;
