import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { getSidBarList } from "../../../utils/data";
import useStore from "../../store";
import requestsStore from "../../store/requests";

const List = ({ activePath }) => {
  const data = getSidBarList();
  const requests = requestsStore((state) => state.requests);
  const setNavState = useStore((state) => state.setNavState);

  return (
    <ul>
      {data.map((item, index) => {
        const isPath = activePath === item.path;
        const isLast = index === data.length - 1;
        const isRequest = item.path === "/requests";

        return (
          <Link
            className="hover:no-underline"
            to={item.path}
            key={item.id}
            onClick={() => setNavState(false)}
          >
            <li
              className={`flex justify-between items-center px-5 py-5 transition-colors duration-100 ease-in-out border-l-4 ${
                isPath
                  ? "bg-gray-300 border-green-600 text-black"
                  : "border-transparent text-gray-700 hover:text-black "
              }`}
            >
              <div className="flex items-center justify-start gap-2">
                <span
                  className={`text-xl ${isPath && "text-green-600"} ${
                    isLast && "text-red-600"
                  } `}
                >
                  {<item.icon />}
                </span>
                <span className="font-[500] lg:text-[1rem] text-lg">
                  {item.label}
                </span>
              </div>

              {/* {Boolean(requests.length) && isRequest && (
                <span className="bg-yellow-600 text-black rounded-full h-[1.3rem] w-[1.3rem] flex items-center justify-center text-xs font-[900] p-2">
                  {requests.length}
                </span>
              )} */}
            </li>
          </Link>
        );
      })}
    </ul>
  );
};

export default List;

List.protoType = {
  activePath: PropTypes.string,
};
