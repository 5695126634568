import { useEffect, useRef, useState } from "react";
import { FiX } from "react-icons/fi";
import { toast } from "react-hot-toast";
import { BiSolidBell } from "react-icons/bi";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { useOutsideClick } from "@chakra-ui/react";
import notificationsIcon from "../../assets/images/notification-bell.svg";
import NotificationCard from "./notificationCard";
import { deleteData, getData, postData } from "../../utils/api";
import notificationStore from "../store/notifications";

const Notifications = () => {
  const queryClient = useQueryClient();

  const notifications = notificationStore((state) => state.notifications);
  const setNotifications = notificationStore((state) => state.setNotifications);

  const [count, setCount] = useState(10);
  const ref = useRef();
  const [isNotificationsShowing, setIsNotificationsShowing] = useState(false);
  useOutsideClick({
    ref: ref,
    handler: () => setIsNotificationsShowing(false),
  });

  const detailRef = useRef();
  const [isShowingDetail, setIsShowingDetail] = useState(null);
  useOutsideClick({
    ref: detailRef,
    handler: () => {
      setIsShowingDetail(null);
    },
  });

  const { isLoading, isError, error } = useQuery({
    queryKey: ["notifications"],
    queryFn: async () =>
      await getData(`/admin/notifications`, localStorage.getItem("evently")),
    onSuccess: (data) => setNotifications(data.data.data),
  });

  const updateNotificationMutation = useMutation({
    mutationFn: (id) => {
      return postData(
        `/admin/toggle_notifications`,
        {
          id: id,
        },
        localStorage.getItem("evently"),
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["notifications"],
        exact: true,
      });
    },
  });

  const clearNotificationMutation = useMutation({
    mutationFn: () => {
      return deleteData(
        `/admin/clear_notifications`,
        localStorage.getItem("evently"),
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["notifications"],
        exact: true,
      });
    },
  });

  useEffect(() => {
    if (isError && error.response) {
      const message = `Notifications: ${error.response.status}:\n\n ${error.response.data.message}`;
      toast.error(message);
    }

    if (isError && !error.response) toast.error(error.message);
  }, [isError]);

  useEffect(() => {
    setCount(
      notifications.filter((item) => {
        return Boolean(Number(item.is_read)) === false;
      }).length,
    );
  }, [notifications]);

  return (
    <aside className="relative">
      {isShowingDetail && (
        <section className="fixed top-0 left-0 z-40 bg-black bg-opacity-40 grid items-center justify-center w-full h-screen m-auto">
          <div
            ref={detailRef}
            className="bg-white lg:rounded-xl rounded-none p-8 lg:w-[30rem] lg:h-[max-content] w-full h-full"
          >
            <nav className="flex items-center justify-end">
              <span
                onClick={() => setIsShowingDetail(null)}
                className="cursor-pointer flex items-center justify-center text-xl bg-gray-300 rounded-full h-[3rem] w-[3rem]"
              >
                <FiX />
              </span>
            </nav>

            <NotificationCard variant item={isShowingDetail} />
          </div>
        </section>
      )}

      <button
        onClick={() => setIsNotificationsShowing(!isNotificationsShowing)}
        className="relative h-[3rem] w-[3rem] rounded-full flex items-center justify-center text-2xl bg-gray-300 cursor-pointer"
      >
        <BiSolidBell className="text-green-500" />

        {Boolean(count) && (
          <span className="absolute top-0 right-0 bg-yellow-600 rounded-full h-[1.3rem] w-[1.3rem] flex items-center justify-center text-xs font-[900] p-2">
            {count}
          </span>
        )}
      </button>

      {isNotificationsShowing && (
        <div className="md:absolute fixed top-0 right-0 md:py-[70px] md:z-10 z-20 ">
          <div
            ref={ref}
            className="relative bg-white rounded-lg shadow-lg md:w-[25rem] md:h-[30rem] w-[100vw] h-[100vh] px-5 grid grid-rows-[max-content_1fr_max-content]"
          >
            <div className="border-b border-gray-200 py-5 flex justify-between items-center">
              <div className="flex items-center justify-center gap-5">
                <h6 className="lg:h8 h7 font-[600]">
                  Notifications <span className="text-green-500">{count}</span>
                </h6>

                {Boolean(notifications.length) && (
                  <button
                    disabled={clearNotificationMutation.isLoading}
                    onClick={clearNotificationMutation.mutate}
                    className="text-red-600 font-[600] text-sm lg:hidden block"
                  >
                    {clearNotificationMutation.isLoading
                      ? "Clearing..."
                      : "Clear All"}
                  </button>
                )}
              </div>

              <span
                onClick={() => setIsNotificationsShowing(false)}
                className="cursor-pointer flex items-center justify-center text-xl bg-gray-300 rounded-full h-[3rem] w-[3rem]"
              >
                <FiX />
              </span>
            </div>

            <div className="lg:h-[24rem] h-[90vh] py-5 pb-[55px] pr-2 overflow-auto scrollbar-thumb-gray-600 scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-full">
              {!isLoading && Boolean(notifications.length) && (
                <div className="flex flex-col gap-2 bg-white">
                  {notifications.map((item) => (
                    <NotificationCard
                      onView={() => updateNotificationMutation.mutate(item.id)}
                      onShow={() => setIsShowingDetail(item)}
                      key={item.id}
                      item={item}
                    />
                  ))}
                </div>
              )}

              {isLoading && (
                <div
                  className={`animate-pulse bg-gray-600 rounded-xl w-full col-span-2 min-h-full`}
                ></div>
              )}

              {!Boolean(notifications.length) && !isLoading && (
                <div className="flex justify-center items-center flex-col h-[80%] gap-2">
                  <img className="w-[4rem] mb-5" src={notificationsIcon} />
                  <h6 className="h7 font-[600]">It's lonely in here</h6>
                  <p className="text-gray-700 text-sm">
                    You currently don't have any notifications.
                  </p>
                </div>
              )}
            </div>

            {Boolean(notifications.length) && (
              <div className="lg:flex hidden absolute bottom-[65px] left-0 w-full items-center justify-center py-6 pb-9 blur-background">
                <button
                  disabled={clearNotificationMutation.isLoading}
                  onClick={clearNotificationMutation.mutate}
                  className="text-red-600 font-[600] text-sm"
                >
                  {clearNotificationMutation.isLoading
                    ? "Clearing..."
                    : "Clear Notifications"}
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </aside>
  );
};

export default Notifications;
