import { create } from "zustand";

const archivesStore = create((set) => ({
  archives: [],
  archivesLoading: false,

  setArchives: (archives) => set({ archives: archives }),
  setArchivesLoading: (value) => set({ archivesLoading: value }),
}));

export default archivesStore;
