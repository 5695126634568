import { useRef } from "react";
import QRCode from "react-qr-code";
import Button from "../common/button";
import { MdOutlineFileDownload } from "react-icons/md";
import jsPDF from "jspdf";

// eslint-disable-next-line react/prop-types
const QRCodeComponent = ({ eventDetail }) => {
  const qrRef = useRef();

  const downloadQRCode = async () => {
    console.log("downloadQRCode function called");
    const svg = qrRef.current.querySelector("svg");
    console.log("SVG element:", svg);
    if (svg) {
      const svgData = new XMLSerializer().serializeToString(svg);
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.src = "data:image/svg+xml;base64," + btoa(svgData);
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "pt",
          format: [500, 500],
        });
        pdf.addImage(imgData, "PNG", 0, 0);
        pdf.save(`QRCode_${eventDetail?.uuid}.pdf`);
      };
    } else {
      console.error("SVG not found");
    }
  };

  return (
    <figure
      ref={qrRef}
      className="bg-white rounded-xl h-[30rem] flex flex-col py-10"
    >
      <QRCode
        level="H"
        value={`https://scan.eventlyhq.net/event/${eventDetail?.uuid}`}
        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
      />
      <div className="mt-4">
        <center>
          <Button
            variant="filled"
            colorScheme="primary"
            size="sm"
            icon={<MdOutlineFileDownload size={18} />}
            onClick={downloadQRCode}
          >
            Download Code
          </Button>
        </center>
      </div>
    </figure>
  );
};

export default QRCodeComponent;
