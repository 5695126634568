import usePageTitle from "../../hooks/pageTitle";
import Stats from "./stats";
import RequestsTable from "../requests/requestsTable";

const DashBoard = () => {
  usePageTitle("Evently — Dashboard");

  return (
    <div className="pb-[6.3rem] min-h-full w-full bg-gray-300 flex flex-col gap-5 ">
      <div className="py-10 lg:px-10 px-5 flex flex-col gap-12 lg:max-w-[80rem] lg:mx-auto sm:w-[100%]">
        <Stats />
        <RequestsTable isVariant />
      </div>
    </div>
  );
};

export default DashBoard;
