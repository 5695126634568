import { createBrowserRouter } from "react-router-dom";

import Layout from "./components/layout";
import SignIn from "./components/pages/signIn";
// import NotFound from "./components/notFound";
import SignOut from "./components/pages/signOut";
import DashBaord from "./components/pages/dashboard";
import Events from "./components/pages/events";
import Requests from "./components/pages/requests";
import Archive from "./components/pages/archive";
import EventDetail from "./components/pages/events/eventDetail";
import CreateEvent from "./components/pages/createEvent";
import SearchPage from "./components/pages/searchPage";
import Settings from "./components/pages/settings";
import EditEvent from "./components/pages/editEvent";
import LineManagers from "./components/pages/lineManagers";
import SendNotification from "./components/pages/sendNotification";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    // errorElement: <NotFound />,
    children: [
      {
        path: "/",
        element: <DashBaord />,
      },
      {
        path: "/events",
        element: <Events />,
      },
      {
        path: "/requests",
        element: <Requests />,
      },
      {
        path: "/archive",
        element: <Archive />,
      },
      {
        path: "/send-notification",
        element: <SendNotification />,
      },
      {
        path: "/line-managers",
        element: <LineManagers />,
      },
      {
        path: "/create-event",
        element: <CreateEvent />,
      },
      {
        path: "/edit-event/:eventId",
        element: <EditEvent />,
      },
      {
        path: "/add-attendees/:eventId",
        element: <CreateEvent tabNumber={5} />,
      },
      {
        path: "/add-speakers/:eventId",
        element: <CreateEvent tabNumber={6} />,
      },
      {
        path: "/invite-attendees/:eventId",
        element: <CreateEvent tabNumber={7} />,
      },
      {
        path: "/view-attendees/:eventId",
        element: <CreateEvent tabNumber={8} />,
      },
      {
        path: "/invite-speakers/:eventId",
        element: <CreateEvent tabNumber={9} />,
      },
      {
        path: "/bulk-invite/:eventId",
        element: <CreateEvent tabNumber={10} />,
      },
      {
        path: "/event-detail/:id/:prevPathName?",
        element: <EventDetail />,
      },
      {
        path: "search/:term?",
        element: <SearchPage />,
      },
      {
        path: "/sign-out",
        element: <SignOut />,
      },
      {
        path: "/settings",
        element: <Settings />,
      },
    ],
  },

  {
    path: "/sign-in",
    element: <SignIn />,
  },

  {
    path: "/sign-out",
    element: <SignOut />,
  },
]);

export default router;
