import { useNavigate } from "react-router-dom";
import Button from "../common/button";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteData, getData, postData } from "../../utils/api";
import { useState } from "react";
import { BiMinusCircle } from "react-icons/bi";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment/moment";
import { FaPlus } from "react-icons/fa";
const validationSchema = Yup.object().shape({
  line_manager: Yup.string().required("Required"),
});

const LineManagers = () => {
  const navigate = useNavigate();
  const [lineManagers, setLineManagers] = useState([]);
  const [admins, setAdmins] = useState([]);
  const queryClient = useQueryClient();

  const token = localStorage.getItem("evently");
  const lineManagersQuery = useQuery({
    queryKey: ["lineManagers"],
    queryFn: async () => await getData("/admin/fetch_line_managers", token),
    onSuccess: (data) => setLineManagers(data.data.data),
  });
  useQuery({
    queryKey: ["admins"],
    queryFn: async () => await getData("/admin/fetch_admins", token),
    onSuccess: (data) => setAdmins(data.data.data),
  });
  const mutation = useMutation({
    mutationFn: (data) => {
      return postData("/admin/add_line_manager", data, token);
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["lineManagers"],
        exact: true,
      }),
  });
  const deleteMutation = useMutation({
    mutationFn: (id) => {
      return deleteData(`/admin/remove_line_manager/${id}`, token);
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["lineManagers"],
        exact: true,
      }),
  });

  const handleSubmit = (value) => {
    mutation.mutate({ admin_uuid: value });
  };

  const getFieldStyle = (error) => {
    return `form-input bg-gray-200 px-5 py-3 outline-none border focus:border-green-600 rounded-md w-full text-md 
    scrollbar-track-gray-100 scrollbar-thin bg-white scrollbar-rounded-full appearance-none
    ${
      formik.touched[error] && formik.errors[error]
        ? "border-red-600 focus:border-red-600"
        : "border-transparent"
    }`;
  };

  const formik = useFormik({
    initialValues: {
      line_manager: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <div className="relative pt-10 pb-40 min-h-full w-full bg-gray-300 first-letter: grid grid-rows-[max-content_max-content_1fr]">
      <div className="max-w-[80rem] mx-auto lg:px-10 px-5 h-full w-full grid grid-rows-[max-content_1fr]">
        <div className="flex justify-between">
          <span className="text-gray-700 flex gap-2 items-center justify-start">
            <h5 className="font-[600] text-black">Line Managers</h5>
          </span>

          <Button
            size="md"
            variant="filled"
            colorScheme="danger"
            onClick={() => navigate(-1)}
          >
            Go back
          </Button>
        </div>

        <div className="mt-10 mb-10 grid grid-cols-[1fr_max-content] gap-4">
          <div className="flex flex-col gap-5">
            <label
              htmlFor="admins"
              className="text-lg font-[400] flex gap-2 items-center justify-start"
            >
              Add Line Manager
              {formik.touched.line_manager && formik.errors.line_manager && (
                <span className="text-red-600 text-sm">
                  {formik.errors.line_manager}
                </span>
              )}
            </label>
            <select
              id="line_manager"
              name="line_manager"
              onBlur={formik.handleBlur}
              className={getFieldStyle("line_manager")}
              onChange={formik.handleChange}
              value={formik.values.line_manager}
            >
              <option value="" disabled>
                Select
              </option>
              {admins.map((admin) => (
                <option key={admin.uuid} value={admin.uuid}>
                  {admin.first_name} {admin.last_name}
                </option>
              ))}
            </select>
          </div>
          <div className="grid items-end justify-center">
            <Button
              size="lg"
              icon={<FaPlus className="text-sm" />}
              onClick={formik.handleSubmit}
              type="submit"
              variant="filled"
              colorScheme="success"
              isLoading={mutation.isLoading}
            >
              Add
            </Button>
          </div>
        </div>

        <div
          className={`flex flex-col gap-3 lg:py-5 py-2 h-max-content pr-3 scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-full pb-5 bg-white rounded-lg lg:px-8 px-4 grid-rows-[max-content]`}
        >
          {lineManagersQuery.isLoading && (
            <div className="flex flex-col items-center justify-center gap-3 h-[15rem] w-full">
              <span className="text-center">Loading...</span>
            </div>
          )}

          {!lineManagersQuery.isLoading && !Boolean(lineManagers.length) && (
            <div className="flex flex-col items-center justify-center gap-3 h-[15rem] w-full">
              <h6 className="font-[600] text-sm">No Line Manager yet</h6>
            </div>
          )}

          {!lineManagersQuery.isLoading && Boolean(lineManagers.length) && (
            <table className="lg:table hidden">
              <thead>
                <tr className="text-gray-700 text-sm border-b border-gray-300 font-[600]  text-left">
                  <th className="pb-8 py-2 font-[500]">Name</th>
                  <th className="pb-8 py-2 font-[500]">Email</th>
                  <th className="pb-8 py-2 font-[500]">Added Date</th>
                  <th className="pb-8 py-2 font-[500]"></th>
                </tr>
              </thead>
              <tbody className="font-[600] relative divide-gray-200">
                {lineManagers.map((manager) => (
                  <tr key={manager.id}>
                    <td className="py-4 whitespace-nowrap">
                      {manager.first_name} {manager.last_name}
                    </td>
                    <td className="py-4 whitespace-nowrap">{manager.email}</td>
                    <td className="py-4 whitespace-nowrap">
                      {moment(manager.updated_at).format("ll")}
                    </td>
                    <td>
                      <span className="cursor-pointer text-xl text-red-500">
                        {deleteMutation.isLoading ? (
                          <BiMinusCircle className="opacity-5 cursor-not-allowed animate-pulse" />
                        ) : (
                          <BiMinusCircle
                            onClick={() => deleteMutation.mutate(manager.uuid)}
                          />
                        )}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {!mutation.isLoading && Boolean(lineManagers.length) && (
            <table className="lg:hidden table">
              <thead>
                <tr className="text-gray-700 text-sm border-b border-gray-300 font-[600]  text-left">
                  <th className="pb-8 py-2 font-[500]">Name</th>
                  <th className="pb-8 py-2 font-[500]">Email</th>

                  <th className="pb-8 py-2 font-[500]"></th>
                </tr>
              </thead>
              <tbody className="font-[600] relative divide-gray-200">
                {lineManagers.map((manager) => (
                  <tr>
                    <td className="py-4 whitespace-nowrap">
                      {manager.first_name} {manager.last_name}
                    </td>
                    <td className="py-4 whitespace-nowrap">
                      {moment(manager.updated_at).format("ll")}
                    </td>
                    <td>
                      <span className="cursor-pointer text-xl text-red-500">
                        {deleteMutation.isLoading ? (
                          <BiMinusCircle className="opacity-5 cursor-not-allowed animate-pulse" />
                        ) : (
                          <BiMinusCircle
                            onClick={() => deleteMutation.mutate(manager.uuid)}
                          />
                        )}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default LineManagers;
