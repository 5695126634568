import moment from "moment";
import { FaBookmark, FaUser } from "react-icons/fa";
import {
  BiSolidTrash,
  BiSolidPencil,
  BiPlus,
  BiTrash,
  BiChart,
} from "react-icons/bi";
import { FiX } from "react-icons/fi";

import { Fragment, useEffect, useRef } from "react";
import { toast } from "react-hot-toast";
import { FaMapPin } from "react-icons/fa";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import Category from "../../common/category";
import Button from "../../common/button";
import eventDetailStore from "../../store/eventDetail";
import { deleteData, getData, postData } from "../../../utils/api";
import createEventStore from "../../store/createEvent";
import {
  Box,
  Flex,
  Text,
  useDisclosure,
  useOutsideClick,
} from "@chakra-ui/react";
import { useState } from "react";
import EditSpeaker from "../createEvent/editSpeaker";
import StatsCard from "../../common/statsCard";
import ModalLayout from "../../layouts/modalLayout";
import QRCodeComponent from "../../ misc/qrcode";

const EventDetail = () => {
  const queryClient = useQueryClient();
  const { id, prevPathName } = useParams();
  const token = localStorage.getItem("evently");
  const navigate = useNavigate();
  const [showReport, setShowReport] = useState(false);

  const setExternalAttendeeEventId = createEventStore(
    (state) => state.setExternalAttendeeEventId,
  );

  const setEditEventId = eventDetailStore((state) => state.setEditEventId);
  const eventDetailLoading = eventDetailStore(
    (state) => state.eventDetailLoading,
  );
  const eventDetail = eventDetailStore((state) => state.eventDetail);
  const setEventDetail = eventDetailStore((state) => state.setEventDetail);
  const setEventDetailLoading = eventDetailStore(
    (state) => state.setEventDetailLoading,
  );

  const deleteEventMutation = useMutation({
    mutationFn: (id) => {
      return deleteData(
        `/admin/delete_event/${id}`,
        localStorage.getItem("evently"),
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["counter"], exact: true });
      queryClient.invalidateQueries({
        queryKey: ["requestTable"],
        exact: true,
      });
      queryClient.invalidateQueries({
        queryKey: ["notifications"],
        exact: true,
      });

      navigate("/events");
    },
  });

  const { isLoading, isError, error } = useQuery({
    queryKey: ["eventDetail"],
    cacheTime: 0,
    staleTime: 0,
    queryFn: async () => await getData(`/admin/event_detail/${id}`, token),
    onSuccess: (data) => {
      return setEventDetail({
        id: data.data.data.id,
        uuid: data.data.data.uuid,
        eventCode: data.data.data.event_code,
        image: data.data.data.event_image,
        location: data.data.data.event_location,
        tags: data.data.data.tags,
        title: data.data.data.event_title,
        description: data.data.data.event_description,
        date: data.data.data.event_date,
        time: data.data.data.event_time,
        attendees: data.data.data.attendees,
        speakers: data.data.data.speakers,
        requester: data.data.data.event_requester,
        status: data.data.data.event_status,
        seatingStyle: data.data.data.event_seating_style,
      });
    },
  });

  const renderButton = () => {
    if (prevPathName === "edit-event")
      return (
        <Button
          size="md"
          variant="filled"
          colorScheme="primary"
          onClick={() => navigate("/events")}
        >
          Go to events
        </Button>
      );

    if (prevPathName === "create-event")
      return (
        <Button
          size="md"
          variant="filled"
          colorScheme="primary"
          onClick={() => navigate("/events")}
        >
          Go to events
        </Button>
      );

    return (
      <Button
        size="md"
        variant="filled"
        colorScheme="danger"
        onClick={() => navigate(-1)}
      >
        Go back
      </Button>
    );
  };

  const speakerRef = useRef();
  const [isShowingSpeakers, setIsShowingSpeakers] = useState(null);
  const [isShowingEditSpeaker, setIsShowingEditSpeaker] = useState(null);
  const [totalNumberOfPeopleInvited, setTotalNumberOfPeopleInvited] =
    useState(0);
  const [totalNumberOfPeopleWhoAttended, setTotalNumberOfPeopleWhoAttended] =
    useState(0);
  const [
    totalNumberOfPeopleWhoDidNotAttend,
    setTotalNumberOfPeopleWhoDidNotAttend,
  ] = useState(0);
  const [performanceAverageCalculation, setPerformanceAverageCalculation] =
    useState(0);

  useOutsideClick({
    ref: speakerRef,
    handler: () => {},
  });

  const deleteSpeakerMutation = useMutation({
    mutationFn: (speakerId) => {
      return deleteData(`/admin/remove_speaker/${speakerId}/${id}`, token);
    },

    onSuccess: () => {
      setIsShowingSpeakers(null);
      queryClient.invalidateQueries({ queryKey: ["eventDetail"] });
    },
  });

  const totalNumberOfPeopleInvitedMutation = useMutation({
    mutationFn: (data) => {
      return postData(`/admin/count_of_people_invited`, data, token);
    },

    onSuccess: async (data) => {
      setTotalNumberOfPeopleInvited(data.data.total_number);
    },
  });

  const totalNumberOfPeopleWhoAttendedMutation = useMutation({
    mutationFn: (data) => {
      return postData(`/admin/count_of_people_attended`, data, token);
    },

    onSuccess: async (data) => {
      setTotalNumberOfPeopleWhoAttended(data.data.total_number);
    },
  });

  const totalNumberOfPeopleWhoDidNotAttendMutation = useMutation({
    mutationFn: (data) => {
      return postData(`/admin/count_of_people_not_attended`, data, token);
    },

    onSuccess: async (data) => {
      setTotalNumberOfPeopleWhoDidNotAttend(data.data.total_number);
    },
  });

  const performanceAverageCalculationMutation = useMutation({
    mutationFn: (data) => {
      return postData(`/admin/event_performance_report`, data, token);
    },

    onSuccess: async (data) => {
      setPerformanceAverageCalculation(data.data.performance);
    },
  });

  useEffect(() => {
    setEventDetailLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (isError && error.response)
      toast.error(
        `Event Details: ${error.response.status}:\n\n ${error.response.data.message}`,
      );

    if (isError) toast.error(error.message);
  }, [isError]);

  useEffect(() => {
    if (showReport) {
      totalNumberOfPeopleInvitedMutation.mutate({ event_id: eventDetail.id });
      totalNumberOfPeopleWhoAttendedMutation.mutate({
        event_id: eventDetail.id,
      });
      totalNumberOfPeopleWhoDidNotAttendMutation.mutate({
        event_id: eventDetail.id,
      });
      performanceAverageCalculationMutation.mutate({
        event_id: eventDetail.id,
      });
    }
  }, [showReport]);

  const { isOpen, onClose, onOpen } = useDisclosure();

  const performanceAverageValue = Number.isInteger(performanceAverageCalculation)
    ? performanceAverageCalculation
    : performanceAverageCalculation.toFixed(2);

  return (
    <div className="relative pt-10 pb-40 min-h-full w-full bg-gray-300 first-letter: grid grid-rows-[max-content_max-content_1fr]">
      {showReport && (
        <section className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-20 flex z-50">
          <section
            className="overflow-hidden grid items-center  lg:w-[60rem] w-full m-auto lg:h-[fit-content]  
             scrollbar-thumb-gray-600scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-full"
          >
            <div className="bg-white lg:rounded-xl rounded-none flex flex-col gap-5 lg:mr-3 py-[2em] px-8 relative overflow-y-auto h-[100%] scrollbar-thumb-gray-600scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-full">
              <nav className="flex justify-between border-b border-gray-300 py-5">
                <h6 className="font-[600]">Performance Report</h6>

                <span
                  className="h-[3rem] w-[3rem] bg-gray-300 flex justify-center items-center rounded-full text-xl cursor-pointer"
                  onClick={() => setShowReport(false)}
                >
                  <FiX />
                </span>
              </nav>

              <div className="grid grid-cols-2 gap-5 mb-5">
                <StatsCard
                  title="Total Number of People Invited"
                  value={totalNumberOfPeopleInvited}
                />
                <StatsCard
                  title="Total Number of People Who Attended"
                  value={totalNumberOfPeopleWhoAttended}
                />
                <StatsCard
                  title="Total Number of People Who Didn't Attended"
                  value={totalNumberOfPeopleWhoDidNotAttend}
                />
                <StatsCard
                  title="Performance Average Calculation"
                  withChart
                  value={performanceAverageValue}
                />
              </div>
            </div>
          </section>
        </section>
      )}

      {isShowingSpeakers && (
        <section className="fixed top-0 left-0 z-40 bg-black bg-opacity-40 grid items-center justify-center w-full h-screen m-auto">
          <div
            ref={speakerRef}
            className="bg-white lg:rounded-xl rounded-none p-8 lg:w-[30rem] lg:h-[max-content] w-full h-full grid"
          >
            <nav className="flex items-center justify-end">
              <span
                onClick={() => setIsShowingSpeakers(null)}
                className="cursor-pointer flex items-center justify-center text-xl bg-gray-300 rounded-full h-[3rem] w-[3rem]"
              >
                <FiX />
              </span>
            </nav>

            <div className="grid h-[min-content] place-items-center text-center">
              <figure className="relative bg-yellow-600 h-[7rem] w-[7rem] rounded-full overflow-hidden">
                <img
                  className="absolute top-0 left-0 w-full h-full object-cover"
                  src={isShowingSpeakers.profile_image}
                />

                <span className="h-full flex items-center justify-center font-[700] text-4xl">
                  {isShowingSpeakers.first_name[0]}
                  {isShowingSpeakers.last_name[0]}
                </span>
              </figure>

              <h4 className="mt-2">
                {isShowingSpeakers.first_name} {isShowingSpeakers.last_name}
              </h4>

              <p className="max-w-[40ch] text-gray-700 text-sm mt-5 mb-10">
                {isShowingSpeakers.bio_info}
              </p>

              <div className="grid grid-cols-2 gap-1">
                <Button
                  variant="filled"
                  colorScheme="danger"
                  size="sm"
                  icon={<BiTrash />}
                  isLoading={deleteSpeakerMutation.isLoading}
                  onClick={() => {
                    deleteSpeakerMutation.mutate(isShowingSpeakers.uuid);
                  }}
                >
                  Delete Speaker
                </Button>
                <Button
                  variant="filled"
                  icon={<BiSolidPencil />}
                  colorScheme="primary"
                  size="sm"
                  onClick={() => setIsShowingEditSpeaker(true)}
                >
                  Edit Speaker
                </Button>
              </div>
            </div>
          </div>
        </section>
      )}

      {isShowingEditSpeaker && (
        <section className="fixed top-0 left-0 z-40 bg-black bg-opacity-40 grid items-center justify-center w-full h-screen m-auto">
          <EditSpeaker
            speakerDetails={isShowingSpeakers}
            onClose={() => setIsShowingEditSpeaker(false)}
          />
        </section>
      )}

      <div className="max-w-[80rem] mx-auto lg:px-10 px-5 h-full w-full grid grid-rows-[max-content_1fr]">
        <div className="mb-5">{renderButton()}</div>

        <div className="grid lg:grid-cols-[1.5fr_1fr] grid-cols-1 gap-5 ">
          <div className="flex flex-col gap-5">
            {!eventDetailLoading && (
              <figure className="bg-gray-600 rounded-xl overflow-hidden h-[30rem]">
                {eventDetail.image && (
                  <img
                    src={eventDetail.image}
                    className="w-full h-full object-cover"
                  />
                )}
              </figure>
            )}
            {eventDetailLoading && (
              <div
                className={`animate-pulse bg-gray-600 rounded-xl w-full col-span-2 h-[30rem] lg:mt-0 my-2`}
              ></div>
            )}

            {!eventDetailLoading && (
              <div className="flex flex-wrap gap-1 mt-10">
                {eventDetail.tags.map((item) => (
                  <Category key={item.id} variant={item} />
                ))}
              </div>
            )}

            {eventDetailLoading && (
              <div
                className={`animate-pulse bg-gray-600 rounded-xl w-full col-span-2 h-[2rem] lg:mt-0 my-2`}
              ></div>
            )}

            <div className="flex flex-col gap-5">
              {!eventDetailLoading && (
                <h6 className="font-[600]">{eventDetail.title}</h6>
              )}

              {!eventDetailLoading && !eventDetail.title && (
                <h6 className="font-[600]">No Title</h6>
              )}

              {eventDetailLoading && (
                <div
                  className={`animate-pulse bg-gray-600 rounded-xl w-full col-span-2 h-[2rem] lg:mt-0 my-2`}
                ></div>
              )}

              {!eventDetailLoading && (
                <p className="text-gray-700 lg:w-[60ch] w-[50ch] text-sm">
                  {eventDetail.description}
                </p>
              )}

              {!eventDetailLoading && !eventDetail.description && (
                <p className="text-gray-700 text-sm">No description</p>
              )}
              {eventDetailLoading && (
                <div
                  className={`animate-pulse bg-gray-600 rounded-xl w-full col-span-2 h-[4rem] lg:mt-0 my-2`}
                ></div>
              )}
            </div>

            {!eventDetailLoading && (
              <div className="flex flex-col gap-1">
                <div className="flex gap-1 items-center justify-start  text-md font-[500]">
                  <span>{moment(eventDetail.date).format("MMM D")}</span>
                  <span>・</span>
                  <span>
                    {moment(`${eventDetail.date} ${eventDetail.time}`).format(
                      "h:mm A",
                    )}
                  </span>
                </div>
              </div>
            )}

            {eventDetailLoading && (
              <div
                className={`animate-pulse bg-gray-600 rounded-xl w-full col-span-2 h-[2rem] lg:mt-0 my-2`}
              ></div>
            )}

            <div className="flex flex-col gap-5 mt-5">
              <div className="flex flex-col items-start gap-1">
                <span className="flex gap-2">
                  <h6 className="text-md font-[600]">Attendee(s)</h6>
                  <span className="text-green-500 font-[600] mr-5">
                    {eventDetail.attendees?.length}
                  </span>
                </span>
                {Boolean(eventDetail.attendees?.length) && (
                  <div className="flex flex-wrap gap-2">
                    {/* <Button
                      variant="filled"
                      colorScheme="primary"
                      size="sm"
                      onClick={() => {
                        setEditEventId(eventDetail.id);
                        navigate(`/add-attendees/${eventDetail.uuid}`);
                      }}
                    >
                      Add Internal
                    </Button> */}
                    <Button
                      variant="filled"
                      colorScheme="primary"
                      size="sm"
                      onClick={() => {
                        setExternalAttendeeEventId(eventDetail.id);
                        setEditEventId(eventDetail.id);
                        navigate(`/invite-attendees/${eventDetail.uuid}`);
                      }}
                    >
                      Single Invite
                    </Button>
                    <Button
                      variant="filled"
                      colorScheme="primary"
                      size="sm"
                      onClick={() => {
                        const eventId = eventDetail?.id;
                        setExternalAttendeeEventId(eventDetail.id);
                        setEditEventId(eventDetail.id);
                        navigate(
                          `/bulk-invite/${eventDetail.uuid}?id=${eventId}`,
                        );
                      }}
                    >
                      Bulk Invite
                    </Button>
                    <Button
                      variant="filled"
                      colorScheme="primary"
                      size="sm"
                      onClick={() => {
                        setExternalAttendeeEventId(eventDetail.id);
                        setEditEventId(eventDetail.id);
                        navigate(`/view-attendees/${eventDetail.uuid}`);
                      }}
                    >
                      View All
                    </Button>
                  </div>
                )}
              </div>
              {eventDetailLoading && (
                <div
                  className={`animate-pulse bg-gray-600 rounded-xl w-full col-span-2 h-[3rem] lg:mt-0 my-2`}
                ></div>
              )}
              {!eventDetailLoading &&
                Boolean(eventDetail.attendees?.length) && (
                  <div className="flex pl-2 items-center justify-start">
                    {eventDetail.attendees.slice(0, 4).map((item, index) => (
                      <Fragment key={index}>
                        <figure
                          className={`z-${index + 1}  
bg-yellow-600 ml-[-10px] flex items-center justify-center w-[3.5rem] h-[3.5rem] rounded-full overflow-hidden border border-yellow-100`}
                        >
                          <span className="font-[700] text-xl">
                            {item.attendee_first_name[0]}
                            {item.attendee_last_name[0]}
                          </span>
                        </figure>
                      </Fragment>
                    ))}

                    {Boolean(eventDetail.attendees?.length >= 4) && (
                      <span className="h8 font-[800] ml-3 text-xs font-sans text-green-600">
                        {eventDetail.attendees?.length - 4}+ Attending
                      </span>
                    )}
                  </div>
                )}
            </div>

            {!eventDetailLoading && !Boolean(eventDetail.attendees?.length) && (
              <div className="flex flex-wrap gap-2">
                {/* <Button
                  size="sm"
                  variant="outlined"
                  colorScheme="primary"
                  icon={<BiPlus />}
                  onClick={() => {
                    setEditEventId(eventDetail.id);
                    navigate(`/add-attendees/${eventDetail.uuid}`);
                  }}
                >
                  Add Internal
                </Button> */}
                <Button
                  size="sm"
                  variant="outlined"
                  colorScheme="primary"
                  icon={<BiPlus />}
                  onClick={() => {
                    setExternalAttendeeEventId(eventDetail.id);
                    setEditEventId(eventDetail.id);
                    navigate(`/invite-attendees/${eventDetail.uuid}`);
                  }}
                >
                  Single Invite
                </Button>
                <Button
                  variant="outlined"
                  colorScheme="primary"
                  size="sm"
                  onClick={() => {
                    setExternalAttendeeEventId(eventDetail.id);
                    setEditEventId(eventDetail.id);
                    navigate(`/bulk-invite/${eventDetail.uuid}`);
                  }}
                >
                  Bulk Invite
                </Button>
              </div>
            )}

            <div className="lg:flex hidden items-start gap-2 mb-20 mt-10">
              <Button
                icon={<BiChart />}
                variant="filled"
                colorScheme="info"
                size="md"
                onClick={() => setShowReport(true)}
              >
                View Report
              </Button>
              <Button
                icon={<BiSolidPencil />}
                variant="filled"
                colorScheme="primary"
                size="md"
                onClick={() => {
                  navigate(`/edit-event/${eventDetail.uuid}`);
                }}
              >
                Edit
              </Button>
              <Button
                icon={<BiSolidTrash />}
                variant="filled"
                colorScheme="danger"
                size="md"
                onClick={onOpen}
              >
                Delete
              </Button>
            </div>
            <div className="lg:hidden flex flex-col items-start gap-4  fixed bottom-10 right-10 text-white z-30">
              <span
                className="h-[3rem] w-[3rem] text-2xl rounded-full flex items-center justify-center bg-blue-600 cursor-pointer shadow-lg"
                onClick={() => setShowReport(true)}
              >
                <BiChart />
              </span>
              <span
                className="h-[3rem] w-[3rem] text-2xl rounded-full flex items-center justify-center bg-green-600 cursor-pointer shadow-lg"
                onClick={() => {
                  navigate(`/edit-event/${eventDetail.uuid}`);
                }}
              >
                <BiSolidPencil />
              </span>

              <span
                className={`h-[3rem] w-[3rem] text-2xl rounded-full flex items-center justify-center bg-red-600 cursor-pointer shadow-lg ${
                  deleteEventMutation.isLoading &&
                  "opacity-5 cursor-not-allowed animate-pulse"
                }`}
                onClick={() =>
                  // !deleteEventMutation.isLoading &&
                  // deleteEventMutation.mutate(eventDetail.uuid)
                  onOpen()
                }
              >
                <BiSolidTrash />
              </span>
            </div>
          </div>

          <div className="flex flex-col gap-5">
            {!eventDetailLoading && (
              <QRCodeComponent eventDetail={eventDetail} />
            )}
            {eventDetailLoading && (
              <div
                className={`animate-pulse bg-gray-600 rounded-xl w-full col-span-2 h-[30rem] lg:mt-0 my-2`}
              ></div>
            )}

            <div className="flex flex-col gap-2 mt-10">
              <h6 className="mb-3 text-md font-[600]">Details</h6>
              {!eventDetailLoading && (
                <div className="flex items-center gap-2">
                  <span className="h-[2.5rem] w-[2.5rem] rounded-full bg-yellow-600 flex items-center justify-center">
                    <FaMapPin className="text-md" />
                  </span>

                  <p className="font-[600]">{eventDetail.location}</p>
                  {!eventDetail.location && (
                    <p className="font-[600] text-sm text-gray-700">
                      Not Specified
                    </p>
                  )}
                </div>
              )}

              {eventDetailLoading && (
                <div
                  className={`animate-pulse bg-gray-600 rounded-xl w-full col-span-2 h-[3.5rem] lg:mt-0 my-2`}
                ></div>
              )}
              {!eventDetailLoading && (
                <div className="flex items-start gap-2">
                  <span className="h-[2.5rem] w-[2.5rem] rounded-full bg-yellow-600 flex items-center justify-center">
                    <FaUser className="text-md" />
                  </span>

                  <div className="flex flex-col gap-1">
                    <p className="text-gray-700 text-sm">Requested By:</p>
                    {!eventDetail.requester && (
                      <p className="font-[600] text-sm text-gray-700">
                        Not Specified
                      </p>
                    )}
                    <p className="font-[600]">{eventDetail.requester}</p>
                  </div>
                </div>
              )}
              {!eventDetailLoading && (
                <div className="flex items-start gap-2">
                  <span className="h-[2.5rem] w-[2.5rem] rounded-full bg-yellow-600 flex items-center justify-center">
                    <FaBookmark className="text-sm" />
                  </span>

                  <div className="flex flex-col gap-1">
                    <p className="text-gray-700 text-sm">Event Code:</p>
                    {!eventDetail.eventCode && (
                      <p className="font-[600] text-sm text-gray-700">None</p>
                    )}
                    <p className="font-[600]">{eventDetail.eventCode}</p>
                  </div>
                </div>
              )}

              {eventDetailLoading && (
                <div
                  className={`animate-pulse bg-gray-600 rounded-xl w-full col-span-2 h-[3rem] lg:mt-0 my-2`}
                ></div>
              )}
            </div>

            <div className="flex flex-col gap-5 mt-10">
              <div className="flex flex-col items-start gap-1">
                <div className="flex items-center gap-1">
                  <h6 className="text-md font-[600]">Speaker(s)</h6>
                  <span className="text-green-500 font-[600] mr-5">
                    {eventDetail.speakers?.length}
                  </span>
                </div>
                {Boolean(eventDetail.speakers?.length) && (
                  // <Button
                  //   variant="filled"
                  //   colorScheme="primary"
                  //   size="sm"
                  //   onClick={() => {
                  //     setEditEventId(eventDetail.id);
                  //     navigate(`/add-speakers/${eventDetail.uuid}`);
                  //   }}
                  // >
                  //   Add/Edit
                  // </Button>
                  <Button
                    variant="filled"
                    colorScheme="primary"
                    size="sm"
                    onClick={() => {
                      setEditEventId(eventDetail.id);
                      setExternalAttendeeEventId(eventDetail.id);
                      navigate(`/invite-speakers/${eventDetail.uuid}`);
                    }}
                  >
                    Add
                  </Button>
                )}
              </div>

              {eventDetailLoading && (
                <div
                  className={`animate-pulse bg-gray-600 rounded-xl w-full col-span-2 h-[3rem] lg:mt-0 my-2`}
                ></div>
              )}

              {!eventDetailLoading &&
                Boolean(eventDetail?.speakers?.length) && (
                  <div className="flex pl-2 items-start justify-start gap-5">
                    {eventDetail.speakers.slice(0, 4).map((item, index) => (
                      <div
                        onClick={() => setIsShowingSpeakers(item)}
                        className="grid place-items-center cursor-pointer"
                      >
                        <figure
                          className={`z-${index} 
                        relative bg-yellow-600 ml-[-10px] flex items-center justify-center w-[4rem] h-[4rem] rounded-full overflow-hidden border border-yellow-100`}
                        >
                          {item.profile_image && (
                            <img
                              className="absolute top-0 left-0 w-full h-full object-fill"
                              src={item.profile_image}
                            />
                          )}
                          <span className="font-[700] text-xl">
                            {item.first_name[0]}
                            {item.last_name[0]}
                          </span>
                        </figure>
                        <div className="flex flex-col text-center text-sm">
                          <p className="font-[600] text-sm">
                            {item.first_name}
                          </p>
                          <p className="text-gray-700 text-sm">
                            {item.last_name}
                          </p>
                        </div>
                      </div>
                    ))}

                    {Boolean(eventDetail?.speakers?.length >= 4) && (
                      <span className="h8 font-[800] bg-white flex items-center justify-center w-[4rem] h-[4rem] rounded-full overflow-hidden">
                        +{eventDetail.speakers?.length - 4}
                      </span>
                    )}
                  </div>
                )}

              {!eventDetailLoading &&
                !Boolean(eventDetail?.speakers?.length) && (
                  <div>
                    {/* <Button
                      variant="outlined"
                      colorScheme="primary"
                      size="sm"
                      icon={<BiPlus />}
                      onClick={() => {
                        setEditEventId(eventDetail.id);
                        navigate(`/add-speakers/${eventDetail.uuid}`);
                      }}
                    >
                      Add speakers
                    </Button> */}
                    <Button
                      variant="outlined"
                      colorScheme="primary"
                      size="sm"
                      icon={<BiPlus />}
                      onClick={() => {
                        setEditEventId(eventDetail.id);
                        setExternalAttendeeEventId(eventDetail.id);
                        navigate(`/invite-speakers/${eventDetail.uuid}`);
                      }}
                    >
                      Invite Speakers
                    </Button>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>

      <ModalLayout isOpen={isOpen}>
        <Box textAlign={"center"}>
          <Text fontSize={"4xl"}>❓</Text>
          <Text my={4}>Are you sure you want to delete this event ?</Text>
          <Flex alignItems={"center"} justifyContent={"center"} gap={2} mt={2}>
            <Button
              variant="filled"
              colorScheme="gray"
              size="md"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              variant="filled"
              colorScheme="danger"
              size="md"
              icon={<BiTrash />}
              isLoading={deleteEventMutation.isLoading}
              onClick={() => deleteEventMutation.mutate(eventDetail.uuid)}
            >
              Continue
            </Button>
          </Flex>
        </Box>
      </ModalLayout>
    </div>
  );
};

export default EventDetail;
