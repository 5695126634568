import usePageTitle from "../hooks/pageTitle";
import userStore from "../store/user";
import logoIcon from "../../assets/images/evently-icon.png";
import Button from "../common/button";
import { useNavigate } from "react-router-dom";
import useStore from "../store";

const Settings = () => {
  usePageTitle("Evently — Settings");
  const user = userStore((state) => state.signedInUser);

  const setShowAds = useStore((state) => state.setShowAds);
  const showAds = useStore((state) => state.showAds);

  const navigate = useNavigate();

  const renderAdsStatus = (value) => {
    let checked = value === "yes" ? true : false;

    if (checked)
      return (
        <span className="relative font-[600] text-sm text-green-700">
          Turned on
        </span>
      );
    else
      return (
        <span className="relative font-[600] text-sm text-red-700">
          Turned off
        </span>
      );
  };

  return (
    <div className="relative pt-10 pb-40 min-h-full w-full bg-gray-300 first-letter: grid grid-rows-[max-content_max-content_1fr]">
      <div className="max-w-[80rem] mx-auto lg:px-10 px-5 h-full w-full grid grid-rows-[max-content_1fr]">
        <div className="pb-5">
          <span className="text-gray-700 flex gap-2 items-center justify-start">
            <h5 className="font-[600] text-black">Settings</h5>
          </span>
        </div>

        <div className="bg-white rounded-xl overflow-hidden flex flex-col gap-10 py-10">
          <div className="bg-white w-full px-8">
            <div className="flex flex-col gap-2 border-b border-gray-300 pb-5">
              <span className="h7 font-[600]">Profile</span>
            </div>

            <div className="flex items-center justify-start gap-3 pt-4  border-gray-200">
              <figure className="h-[3rem] w-[3rem] bg-yellow-600 flex text-lg items-center justify-center rounded-full font-[700]">
                {user.initials}
              </figure>
              <p className="flex flex-col font-[600]">
                <span className="lg:text-md text-[1.1rem]">
                  {user.firstName} {user.lastName}
                </span>

                <span className="font-[500] text-sm text-gray-700">Admin</span>
              </p>
            </div>
          </div>

          <div className="bg-white w-full px-8">
            <div className="flex flex-col gap-2 border-b border-gray-300 pb-5">
              <span className="h7 font-[600]">Ads</span>
            </div>

            <div className="flex items-center justify-start gap-3 pt-4 border-gray-200">
              <figure className="h-[3rem] w-[3rem] bg-gray-600 flex text-lg items-center justify-center rounded-full font-[700] p-1">
                <img src={logoIcon} />
              </figure>
              <button
                className="relative flex flex-col font-[600] cursor-pointer"
                onClick={() => {
                  if (showAds === "yes")
                    localStorage.setItem("evently-showAds", "no");

                  if (showAds === "no")
                    localStorage.setItem("evently-showAds", "yes");

                  setShowAds(localStorage.getItem("evently-showAds"));
                }}
              >
                <span className="text-md">Allow Snapcomm Ads</span>
                <div className=" flex gap-2 ">
                  {renderAdsStatus(showAds)}
                  <span className="text-sm font-[500] text-gray-700">
                    (click to toggle)
                  </span>
                </div>
              </button>
            </div>
          </div>

          <div className="bg-white w-full px-8 ">
            <div className="flex flex-col gap-2 border-b border-gray-300 pb-5">
              <span className="h7 font-[600]">Events</span>
            </div>

            <div className="flex lg:flex-row flex-col gap-3 justify-start items-start pt-5">
              <Button
                variant="filled"
                colorScheme="primary"
                onClick={() => navigate("/line-managers")}
                size="md"
              >
                Add line managers
              </Button>
              <Button
                variant="filled"
                onClick={() => navigate("/send-notification")}
                colorScheme="primary"
                size="md"
              >
                Send notifications to all users
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;

Settings.protoType = {};
