import { useEffect, useRef } from "react";
import PropTypes from "prop-types";

const TruncateText = ({ text, className, wordLength }) => {
  const pRef = useRef(null);

  useEffect(() => {
    const pElement = pRef.current;

    const truncateText = (wordLength) => {
      const words = pElement.innerText.split(" ");
      const truncatedText = words.slice(0, wordLength).join(" ");
      const isGreaterThan = words.length >= wordLength;

      if (isGreaterThan) {
        if (words[words.length - 1].includes("...")) return;
        return (pElement.innerText = truncatedText + "...");
      }

      return (pElement.innerText = truncatedText);
    };

    truncateText(wordLength);
  }, []);

  return (
    <p className={className} ref={pRef}>
      {text}
    </p>
  );
};

export default TruncateText;

TruncateText.protoType = {
  text: PropTypes.string,
  className: PropTypes.string,
  wordLength: PropTypes.number,
};
