import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Button from "./button";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  if (totalPages <= 1) return "";

  return (
    <div className="flex items-center justify-center">
      <div className="flex items-center justify-center space-x-4 mt-8 gap-10 bg-white p-5 rounded-xl">
        <button
          className={`flex items-center gap-2 font-[600] cursor-pointer ${
            currentPage <= 1 && "opacity-[.5] cursor-not-allowed"
          }`}
          onClick={handlePreviousPage}
        >
          <FaChevronLeft className="w-5 h-5" />
          Prev
        </button>

        <span className="text-gray-700 font-medium">
          Page {currentPage} of {totalPages}
        </span>

        <button
          className={`flex items-center font-[600] gap-2 cursor-pointer ${
            currentPage >= totalPages && "opacity-[.5] cursor-not-allowed"
          }`}
          onClick={handleNextPage}
        >
          Next
          <FaChevronRight className="w-5 h-5" />
        </button>
      </div>
    </div>
  );
};

export default Pagination;
