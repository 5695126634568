import PropTypes from "prop-types";
import { CgSpinner } from "react-icons/cg";

const Button = ({
  children,
  variant,
  colorScheme,
  size,
  icon,
  type,
  onClick,
  isLoading,
  flex,
  disabled,
}) => {
  const getVariantandColor = () => {
    if (variant === "filled") {
      if (colorScheme === "gray")
        return "bg-gray-600 text-gray-900 hover:bg-gray-500";
      if (colorScheme === "primary")
        return "bg-green-600 text-gray-100 hover:bg-green-500";
      if (colorScheme === "success")
        return "bg-success-600 text-gray-100 hover:bg-success-500";
      if (colorScheme === "danger")
        return "bg-red-600 text-gray-100 hover:bg-red-500";
      if (colorScheme === "info")
        return "bg-blue-600 text-gray-100 hover:bg-blue-500";
    }

    if (variant === "outlined") {
      if (colorScheme === "gray")
        return "border border-gray-600 text-gray-700 hover:bg-gray-600 hover:text-gray-800";
      if (colorScheme === "primary")
        return "border border-green-600 text-green-600 hover:bg-green-600 hover:text-gray-100";
      if (colorScheme === "success")
        return "border border-success-600 text-success-600 hover:bg-success-600 hover:text-success-100";
      if (colorScheme === "danger")
        return "border border-red-600 text-red-600 hover:bg-red-600 hover:text-red-100";
      if (colorScheme === "info")
        return "border border-blue-600 text-blue-600 hover:bg-blue-600 hover:text-blue-100";
    }
  };

  const getSize = () => {
    if (flex) return "w-full px-[50px] py-3";
    if (size === "sm") return "px-3 py-2 text-sm";
    if (size === "md") return "px-4 py-2 text-md";
    if (size === "lg") return "px-6 py-3 text-lg";
    if (size === "xl") return "px-5 py-3 text-xl";
  };

  const getIconSize = () => {
    if (size === "sm") return "text-md";
    if (size === "md") return "text-lg";
    if (size === "lg") return "text-xl";
    if (size === "xl") return "text-2xl";
  };

  return (
    <button
      onClick={onClick}
      type={type}
      disabled={disabled}
      className={`${getVariantandColor()} ${getSize()} rounded-lg flex items-center gap-1 transition-colors duration-100 ease-in-out ${
        !icon && "justify-center"
      }`}
    >
      {icon && <span className={`${getIconSize()}`}>{icon}</span>}
      <span>{isLoading ? "Loading" : children}</span>
      {isLoading && <CgSpinner className="animate-spin text-md" />}
    </button>
  );
};

export default Button;

Button.propTypes = {
  children: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string,
  colorScheme: PropTypes.string,
  size: PropTypes.string,
};
