import { useNavigate } from "react-router-dom";
import Button from "../../common/button";
import { FiX } from "react-icons/fi";

const eventSeatingStyles = [
  {
    id: 2,
    value: "theatre-style",
    label: "Theatre Style",
    text: "A classic linear seating position with a single focal point.",
  },
  {
    id: 3,
    value: "banquet-style",
    label: "Banquet Style",
    text: "A simple and sophisticated round table arrangement. Suitable for meetings.",
  },
];

const SeatingType = ({ onNext, onPrev, formik }) => {
  const navigate = useNavigate();
  const canProceed = formik.values.event_seating_style;

  return (
    <section className="overflow-y-auto lg:rounded-xl scrollbar-thumb-gray-600 lg:w-[60rem] m-auto lg:h-[90%] h-screen scrollbar-track-gray-100 scrollbar-thin  scrollbar-rounded-full grid items-center">
      <div className="bg-white lg:rounded-xl z-30 flex flex-col gap-5 lg:mr-3 p-5 px-10 lg:h-[max-content] h-screen ">
        <nav className="flex justify-between border-b border-gray-300 py-5">
          <h6 className="font-[600]">Select seating type</h6>

          <span
            className="h-[3rem] w-[3rem] bg-gray-300 flex justify-center items-center rounded-full text-xl cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <FiX />
          </span>
        </nav>

        <div className="mb-4 flex flex-col gap-10">
          {eventSeatingStyles.map((style) => (
            <div key={style.label} className="flex items-start">
              <input
                type="radio"
                id={`event_seating_style_${style.label}`}
                name="event_seating_style"
                value={style.label}
                checked={formik.values.event_seating_style === style.label}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <label
                htmlFor={`event_seating_style_${style.label}`}
                className="ml-2 flex flex-col  mt-[-5px]"
              >
                <span className="font-[700] text-lg">{style.label}</span>
                <span className="text-gray-700">{style.text}</span>
              </label>
            </div>
          ))}
          {formik.touched.event_seating_style &&
            formik.errors.event_seating_style && (
              <div className="text-red-600">
                {formik.errors.event_seating_style}
              </div>
            )}
        </div>

        <div className="flex items-center justify-end gap-4 mt-2 pb-8">
          <Button
            onClick={onPrev}
            variant="outlined"
            size="lg"
            colorScheme="gray"
          >
            Back
          </Button>

          {canProceed && (
            <Button
              onClick={onNext}
              variant="filled"
              size="lg"
              colorScheme="primary"
            >
              Continue
            </Button>
          )}
        </div>
      </div>
    </section>
  );
};

export default SeatingType;
