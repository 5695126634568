import { Progress } from "rsuite";

const StatsCard = (props) => {
  const { withChart, title, value } = props;

  const getClasses = () => {
    let names = `bg-gray-200 rounded-md p-4 border items-center `;
    if (withChart) return (names += `grid grid-cols-[max-content_1fr] gap-2`);
    return names;
  };
  const style = {
    width: 100,
    display: "inline-block",
    marginRight: 10,
  };

  return (
    <div className={getClasses()}>
      {withChart && (
        <div style={style}>
          <Progress.Circle percent={value} strokeColor="#F1DE02" />
        </div>
      )}
      <article>
        <h2 className="font-[600] text-5xl">
          {value + `${withChart ? "%" : ""}`}
        </h2>
        {title && <p className="text-md mt-2">{title}</p>}
      </article>
    </div>
  );
};

export default StatsCard;
