import router from "./router";
import { RouterProvider } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { useEffect } from "react";

const App = () => {
  useEffect(() => {
    const hasBeenSet = localStorage.getItem("evently-showAds");

    if (hasBeenSet === null) localStorage.setItem("evently-showAds", "yes");
  }, []);

  return (
    <>
      <Toaster position="bottom-right" reverseOrder={false} />
      <RouterProvider router={router} />
    </>
  );
};
export default App;
