
import Category from "../../common/category";
import moment from "moment";
import TruncateText from "../../common/truncateText";
import { FaImage } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const EventsCard = ({ data, small }) => {
  const navigate = useNavigate();

  const getInitials = (firstName, lastName) => {
    const firstInitial = firstName?.[0] || '';
    const lastInitial = lastName?.[0] || '';
    return `${firstInitial}${lastInitial}`;
  };

  if (small)
    return (
      <article
        onClick={() => navigate(`/event-detail/${data?.uuid}`)}
        className="bg-white rounded-lg p-4 pb-5 w-full grid lg:grid-cols-[10rem_1fr] grid-cols-1 lg:gap-0 gap-5 cursor-pointer"
      >
        <figure className="lg:h-[8rem] h-[15rem] w-full lg:w-[8rem] overflow-hidden bg-yellow-600 rounded-lg">
          <img src={data?.event_image} className="w-full h-full object-cover" alt="Event" />
        </figure>

        <div className="flex lg:flex-row flex-col justify-between px-2 md:gap-2 gap-5">
          <div className="flex flex-col justify-center gap-3">
            <div className="flex gap-1">
              {data?.tags?.map((item) => (
                <Category key={item.id} variant={item} />
              ))}
            </div>

            <div className="flex flex-col gap-2">
              <span className="h8 font-[600]">{data?.event_title}</span>
            </div>
            <TruncateText
              wordLength={10}
              className="lg:text-sm text-xs text-gray-700"
              text={data?.event_description}
            />
          </div>

          <div className="flex pl-2 items-center justify-start">
            {data?.attendees?.slice(0, 4)?.map((item, index) => (
              <figure
                key={index + 1}
                className={`z-${index + 1} 
              bg-yellow-600 ml-[-10px] flex items-center justify-center lg:w-[3rem] w-[3.5rem] lg:h-[3rem] h-[3.5rem] rounded-full overflow-hidden border border-yellow-100`}
              >
                <span className="font-[700] text-md">
                  {getInitials(item?.attendee_first_name, item?.attendee_last_name)}
                </span>
              </figure>
            ))}

            {Boolean(data?.attendees?.length >= 4) && (
              <span className="h8 font-[800] ml-3 text-xs font-sans text-green-600">
                {data?.attendees?.length - 4}+ Attending
              </span>
            )}
          </div>
        </div>
      </article>
    );

  return (
    <article
      onClick={() => navigate(`/event-detail/${data?.uuid}`)}
      className="bg-white rounded-3xl grid grid-rows-[1fr_max-content] transition cursor-pointer overflow-hidden"
    >
      <figure className="relative lg:h-[15rem] h-[15rem] w-full bg-gray-600 rounded-t-3xl overflow-hidden">
        <div className="h-full flex items-center justify-center">
          <FaImage className="text-4xl opacity-5" />
        </div>

        {data?.event_image && (
          <img
            src={data?.event_image}
            className="absolute top-0 left-0 w-full h-full object-cover"
            alt="Event"
          />
        )}
      </figure>

      <div className="px-8 py-8 flex flex-col gap-2">
        <div className="flex flex-wrap gap-1">
          {data?.tags?.map((item) => (
            <Category key={item.id} variant={item} />
          ))}
        </div>

        <div className="flex flex-col gap-1">
          <TruncateText
            className="font-[600]"
            wordLength={5}
            text={data?.event_title}
          />

          <div className="flex gap-1 items-center justify-start text-gray-700 text-sm font-[500]">
            <span>{moment(data?.event_date).format("MMM D")}</span>
            <span>・</span>
            <span>
              {moment(`${data?.event_date} ${data?.event_time}`).format("h:mm A")}
            </span>
          </div>
        </div>

        {Boolean(data?.attendees?.length) && (
          <div className="flex pl-2 items-center justify-start">
            {data?.attendees?.slice(0, 4)?.map((item, index) => (
              <figure
                key={index + 1}
                className={`z-${index + 1}
              bg-yellow-600 ml-[-10px] flex items-center justify-center lg:w-[3rem] w-[3.5rem] lg:h-[3rem] h-[3.5rem] rounded-full overflow-hidden border border-yellow-100`}
              >
                <span className="font-[700] text-md">
                  {getInitials(item?.attendee_first_name, item?.attendee_last_name)}
                </span>
              </figure>
            ))}

            {Boolean(data?.attendees?.length >= 4) && (
              <span className="h8 font-[800] ml-3 text-xs font-sans text-green-600">
                {data?.attendees?.length - 4}+ Attending
              </span>
            )}
          </div>
        )}

        {!Boolean(data?.attendees?.length) && (
          <span className="text-gray-700">No attendees</span>
        )}
      </div>
    </article>
  );
};

export default EventsCard;
