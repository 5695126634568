import { useNavigate, useParams } from "react-router-dom";
import Button from "../../common/button";
import { FiX } from "react-icons/fi";
import * as XLSX from "xlsx";
import QRCode from "react-qr-code";
import { useEffect, useState } from "react";
import { TbUserEdit } from "react-icons/tb";
import createEventStore from "../../store/createEvent";
import { useMutation, useQuery } from "react-query";
import { getData, postData } from "../../../utils/api";

import { BiSolidBell, BiSolidFileExport } from "react-icons/bi";

const convertToExcel = (data, filename) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, filename + ".xlsx");
};

const ViewPeople = () => {
  const navigate = useNavigate();

  const externalAttendeeEventId = createEventStore(
    (state) => state.externalAttendeeEventId,
  );
  const [filterBy, setFilterBy] = useState("");
  const [attendeesList, setAttendeesList] = useState([]);
  const [acceptedAttendeesList, setAcceptedAttendeesList] = useState([]);

  const token = localStorage.getItem("evently");

  const getAllAttendees = useMutation({
    mutationFn: (id) => {
      return postData(
        `/admin/all_attendees`,
        {
          event_id: id,
        },
        token,
      );
    },
    onSuccess: (data) => {
      setAttendeesList(data?.data);
    },
  });

  const getAcceptedAttendees = useMutation({
    mutationFn: (id) => {
      return postData(
        `/admin/accepted_invite_attendees`,
        {
          event_id: id,
        },
        token,
      );
    },
    onSuccess: (data) => {
      setAcceptedAttendeesList(data.data);
    },
  });

  const sendReminderToUnAccepted = useMutation({
    mutationFn: (id) => {
      return postData(
        `/admin/send_reminder_to_unaccepted`,
        {
          event_id: id,
        },
        token,
      );
    },
  });

  const sendReminderToAccepted = useMutation({
    mutationFn: (id) => {
      return postData(
        `/admin/send_reminder_to_accepted`,
        {
          event_id: id,
        },
        token,
      );
    },
  });

  useEffect(() => {
    if (filterBy === "") getAllAttendees.mutate(externalAttendeeEventId);
    else getAcceptedAttendees.mutate(externalAttendeeEventId);
  }, [filterBy]);

  const [isTable, setIsTable] = useState(null);
  const { eventId } = useParams();

  useQuery({
    queryKey: ["getEventDetailQuick"],
    queryFn: async () => await getData(`/admin/event_detail/${eventId}`, token),
    onSuccess: (data) =>
      setIsTable(
        data.data.data.event_seating_style === "Banquet Style" ? true : false,
      ),
  });

  return (
    <section className="overflow-y-auto lg:rounded-xl scrollbar-thumb-gray-600 lg:w-[50rem] w-full m-auto lg:h-[90%] h-screen scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-full grid items-center">
      <div className="bg-white lg:rounded-xl z-30 flex flex-col gap-5 lg:mr-3 p-5 px-10 lg:h-[max-content] h-screen">
        <nav className="flex justify-between border-b border-gray-300 py-5">
          <h6 className="font-[600]">View People</h6>

          <span
            className="h-[3rem] w-[3rem] bg-gray-300 flex justify-center items-center rounded-full text-xl cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <FiX />
          </span>
        </nav>
        <div className="flex justify-between">
          <div className="flex items-center justify-start gap-2 pb-8">
            <span className="font-[500]">Filter By:</span>
            <Button
              onClick={() => setFilterBy("")}
              variant={filterBy === "" ? "filled" : "outlined"}
              size="sm"
              colorScheme="primary"
            >
              Not Accepted
            </Button>
            <Button
              onClick={() => setFilterBy("accepted")}
              variant={filterBy === "accepted" ? "filled" : "outlined"}
              size="sm"
              colorScheme="primary"
            >
              Accepted
            </Button>
          </div>

          {!getAllAttendees.isLoading && !getAcceptedAttendees.isLoading && (
            <div className="flex gap-5 items-start">
              <Button
                onClick={() => {
                  if (isTable === true)
                    sendReminderToUnAccepted.mutate(externalAttendeeEventId);

                  if (isTable === false)
                    sendReminderToAccepted.mutate(externalAttendeeEventId);
                }}
                size="sm"
                isLoading={
                  sendReminderToAccepted.isLoading ||
                  sendReminderToUnAccepted.isLoading
                }
                icon={<BiSolidBell />}
                variant={"filled"}
                colorScheme="primary"
              >
                {filterBy === "accepted" ? "Remind All" : "Notify All"}
              </Button>

              <Button
                onClick={() => {
                  if (filterBy === "") {
                    const data = attendeesList.map((item) => {
                      if (isTable === true)
                        return {
                          ["First Name"]: item.attendee_first_name,
                          ["Last Name"]: item.attendee_last_name,
                          ["Table Number"]: item.attendee_table_number,
                          ["Table Seat Number"]:
                            item.attendee_table_seat_number,
                        };

                      if (isTable === false)
                        return {
                          ["First Name"]: item.attendee_first_name,
                          ["Last Name"]: item.attendee_last_name,
                          ["Seat Number"]: item.attendee_seat_number,
                        };
                    });

                    convertToExcel(data, `evently-not-verified-attendees`);
                    return;
                  } else {
                    const data = acceptedAttendeesList.map((item) => {
                      if (isTable === true)
                        return {
                          ["First Name"]: item.attendee_first_name,
                          ["Last Name"]: item.attendee_last_name,
                          ["Table Number"]: item.attendee_table_number,
                          ["Table Seat Number"]:
                            item.attendee_table_seat_number,
                        };

                      if (isTable === false)
                        return {
                          ["First Name"]: item.attendee_first_name,
                          ["Last Name"]: item.attendee_last_name,
                          ["Seat Number"]: item.attendee_seat_number,
                        };
                    });

                    convertToExcel(data, `evently-verified-attendees`);
                  }
                }}
                variant={"filled"}
                size="sm"
                icon={<BiSolidFileExport />}
                colorScheme="primary"
              >
                Export
              </Button>
            </div>
          )}
        </div>

        <div
          className={`flex flex-col gap-3 h-max-content scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-full pb-5 bg-white rounded-lg  grid-rows-[max-content]`}
        >
          {filterBy === "" && getAllAttendees.isLoading && (
            <div className="flex flex-col items-center justify-center gap-3 h-[15rem] w-full bg-gray-300">
              <span className="text-center">Loading...</span>
            </div>
          )}

          {filterBy === "accepted" && getAcceptedAttendees.isLoading && (
            <div className="flex flex-col items-center justify-center gap-3 h-[15rem] w-full bg-gray-300">
              <span className="text-center">Loading...</span>
            </div>
          )}

          {filterBy === "" &&
            !getAllAttendees.isLoading &&
            !Boolean(attendeesList?.length) && (
              <div className="flex flex-col items-center justify-center gap-3 h-[10rem] w-full">
                <h6 className="font-[600] text-sm">No Attendees Yet.</h6>
              </div>
            )}

          {filterBy === "accepted" &&
            !getAcceptedAttendees?.isLoading &&
            !Boolean(acceptedAttendeesList.length) && (
              <div className="flex flex-col items-center justify-center gap-3 h-[10rem] w-full">
                <h6 className="font-[600] text-sm">
                  No Accepted Attendees Yet.
                </h6>
              </div>
            )}

          {filterBy === "" &&
            !getAllAttendees.isLoading &&
            Boolean(attendeesList.length) && (
              <table className="table">
                <thead>
                  <tr className="text-gray-700 text-sm border-b border-gray-300 font-[600]  text-left">
                    <th className="pb-8 py-2 font-[500]">Name</th>
                    {isTable === false && (
                      <th className="pb-8 py-2 font-[500]">Seat No.</th>
                    )}
                    {isTable === true && (
                      <th className="pb-8 py-2 font-[500]">Table No. </th>
                    )}
                    {isTable === true && (
                      <th className="pb-8 py-2 font-[500]">Table Seat No.</th>
                    )}
                  </tr>
                </thead>

                <tbody className="font-[600] relative divide-gray-200">
                  {attendeesList.map((attendee) => (
                    <tr key={attendee.id}>
                      <td className="py-4 whitespace-nowrap">
                        {attendee.attendee_first_name}{" "}
                        {attendee.attendee_last_name}
                      </td>

                      {isTable === false && (
                        <td>
                          {attendee.attendee_seat_number ? (
                            attendee.attendee_seat_number
                          ) : (
                            <TbUserEdit cursor={"pointer"} size={"1.5em"} />
                          )}{" "}
                        </td>
                      )}
                      {isTable === true && (
                        <td>{attendee.attendee_table_number} </td>
                      )}
                      {isTable === true && (
                        <td>{attendee.attendee_table_seat_number}</td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

          {filterBy === "accepted" &&
            !getAcceptedAttendees.isLoading &&
            Boolean(acceptedAttendeesList.length) && (
              <table className="table">
                <thead>
                  <tr className="text-gray-700 text-sm border-b border-gray-300 font-[600]  text-left">
                    <th className="pb-8 py-2 font-[500]">Name</th>
                    {isTable === false && (
                      <th className="pb-8 py-2 font-[500]">Seat No.</th>
                    )}
                    {isTable === true && (
                      <th className="pb-8 py-2 font-[500]">Table No. </th>
                    )}
                    {isTable === true && (
                      <th className="pb-8 py-2 font-[500]">Table Seat No.</th>
                    )}

                    <th className="pb-8 py-2 font-[500]">QR Code.</th>
                  </tr>
                </thead>

                <tbody className="font-[600] relative divide-gray-200">
                {acceptedAttendeesList?.map((attendee) => (
                  <tr key={attendee?.id} className="">
                      <td className="py-4 whitespace-nowrap">
                      {attendee?.attendee_first_name}{" "}
                      {attendee?.attendee_last_name}
                      </td>

                      {isTable === false && (
                      <td>{attendee?.attendee_seat_number}</td>
                      )}
                      {isTable === true && (
                      <td>{attendee?.attendee_table_number}</td>
                      )}
                      {isTable === true && (
                      <td>{attendee?.attendee_table_seat_number}</td>
                      )}
                      <td className="">
                        <QRCode
                          level="H"
                        value={attendee?.uuid}
                        style={{ height: "4rem", width: "auto", margin: 5 }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
        </div>
      </div>
    </section>
  );
};

export default ViewPeople;
