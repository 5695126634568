import { create } from "zustand";
import { deleteData, postData } from "../../utils/api";

const requestsStore = create((set) => ({
  requests: [],
  requestsCount: 0,
  paginationLoading: false,
  pagination: {
    perPage: 0,
    nextPage: "",
    prevPage: "",
    currentPage: 0,
    lastPage: 0,
  },
  requestsLoading: false,
  requestUpdateLoading: false,

  setRequests: (requests) => set({ requests: requests }),
  setRequestsLoading: (value) => set({ requestsLoading: value }),
  setRequestsCount: (value) => set({ requestsCount: value }),
  setPagination: (value) => set({ pagination: value }),
  setPaginationLoading: (value) => set({ paginationLoading: value }),

  setRequestUpdateLoading: (value) => set({ requestUpdateLoading: value }),
  updateRequest: async (id, status) => {
    set((state) => {
      const foundId = state.requests.findIndex((item) => item.uuid === id);
      const requests = [...state.requests];
      requests[foundId] = { ...requests[foundId], event_status: status };
      console.log(requests[foundId]);
      return {
        requests: requests,
      };
    });
  },

  deleteRequest: async (id) => {
    set((state) => {
      const foundId = state.requests.findIndex((item) => item.uuid === id);

      const requests = [...state.requests];
      requests.splice(foundId, 1);
      return {
        requests: requests,
      };
    });
  },
}));

export default requestsStore;
