import { FaCaretDown } from "react-icons/fa";
import { useOutsideClick } from "@chakra-ui/react";
import { useRef, useState } from "react";
import { IoLogOut } from "react-icons/io5";
import { Link } from "react-router-dom";
import TruncateText from "../common/truncateText";
import userStore from "../store/user";

const NavBarDropDown = () => {
  const ref = useRef();
  const signedInUserLoading = userStore((state) => state.signedInUserLoading);
  const user = userStore((state) => state.signedInUser);

  const [isDropDownShowing, setIsDropDownShowing] = useState(false);
  useOutsideClick({
    ref: ref,
    handler: () => setIsDropDownShowing(false),
  });

  return (
    <div className="relative">
      <div
        className="flex items-center justify-center gap-2 cursor-pointer"
        onClick={() => setIsDropDownShowing(true)}
      >
        <div className="flex items-center justify-center gap-4 w-[max-content]">
          <div className="flex items-center justify-center gap-2">
            <figure className="h-[3rem] w-[3rem] bg-yellow-600 flex text-lg items-center justify-center rounded-full font-[700]">
              {user.initials}
            </figure>

            {!signedInUserLoading && (
              <div className="lg:flex hidden gap-2">
                <TruncateText
                  className="font-[600] text-sm"
                  wordLength={2}
                  text={`${user.firstName} ${user.lastName}`}
                />

                <span className="text-xl">
                  <FaCaretDown />
                </span>
              </div>
            )}

            {signedInUserLoading && (
              <div
                className={`lg:flex hidden animate-pulse bg-gray-200 rounded w-[8rem] h-5`}
              ></div>
            )}
          </div>
        </div>
      </div>

      {isDropDownShowing && (
        <div className="absolute top-0 right-0 py-[70px] z-10 w-[max-content]">
          <div ref={ref} className="grid bg-white rounded-md shadow-lg">
            <div className="flex items-center justify-center gap-2 py-3 px-5 border-b border-gray-200">
              <figure className="h-[3rem] w-[3rem] bg-yellow-600 flex text-md items-center justify-center rounded-full font-[700]">
                {user.initials}
              </figure>
              <p className="flex gap-1">
                <span>
                  {user.firstName} {user.lastName}
                </span>
                <span className="font-[500] text-gray-600">
                  ({user.referenceIndicator})
                </span>
              </p>
            </div>

            <Link to="/sign-out">
              <button className="flex items-center justify-center w-full text-red-600 gap-1 font-[600] p-4 px-2">
                <span>
                  <IoLogOut />
                </span>
                <span>Signout</span>
              </button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default NavBarDropDown;
