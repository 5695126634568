import { useNavigate, useParams } from "react-router-dom";
import Button from "../../common/button";
import { FiX } from "react-icons/fi";
import * as Yup from "yup";
import createEventStore from "../../store/createEvent";
import { useMutation, useQuery } from "react-query";
import { getData, postData } from "../../../utils/api";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import toast from "react-hot-toast";

const InviteExternalPeople = () => {
  const navigate = useNavigate();
  const [isTable, setIsTable] = useState(null);
  const [id, setId] = useState(null);
  const [reservedSeatsOrTables, setReservedSeatsOrTables] = useState([]);
  const [availableSeatsOrTables, setAvailableSeatsOrTables] = useState([]);
  const [availableSeatsForTables, setAvailableSeatsForTables] = useState([]);
  const { eventId } = useParams();

  const externalAttendeeEventId = createEventStore(
    (state) => state.externalAttendeeEventId
  );

  const token = localStorage.getItem("evently");

  useQuery({
    queryKey: ["getEventDetailQuick"],
    queryFn: async () => await getData(`/admin/event_detail/${eventId}`, token),
    onSuccess: (data) => {
      setId(data.data.data.id);
      setIsTable(
        data.data.data.event_seating_style.toLowerCase().includes("banquet")
      );
    },
  });

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      event_seat_number: "",
      event_table_number: "",
      event_seat_of_table: "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Required"),
      last_name: Yup.string().required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      event_seat_number: Yup.string().notRequired().min(1),
      event_table_number: Yup.string().notRequired().min(1),
      event_seat_of_table: Yup.string().notRequired().min(1),
    }),
    onSubmit: (values) => {
      if (isTable) {
        return addExternalAttendeeMutation.mutate({
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          event_table_number: values.event_table_number,
          event_seat_of_table: values.event_seat_of_table,
        });
      }
      addExternalAttendeeMutation.mutate({
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        event_seat_number: values.event_seat_number,
      });
    },
  });

  const addExternalAttendeeMutation = useMutation({
    mutationFn: (data) => {
      return postData(
        "/admin/invite_attendees",
        {
          ...data,
          event_requests_id: externalAttendeeEventId,
        },
        token
      );
    },
    onSuccess: () => {
      formik.resetForm();
      toast.success("Attendee added!");
    },
    onError: () => {
      formik.resetForm();
    },
  });

  const checkReservedSeatOrTableMutation = useMutation({
    mutationFn: (event_requests_id) => {
      return postData(
        `/admin/check_reserved_seats_or_table`,
        { event_requests_id },
        token
      );
    },
    onSuccess: async (data) => {
      setReservedSeatsOrTables(data.data);
    },
  });

  const checkAvailableSeatsOrTablesMutation = useMutation({
    mutationFn: (event_requests_id) => {
      return postData(
        `/admin/check_available_seats_or_table`,
        { event_requests_id },
        token
      );
    },
    onSuccess: async (data) => {
      setAvailableSeatsOrTables(data.data);
    },
  });

  const checkAvailableSeatsForTablesMutation = useMutation({
    mutationFn: (data) => {
      return postData(`/admin/check_available_seats_for_table`, data, token);
    },
    onSuccess: async (data) => {
      setAvailableSeatsForTables(data.data);
    },
  });

  useEffect(() => {
    if (id) {
      checkReservedSeatOrTableMutation.mutate(id);
      checkAvailableSeatsOrTablesMutation.mutate(id);
    }
  }, [id]);

  const getFieldStyle = (error) => {
    return `form-input bg-gray-200 px-5 py-4 outline-none border focus:border-green-600 rounded-md w-full text-md 
    scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-full
    ${
      formik.touched[error] && formik.errors[error]
        ? "border-red-600 focus:border-red-600"
        : "border-transparent"
    }`;
  };

  return (
    <section className="overflow-y-auto lg:rounded-xl scrollbar-thumb-gray-600 lg:w-[50rem] w-full m-auto lg:h-[90%] h-screen scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-full grid items-center">
      <div className="bg-white lg:rounded-xl z-30 flex flex-col gap-5 lg:mr-3 p-5 px-8 lg:h-[max-content] h-[100%]">
        <nav className="flex justify-between border-b border-gray-300 py-5">
          <h6 className="font-[600]">Invite External People</h6>
          <span
            className="h-[3rem] w-[3rem] bg-gray-300 flex justify-center items-center rounded-full text-xl cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <FiX />
          </span>
        </nav>
        <div className="">
          <form onSubmit={formik.handleSubmit}>
            <div className="grid gap-4 grid-cols-1">
              <div className="mb-4">
                <label htmlFor="first_name" className="block mb-1 font-medium">
                  First Name
                </label>
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  placeholder="eg. John"
                  className={getFieldStyle("first_name")}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.first_name}
                />
                {formik.touched.first_name && formik.errors.first_name ? (
                  <div className="text-red-500">{formik.errors.first_name}</div>
                ) : null}
              </div>
              <div className="mb-4">
                <label htmlFor="last_name" className="block mb-1 font-medium">
                  Last Name
                </label>
                <input
                  type="text"
                  id="last_name"
                  name="last_name"
                  placeholder="eg. Doe"
                  className={getFieldStyle("last_name")}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.last_name}
                />
                {formik.touched.last_name && formik.errors.last_name ? (
                  <div className="text-red-500">{formik.errors.last_name}</div>
                ) : null}
              </div>
            </div>
            <div className="grid gap-4 grid-cols-1">
              <div className="mb-4">
                <label htmlFor="email" className="block mb-1 font-medium">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="eg. john.doe@somewhere.com"
                  className={getFieldStyle("email")}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="text-red-500">{formik.errors.email}</div>
                ) : null}
              </div>
            </div>
            <div className="mb-4 flex flex-col gap-2">
              <label
                htmlFor="event_table_number"
                className="mb-1 font-medium flex gap-1 items-center"
              >
                {isTable ? " Reserved Table(s)" : " Reserved Seat(s)"}
              </label>
              {Boolean(checkReservedSeatOrTableMutation.isLoading) && (
                <div
                  className={`animate-pulse bg-gray-600 rounded-xl w-full col-span-2 min-h-[3.4rem]`}
                ></div>
              )}
              {!Boolean(checkReservedSeatOrTableMutation.isLoading) &&
                !Boolean(reservedSeatsOrTables.length) && (
                  <div
                    className={`grid place-items-center text-center bg-gray-200 rounded-xl w-full col-span-2 min-h-[3.4rem]`}
                  >
                    <p className="text-sm text-gray-700">
                      {isTable ? "No reserved table(s)" : "No reserved seat(s)"}
                    </p>
                  </div>
                )}
              <div className="flex flex-wrap gap-5">
                {!Boolean(checkReservedSeatOrTableMutation.isLoading) &&
                  Boolean(reservedSeatsOrTables.length) &&
                  reservedSeatsOrTables.map((item) => (
                    <span
                      className="py-2 px-4 bg-yellow-600 font-bold rounded-md"
                      key={item}
                    >
                      {item}
                    </span>
                  ))}
              </div>
            </div>
            {availableSeatsOrTables?.length > 0 && (
              <div className="mb-4 flex flex-col gap-2">
                <label
                  htmlFor="event_table_number"
                  className="mb-1 font-medium flex gap-1 items-center"
                >
                  {isTable ? "Available Tables" : "Available Seats"}
                </label>
                {Boolean(checkAvailableSeatsOrTablesMutation.isLoading) && (
                  <div
                    className={`animate-pulse bg-gray-600 rounded-xl w-full col-span-2 min-h-[3.4rem]`}
                  ></div>
                )}
                {!Boolean(checkAvailableSeatsOrTablesMutation.isLoading) && (
                  <select
                    id="event_seat_number"
                    name="event_seat_number"
                    onBlur={formik.handleBlur}
                    className={getFieldStyle("event_seat_number")}
                    onChange={(event) => {
                      formik.handleChange(event);  // Call the original formik.handleChange
                      const eventSeatNumber = event.target.value;
                      if (!isTable) {
                        formik.setFieldValue(
                          "event_seat_number",
                          eventSeatNumber
                        );
                      }
                      if (isTable) {
                        formik.setFieldValue(
                          "event_table_number",
                          eventSeatNumber
                        );
                        checkAvailableSeatsForTablesMutation.mutate({
                          table_number: eventSeatNumber,
                          event_requests_id: id,
                        });
                      }
                    }}
                    value={
                      isTable
                        ? formik.values.event_table_number
                        : formik.values.event_seat_number
                    }
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    {availableSeatsOrTables.map((seat) => (
                      <option key={seat} value={seat}>
                        {seat}
                      </option>
                    ))}
                  </select>
                )}
                {formik.touched.event_seat_number &&
                  formik.errors.event_seat_number && (
                    <div className="text-red-600">
                      {formik.errors.event_seat_number}
                    </div>
                  )}
              </div>
            )}
            {isTable && (
              <div className="mb-4 flex flex-col gap-2">
                <label
                  htmlFor="event_seat_of_table"
                  className="mb-1 font-medium flex gap-1 items-center"
                >
                  Available Seats for Table
                </label>
                {Boolean(checkAvailableSeatsForTablesMutation.isLoading) && (
                  <div
                    className={`animate-pulse bg-gray-600 rounded-xl w-full col-span-2 min-h-[3.4rem]`}
                  ></div>
                )}
                {!Boolean(checkAvailableSeatsForTablesMutation.isLoading) && (
                  <select
                    id="event_seat_of_table"
                    name="event_seat_of_table"
                    onBlur={formik.handleBlur}
                    className={getFieldStyle("event_seat_of_table")}
                    onChange={formik.handleChange}
                    value={formik.values.event_seat_of_table}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    {availableSeatsForTables.map((seat) => (
                      <option key={seat} value={seat}>
                        {seat}
                      </option>
                    ))}
                  </select>
                )}
                {formik.touched.event_seat_of_table &&
                  formik.errors.event_seat_of_table && (
                    <div className="text-red-600">
                      {formik.errors.event_seat_of_table}
                    </div>
                  )}
              </div>
            )}
            <div className="flex items-center justify-end gap-4 mt-2 lg:pb-8 pb-[10rem]">
              <Button
                onClick={formik.handleSubmit}
                isLoading={addExternalAttendeeMutation.isLoading}
                variant="outlined"
                size="lg"
                type="button"
                colorScheme="primary"
              >
                Add External Attendee
              </Button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default InviteExternalPeople;