import { create } from "zustand";

const userStore = create((set) => ({
  signedInUserLoading: false,
  signedInUser: {
    initials: "AN",
    firstName: "Anonymous",
    lastName: "User",
    referenceIndicator: "DEV",
  },
  setSignedInUser: (user) => set({ signedInUser: user }),
  setSignedInUserLoading: (value) => set({ signedInUserLoading: value }),
}));

export default userStore;
