import Button from "../../common/button";
import { FiX } from "react-icons/fi";
import * as Yup from "yup";
import { useQueryClient } from "react-query";

import { FaCheckCircle, FaInfoCircle } from "react-icons/fa";

import { useMutation } from "react-query";
import { postData } from "../../../utils/api";
import { useFormik } from "formik";
import { useEffect } from "react";

const EditSpeaker = ({ onClose, speakerDetails }) => {
  const token = localStorage.getItem("evently");
  const queryClient = useQueryClient();

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      bio_info: "",
      profile_image: null,
    },

    validationSchema: Yup.object({
      first_name: Yup.string().required("Required"),
      last_name: Yup.string().required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      bio_info: Yup.string().required("Required").min(1).max(1000),
      profile_image: Yup.mixed()
        .test("fileType", "Unsupported file type", (value) => {
          const allowedExtensions = ["jpg", "jpeg", "png"];

          if (!value) return true;
          else
            return allowedExtensions.includes(
              value.name.split(".").pop().toLowerCase(),
            );
        })
        .test("fileSize", "Image size exceeds 5MB limit", (value) => {
          if (!value) return true;
          else return value.size <= 5 * 1024 * 1024; // 5MB in bytes
        })
        .optional()
        .notRequired(),
      speaker_uuid: Yup.string().required(),
    }),
    onSubmit: (values) => {
      addExternalSpeakerMutation.mutate(values);
    },
  });

  useEffect(() => {
    const data = { ...speakerDetails };
    delete data.uuid;
    data.profile_image = "";
    data.speaker_uuid = speakerDetails.uuid;
    formik.setValues(data);
  }, []);

  const addExternalSpeakerMutation = useMutation({
    mutationFn: (data) => {
      return postData("/admin/edit_speaker", data, token);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["eventDetail"] });
    },
    // onError: () => {
    //   formik.resetForm();
    // },
  });

  const getFieldStyle = (error, extraStyles) => {
    return `form-input bg-gray-200 px-5 py-4 outline-none border focus:border-green-600 rounded-md w-full text-md 
    scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-full ${extraStyles}
    ${
      formik.touched[error] && formik.errors[error]
        ? "border-red-600 focus:border-red-600"
        : "border-transparent"
    }`;
  };

  return (
    <section className="overflow-y-auto lg:rounded-xl scrollbar-thumb-gray-600 lg:w-[50rem] w-full m-auto lg:h-[90%] h-screen scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-full grid items-center">
      <div className="bg-white lg:rounded-xl z-30 flex flex-col gap-5 lg:mr-3 p-5 px-8 lg:h-[max-content] h-[100%]">
        <nav className="flex justify-between border-b border-gray-300 py-5">
          <h6 className="font-[600]">Edit Speaker</h6>

          <span
            className="h-[3rem] w-[3rem] bg-gray-300 flex justify-center items-center rounded-full text-xl cursor-pointer"
            onClick={onClose}
          >
            <FiX />
          </span>
        </nav>

        <div className="">
          <form onSubmit={formik.handleSubmit}>
            <div className="grid gap-4 grid-cols-1">
              <div className="mb-4">
                <label htmlFor="first_name" className="block mb-1 font-medium">
                  First Name
                </label>
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  placeholder="eg. John"
                  className={getFieldStyle("first_name")}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.first_name}
                />
                {formik.touched.first_name && formik.errors.first_name ? (
                  <div className="text-red-500">{formik.errors.first_name}</div>
                ) : null}
              </div>

              <div className="mb-4">
                <label htmlFor="last_name" className="block mb-1 font-medium">
                  Last Name
                </label>
                <input
                  type="text"
                  id="last_name"
                  name="last_name"
                  placeholder="eg. Doe"
                  className={getFieldStyle("last_name")}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.last_name}
                />
                {formik.touched.last_name && formik.errors.last_name ? (
                  <div className="text-red-500">{formik.errors.last_name}</div>
                ) : null}
              </div>
            </div>

            <div className="grid gap-4 grid-cols-1">
              <div className="mb-4">
                <label htmlFor="email" className="block mb-1 font-medium">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="eg. john.doe@somewhere.com"
                  className={getFieldStyle("email")}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="text-red-500">{formik.errors.email}</div>
                ) : null}
              </div>
            </div>

            <div className="mb-4">
              <label htmlFor="bio_info" className="mb-1 font-medium flex gap-1">
                <span>Bio</span>
                <span className="text-gray-700 font-[400]">
                  1,000 characters
                </span>
              </label>
              <textarea
                id="bio_info"
                placeholder="eg. Bio here"
                name="bio_info"
                className={getFieldStyle("bio_info", "h-[10rem]")}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.bio_info}
              />
              {formik.touched.bio_info && formik.errors.bio_info && (
                <div className="text-red-600">{formik.errors.bio_info}</div>
              )}
            </div>

            <div className="flex flex-col w-[45%] gap-5 relative">
              <label
                htmlFor="profile_image"
                className="mb-1 font-medium flex gap-1"
              >
                <span>Profile Image</span>
                <span className="text-gray-700 font-[400]">Optional</span>
              </label>
              <input
                type="file"
                id="profile_image"
                name="profile_image"
                onChange={(event) =>
                  formik.setFieldValue(
                    "profile_image",
                    event.currentTarget.files[0],
                  )
                }
                onBlur={formik.handleBlur}
                className="form-input absolute h-full w-full opacity-0 cursor-pointer"
              />

              {!formik.values.profile_image && (
                <div
                  className={`border-dashed border-2 ${
                    formik.errors.profile_image
                      ? "border-red-500"
                      : "border-gray-500"
                  } bg-gray-300 h-[20rem] p-5 rounded-md text-center flex flex-col gap-2 items-center justify-center cursor-pointer overflow-hidden`}
                >
                  <span className="w-[5rem] h-[5rem] overflow-hidden rounded-full">
                    <img
                      className="w-full h-full"
                      src={speakerDetails.profile_image}
                    />
                  </span>
                  <h6 className="h8 font-[700]">Change Image</h6>
                  <p className="text-sm text-gray-700 w-[25ch]">
                    Click here to select a photo from your computer.
                  </p>
                </div>
              )}

              {formik.values.profile_image && (
                <div
                  className={`bg-gray-100 h-[20rem] rounded-md cursor-pointer overflow-hidden ${
                    formik.errors.profile_image
                      ? "border-dashed border-3 border-red-500"
                      : "border-dashed border-3 border-green-500"
                  }`}
                >
                  {Boolean(formik.values.profile_image.type) &&
                    formik.values.profile_image && (
                      <img
                        src={URL.createObjectURL(formik.values.profile_image)}
                        width={100}
                        height={100}
                        alt=""
                        className="w-full h-full object-cover"
                      />
                    )}

                  {!Boolean(formik.values.profile_image.type) &&
                    formik.values.profile_image.includes(
                      "https://res.cloudinary.com/",
                    ) && (
                      <img
                        width={100}
                        height={100}
                        src={formik.values.profile_image}
                        alt="Uploaded"
                        className="w-full h-full object-cover"
                      />
                    )}
                </div>
              )}

              {!formik.values.profile_image && !formik.errors.profile_image && (
                <div
                  className={`border-dotted border-3 border-gray-300 bg-gray-100 p-5 rounded-md text-center flex gap-2 items-center justify-center`}
                >
                  {!formik.errors.profile_image && (
                    <p className="text-sm text-blue-500">
                      Image size should not be above{" "}
                      <span className="font-[800]">5 MB</span>
                    </p>
                  )}
                </div>
              )}

              {!formik.errors.profile_image && formik.values.profile_image && (
                <div className="border border-green-600 bg-green-100 p-5 rounded-md text-center flex gap-2 items-center justify-center">
                  <p className="text-sm text-green-500">
                    Image uploaded successfully
                  </p>
                </div>
              )}

              {formik.errors.profile_image && (
                <div className="border border-red-600 bg-red-50 p-5 rounded-md text-center flex gap-2 items-center justify-center">
                  <p className="text-sm text-red-500">
                    {String(formik.errors.profile_image)}
                  </p>
                </div>
              )}
            </div>

            <div className="flex items-center justify-end gap-4 mt-2 lg:pb-8 pb-[10rem]">
              <Button
                onClick={formik.handleSubmit}
                isLoading={addExternalSpeakerMutation.isLoading}
                variant="outlined"
                size="lg"
                type="button"
                colorScheme="primary"
              >
                Update Speaker
              </Button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default EditSpeaker;
