import logo from "../../assets/images/evently.svg";
import { Link } from "react-router-dom";

const Logo = ({ isLink, size }) => {
  return (
    <Link to={isLink && "/"}>
      <div className="lg:w-[15rem] w-[12rem]">
        <img className="w-[max-content]" src={logo} />
      </div>
    </Link>
  );
};

export default Logo;
