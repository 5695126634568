import icon from "../../../assets/images/evently-icon.png";
import { BiLoaderAlt } from "react-icons/bi";

const Loading = ({ text }) => {
  return (
    <div className="absolute top-0 left-0 w-full h-full z-40 grid place-items-start bg-black bg-opacity-10">
      <div className="top-0 left-0 flex flex-col gap-5 items-center justify-center bg-white lg:rounded-xl w-full h-full">
        <div className="relative  flex items-center justify-center">
          <BiLoaderAlt className="animate-spin h-[8rem] w-[8rem] text-green-600" />
          <img className="absolute z-20 w-[50%]" src={icon} />
        </div>

        <span className="h8">
          Just a sec, {text || "We're processing things"}
        </span>
      </div>
    </div>
  );
};

export default Loading;
