import { useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "../../common/button";
import { FiX } from "react-icons/fi";
import { RiFileExcel2Line } from "react-icons/ri";
import * as XLSX from "xlsx";
import { FaCheckCircle, FaInfoCircle } from "react-icons/fa";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { getData, postData } from "../../../utils/api";
import { useMutation, useQuery } from "react-query";
import toast from "react-hot-toast";

const BulkInvite = () => {
  const navigate = useNavigate();
  const [excelData, setExcelData] = useState();
  const [isTable, setIsTable] = useState(null);
  const [id, setId] = useState(null);
  const [canProceed, setCanProceed] = useState(null);
  const [reservedAvailable, setReservedAvailable] = useState(null);

  const { eventId } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const evId = searchParams.get("id");

  const token = localStorage.getItem("evently");

  const getFieldStyle = (error) => {
    return `form-input bg-gray-200 px-5 py-4 outline-none border focus:border-green-600 rounded-md w-full text-md 
    scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-full
    ${
      formik.touched[error] && formik.errors[error]
        ? "border-red-600 focus:border-red-600"
        : "border-transparent"
    }`;
  };

  useQuery({
    queryKey: ["getEventDetailQuick"],
    queryFn: async () => await getData(`/admin/event_detail/${eventId}`, token),
    onSuccess: (data) => {
      const isTrue =
        Boolean(data.data.data.event_reserved_seats?.length) ||
        Boolean(data.data.data.event_reserved_tables?.length);
      setReservedAvailable(isTrue);
      setId(data.data.data.id);
      setIsTable(
        data.data.data.event_seating_style.toLowerCase().includes("banquet")
          ? true
          : false,
      );
    },
  });

  const uploadMutation = useMutation({
    mutationFn: (data) => {
      return postData("/admin/bulk_invites", data, token);
    },
    onSuccess: () => {
      formik.resetForm();
      toast.success("Bulk invite successful!");
      navigate(-1);
    },
    onError: () => {},
  });

  const readExcelFile = (value) => {
    let reader = new FileReader();
    reader.readAsArrayBuffer(value);
    reader.onload = (e) => {
      const workBook = XLSX.read(e.target.result);
      const sheetName = workBook.SheetNames[0];
      const worksheet = workBook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(worksheet);
      setExcelData(data);
    };
  };

  const handleSubmit = (values) => {
    if (isTable) {
      return uploadMutation.mutate({
        excel_file: values.excel_file,
        reserved_seats_or_table: values.reserved_seats_or_table,
        seats_per_table: values.seats_per_table,
        number_of_tables: values.number_of_tables,
        event_requests_id: id,
      });
    }

    uploadMutation.mutate({
      excel_file: values.excel_file,
      reserved_seats_or_table: values.reserved_seats_or_table,
      max_seats: values.max_seats,
      event_requests_id: id,
    });
  };

  const maxFileSize = 5 * 1024 * 1024;
  const validFileExtensions = [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  const validationSchema = Yup.object().shape({
    excel_file: Yup.mixed()
      .required()
      .test(
        "file_size",
        "File size is too large",
        (value) => !value || (value && value.size <= maxFileSize),
      )
      .test(
        "file_format",
        "uploaded file has unsupported format.",
        (value) =>
          !value || (value && validFileExtensions.includes(value.type)),
      ),
    number_of_tables: Yup.string().notRequired(),
    seats_per_table: Yup.string().notRequired(),
    reserved_seats_or_table: Yup.string().notRequired(),
    max_seats: Yup.string().notRequired(),
  });

  const formik = useFormik({
    initialValues: {
      excel_file: null,
      number_of_tables: "",
      seats_per_table: "",
      reserved_seats_or_table: "",
      max_seats: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (isTable) {
      return setCanProceed(
        Boolean(
          formik.values.excel_file &&
            formik.values.number_of_tables &&
            formik.values.seats_per_table,
        ),
      );
    }

    setCanProceed(Boolean(formik.values.excel_file && formik.values.max_seats));
  }, [formik.values]);

  const { data } = useQuery({
    queryFn: async () => {
      return postData(
        `/admin/check_reserved_seats_or_table`,
        { event_requests_id: evId },
        token,
      );
    },
  });

  const dataLength = data?.data?.length;

  return (
    <>
      <section className="relative overflow-y-auto lg:rounded-xl scrollbar-thumb-gray-600 lg:w-[40rem] w-full m-auto lg:h-[90%] h-screen scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-full grid items-center">
        <div className="bg-white lg:rounded-xl z-30 flex flex-col gap-5 lg:mr-3 p-5 px-10 lg:h-[max-content] h-screen ">
          <nav className="flex justify-between border-b border-gray-300 py-5">
            <h6 className="font-[600]">Bulk Invite</h6>

            <span
              className="h-[3rem] w-[3rem] bg-gray-300 flex justify-center items-center rounded-full text-xl cursor-pointer"
              onClick={() => navigate(-1)}
            >
              <FiX />
            </span>
          </nav>

          <div className="flex flex-col gap-5 relative">
            <input
              type="file"
              id={"excel_file"}
              accept=".xls, .xlsx"
              name={"excel_file"}
              onChange={(event) => {
                formik.setFieldValue(
                  "excel_file",
                  event.currentTarget.files[0],
                );
                readExcelFile(event.currentTarget.files[0]);
              }}
              onBlur={formik.handleBlur}
              className="form-input absolute h-full w-full opacity-0 cursor-pointer"
            />

            {!formik.values["excel_file"] && (
              <div
                className={`border-dashed border-2 ${
                  formik.errors["excel_file"]
                    ? "border-red-500"
                    : "border-gray-500"
                } bg-gray-300 h-[20rem] p-5 rounded-md text-center flex flex-col gap-2 items-center justify-center cursor-pointer overflow-hidden`}
              >
                <RiFileExcel2Line className="text-3xl text-green-600" />
                <h6 className="h8 font-[700]">Upload Excel File</h6>
                <p className="text-sm text-gray-700 w-[25ch]">
                  Click here to select a file from your computer.
                </p>
              </div>
            )}

            {formik.values["excel_file"] && (
              <div className="bg-gray-300 h-[20rem] overflow-y-scroll rounded-md cursor-pointer overflow-hidden">
                {excelData ? (
                  <table className="w-full text-sm">
                    <thead>
                      <tr className="text-gray-700 text-sm border-b border-gray-300 font-[600]  text-left">
                        {Object.keys(excelData[0]).map((key) => (
                          <th key={key} className="pb-8 py-2 font-[500]">
                            {key}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="font-[600] relative bg-white divide-gray-200">
                      {excelData.map((individualExcelData, index) => (
                        <tr key={index}>
                          {Object.keys(individualExcelData).map((key) => (
                            <td className="py-4 whitespace-nowrap" key={key}>
                              {individualExcelData[key]}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div>No File is uploaded yet.</div>
                )}
              </div>
            )}

            {!formik.values["excel_file"] && (
              <div className="border-dotted border-3 border-red-500 bg-gray-300 p-5 rounded-md text-center flex gap-2 items-center justify-center">
                <FaInfoCircle className="text-lg text-red-600" />
                {!formik.errors["excel_file"] && (
                  <p className="text-sm">
                    File size should not be above{" "}
                    <span className="font-[800]">5 MB</span>
                  </p>
                )}

                {formik.errors["excel_file"] && (
                  <p className="text-sm">{formik.errors["excel_file"]}</p>
                )}
              </div>
            )}

            {formik.values["excel_file"] && (
              <div className="border border-green-600 bg-green-100 p-5 rounded-md text-center flex gap-2 items-center justify-center">
                <FaCheckCircle className="text-lg text-green-600" />
                <p className="text-sm">File uploaded successfully</p>
              </div>
            )}
          </div>

          {(data && dataLength > 0) || (
            <>
              {!reservedAvailable && (
                <div className="flex flex-col">
                  <div className="mb-4">
                    <label
                      htmlFor="reserved_seats_or_table"
                      className="block mb-1 font-medium"
                    >
                      {isTable ? "Reserved Tables" : "Reserved Seats"}{" "}
                      <span className="text-gray-700 font-light">
                        (Seperate with comma)
                      </span>
                    </label>
                    <input
                      type="string"
                      id="reserved_seats_or_table"
                      name="reserved_seats_or_table"
                      placeholder="eg. 1,2,3,4,5"
                      className={getFieldStyle("reserved_seats_or_table")}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.reserved_seats_or_table}
                    />
                    {formik.touched.reserved_seats_or_table &&
                    formik.errors.reserved_seats_or_table ? (
                      <div className="text-red-500">
                        {formik.errors.reserved_seats_or_table}
                      </div>
                    ) : null}
                  </div>

                  {formik.values.reserved_seats_or_table && (
                    <div className="flex gap-2 flex-wrap">
                      {formik.values.reserved_seats_or_table
                        .split(",")
                        .map((item) => (
                          <span
                            key={item}
                            className="py-1 px-3 rounded-md text-sm bg-yellow-600 font-bold"
                          >
                            {item}
                          </span>
                        ))}
                    </div>
                  )}
                </div>
              )}
            </>
          )}

          {isTable && (
            <div>
              <div className="mb-4">
                <label
                  htmlFor="number_of_tables"
                  className="block mb-1 font-medium"
                >
                  Number of Tables
                </label>
                <input
                  type="number"
                  id="number_of_tables"
                  name="number_of_tables"
                  placeholder="eg. 10"
                  className={getFieldStyle("number_of_tables")}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.number_of_tables}
                />
                {formik.touched.number_of_tables &&
                formik.errors.number_of_tables ? (
                  <div className="text-red-500">
                    {formik.errors.number_of_tables}
                  </div>
                ) : null}
              </div>

              <div className="mb-4">
                <label
                  htmlFor="seats_per_table"
                  className="block mb-1 font-medium"
                >
                  Seats Per Table
                </label>
                <input
                  type="number"
                  id="seats_per_table"
                  name="seats_per_table"
                  placeholder="eg. 5"
                  className={getFieldStyle("seats_per_table")}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.seats_per_table}
                />
                {formik.touched.seats_per_table &&
                formik.errors.seats_per_table ? (
                  <div className="text-red-500">
                    {formik.errors.seats_per_table}
                  </div>
                ) : null}
              </div>
            </div>
          )}

          {!isTable && (
            <div>
              <div className="mb-4">
                <label htmlFor="max_seats" className="block mb-1 font-medium">
                  Max Seats
                </label>
                <input
                  type="number"
                  id="max_seats"
                  name="max_seats"
                  placeholder="eg. 10"
                  className={getFieldStyle("max_seats")}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.max_seats}
                />
                {formik.touched.max_seats && formik.errors.max_seats ? (
                  <div className="text-red-500">{formik.errors.max_seats}</div>
                ) : null}
              </div>
            </div>
          )}

          <div className="flex items-center justify-end gap-4 mt-2 pb-8">
            {formik.values.excel_file === null || (
              <Button
                onClick={() => formik.setFieldValue("excel_file", undefined)}
                variant="outlined"
                size="lg"
                colorScheme="danger"
              >
                Remove
              </Button>
            )}

            {canProceed && (
              <Button
                onClick={formik.handleSubmit}
                isLoading={formik.isSubmitting}
                size="lg"
                type="submit"
                variant="filled"
                colorScheme="primary"
              >
                Upload File
              </Button>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default BulkInvite;
