import moment from "moment";
import { useEffect, useState } from "react";
import { BsCaretDownFill } from "react-icons/bs";

const Controller = ({ children, fullWidth, onFilterChanged, isVariant }) => {
  const [years, setYears] = useState([]);

  useEffect(() => {
    const currentYear = moment().year();
    const yearOptions = [];

    for (let year = 2022; year <= currentYear; year++) yearOptions.push(year);

    setYears(yearOptions);
  }, []);

  return (
    <nav
      className={`flex lg:flex-row flex-col lg:items-center items-start lg:gap-0 gap-5  justify-between   ${
        fullWidth && "w-[max-content]"
      }`}
    >
      {children}

      <div
        className={`flex flex-wrap items-center justify-start gap-5 ${isVariant && "hidden"}`}
      >
        <span className="font-[600]">Filter by</span>
        <div className="flex gap-3 items-center">
          <div className="flex flex-wrap items-center space-x-2">
            {/* <label htmlFor="month" className="font-[600]">
            Filter by:
          </label> */}
            <div className="relative inline-flex">
              <select
                id="month"
                name="month"
                onChange={onFilterChanged}
                className="appearance-none bg-white border border-gray-300 rounded-md py-2 pl-3 pr-8 leading-5 font-medium focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">Every month</option>
                <option value="january">January</option>
                <option value="february">February</option>
                <option value="march">March</option>
                <option value="april">April</option>
                <option value="may">May</option>
                <option value="june">June</option>
                <option value="july">July</option>
                <option value="august">August</option>
                <option value="september">September</option>
                <option value="october">October</option>
                <option value="november">November</option>
                <option value="december">December</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-black">
                <BsCaretDownFill className="h-4 w-4" />
              </div>
            </div>
          </div>

          <div className="flex items-center space-x-2">
            {/* <label htmlFor="year" className=" font-[600]">
            Filter by:
          </label> */}
            <div className="relative inline-flex">
              <select
                id="year"
                name="year"
                onChange={onFilterChanged}
                className="appearance-none bg-white border border-gray-300 rounded-md py-2 pl-3 pr-8 leading-5 font-medium focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">Every year</option>
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-black">
                <BsCaretDownFill className="h-4 w-4" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Controller;
