import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { BsCalendarFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

const TruncateTableCell = ({ text, id, user, date, amountOfWords }) => {
  const cellRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const cellElement = cellRef.current;

    const truncateText = () => {
      const words = cellElement.innerText.split(" ");
      const truncatedText = words.slice(0, amountOfWords || 5).join(" ");
      const isGreaterThan5 = words.length >= (amountOfWords + 1 || 6);

      if (isGreaterThan5) {
        if (words[words.length - 1].includes("...")) return;
        return (cellElement.innerText = truncatedText + "...");
      }

      return (cellElement.innerText = truncatedText);
    };

    truncateText();
  }, []);

  return (
    <td
      className="py-4 flex gap-3 cursor-pointer hover:underline"
      onClick={() => navigate(`/event-detail/${id}`)}
    >
      {user && (
        <figure className="h-[2.5rem] w-[2.5rem] bg-yellow-600 items-center justify-center rounded-full font-[700] md:flex hidden">
          {user.initials}
        </figure>
      )}
      <div className="flex flex-col gap-1">
        <span className="font-[700] pr-5" ref={cellRef}>
          {text}
        </span>
        {date && (
          <div className="flex items-center gap-1 text-gray-700">
            <BsCalendarFill className="text-xs" />
            <span className="text-xs">{moment(date).format("ll")}</span>
          </div>
        )}
      </div>
    </td>
  );
};

export default TruncateTableCell;

TruncateTableCell.protoType = {
  text: PropTypes.string,
};
