import {
  Modal,
  ModalCloseButton,
  ModalBody,
  ModalContent,
  Box,
  ModalOverlay,
} from "@chakra-ui/react";

// eslint-disable-next-line react/prop-types
const ModalLayout = ({ children, ...props }) => {
  return (
    <Modal {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <ModalCloseButton />
          <Box my={5}>{children}</Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalLayout;
