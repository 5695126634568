import { useNavigate } from "react-router-dom";
import Loading from "../common/loading";
import { useQuery } from "react-query";
import { toast } from "react-hot-toast";
import { getData } from "../../utils/api";
import { useEffect } from "react";

const SignOut = () => {
  const navigate = useNavigate();
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("evently")}`,
    Accept: "application/json",
  };

  const { isSuccess, error } = useQuery({
    queryKey: ["logout"],
    queryFn: () =>
      fetch("https://api.eventlyhq.net/v1/admin/logout", {
        headers,
      }).then((data) => data.json()),
  });

  useEffect(() => {
    if (isSuccess) {
      localStorage.removeItem("evently");
      navigate("/sign-in");
    }
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      navigate("/");
      toast.error(error.message);
    }
  }, [error]);

  return (
    <div className="flex gap-2 flex-col min-h-full items-center justify-center bg-gray-300 ">
      <Loading text="Rounding up everything" />
    </div>
  );
};

export default SignOut;
