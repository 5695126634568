import { useNavigate } from "react-router-dom";
import Button from "../common/button";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { postData } from "../../utils/api";

const validationSchema = Yup.object().shape({
  notification_title: Yup.string().required("Required"),
  notification_body: Yup.string().max(1000).required("Required"),
});

const SendNotification = () => {
  const token = localStorage.getItem("evently");
  const mutation = useMutation({
    mutationFn: (data) => {
      return postData(`/admin/send_notification`, data, token);
    },
  });

  const handleSubmit = (values) => {
    console.log(values);
    mutation.mutate(values);
  };

  const getFieldStyle = (error, extraStyles) => {
    return `form-input bg-gray-200 px-5 py-3 outline-none border focus:border-green-600 rounded-md w-full text-md 
    scrollbar-track-gray-100 scrollbar-thin bg-white scrollbar-rounded-full appearance-none ${extraStyles}
    ${
      formik.touched[error] && formik.errors[error]
        ? "border-red-600 focus:border-red-600"
        : "border-transparent"
    }`;
  };

  const formik = useFormik({
    initialValues: {
      notification_title: "",
      notification_body: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const navigate = useNavigate();
  return (
    <div className="relative pt-10 pb-40 min-h-full w-full bg-gray-300 first-letter: grid grid-rows-[max-content_max-content_1fr]">
      <div className="max-w-[80rem] mx-auto lg:px-10 px-5 h-full w-full grid grid-rows-[max-content_1fr]">
        <div className="flex justify-between">
          <span className="text-gray-700 flex gap-2 items-center justify-start">
            <h5 className="font-[600] text-black">Send Notification</h5>
          </span>

          <Button
            size="md"
            variant="filled"
            colorScheme="danger"
            onClick={() => navigate(-1)}
          >
            Go back
          </Button>
        </div>

        <div className="flex flex-col gap-5 mt-10">
          <div className="mb-4 flex flex-col gap-2">
            <label
              htmlFor="notification_title"
              className="block mb-1 font-medium"
            >
              Title
            </label>
            <input
              type="text"
              id="notification_title"
              placeholder="eg. Lorem Ipsum"
              name="notification_title"
              onBlur={formik.handleBlur}
              className={getFieldStyle("notification_title")}
              onChange={formik.handleChange}
              value={formik.values.notification_title}
            />
            {formik.touched.notification_title &&
              formik.errors.notification_title && (
                <div className="text-red-600">
                  {formik.errors.notification_title}
                </div>
              )}
          </div>

          <div className="mb-4">
            <label
              htmlFor="notification_body"
              className="mb-1 font-medium flex gap-1"
            >
              <span>Content</span>
              <span className="text-gray-700 font-[400]">
                (1,000 characters)
              </span>
            </label>
            <textarea
              id="notification_body"
              placeholder="eg. Lorem Ipsum sit amore dolor"
              name="notification_body"
              className={getFieldStyle("notification_body", "h-[15rem]")}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.notification_body}
            />
            {formik.touched.notification_body &&
              formik.errors.notification_body && (
                <div className="text-red-600">
                  {formik.errors.notification_body}
                </div>
              )}
          </div>

          <div>
            <Button
              onClick={formik.handleSubmit}
              type="submit"
              variant="filled"
              size="md"
              colorScheme="success"
              isLoading={mutation.isLoading}
            >
              Send Notification
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendNotification;
