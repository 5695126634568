import { useOutsideClick } from "@chakra-ui/react";
import { useRef, useState } from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import Status from "../../common/status";
import Option from "./option";
import TruncateTableCell from "./truncateTableCell";
import PropTypes from "prop-types";

const TableBodyResponsive = ({ normalList, isVariant, variantList }) => {
  const list = isVariant ? variantList : normalList;

  const ref = useRef();
  const [isShowingOption, setIsShowingOption] = useState(false);
  useOutsideClick({
    ref: ref,
    handler: () => setIsShowingOption(false),
  });

  return (
    <tbody className="font-[600] relative bg-white divide-gray-200">
      {list.map((item, index) => (
        <tr key={item.uuid} className="text-md">
          <TruncateTableCell
            amountOfWords={2}
            text={item.event_title}
            date={item.created_at}
            id={item.uuid}
          />

          <td className="py-4 whitespace-nowrap">
            {item.event_status && (
              <Status variant={item.event_status.toLowerCase()} />
            )}
          </td>

          <td className="text-green-600 relative pl-10  whitespace-nowrap">
            <FiMoreHorizontal
              className="text-2xl cursor-pointer"
              onClick={() => setIsShowingOption(index)}
            />
            {isShowingOption === index && (
              <div ref={ref}>
                <Option
                  onClose={() => setIsShowingOption(false)}
                  request={item}
                />
              </div>
            )}
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default TableBodyResponsive;

TableBodyResponsive.protoType = {
  list: PropTypes.array,
};
