import { Outlet, useLocation } from "react-router-dom";
import SideBar from "./common/sidebar";
import NavBar from "./common/navbar";
import { useOutsideClick } from "@chakra-ui/react";
import { useRef } from "react";
import useStore from "./store";

const Layout = () => {
  const { pathname } = useLocation();

  const ref = useRef();
  const showMobileNav = useStore((state) => state.smallNav);
  const setShowMobileNav = useStore((state) => state.setNavState);

  useOutsideClick({
    ref: ref,
    handler: () => setShowMobileNav(false),
  });

  return (
    <>
      <section className="h-[100vh] overflow-hidden grid lg:grid-cols-[15rem_1fr] grid-cols-[1fr]">
        <div className="lg:block hidden">
          <SideBar activePath={pathname} />
        </div>

        {showMobileNav && (
          <div className="fixed w-full h-full z-30 lg:hidden">
            <div className="absolute top-0 left-0 z-10 w-full h-full bg-black bg-opacity-5 cursor-pointer"></div>
            <div
              ref={ref}
              className="absolute top-0 left-0 z-20  w-[15rem] h-full"
            >
              <SideBar isVariant activePath={pathname} />
            </div>
          </div>
        )}

        <main>
          <nav>
            <NavBar />
          </nav>

          <section className="lg:h-screen h-[82vh] overflow-y-auto scrollbar-track-gray-100 scrollbar-thin scrollbar-rounded-full">
            <Outlet />
          </section>
        </main>
      </section>
    </>
  );
};

export default Layout;
