import { create } from "zustand";

const statsStore = create((set) => ({
  statsLoading: false,
  stats: {
    approved: 0,
    archive: 0,
    events: {
      completed: 0,
      lastMonth: 0,
    },
  },
  setStats: (stats) => set({ stats: stats }),
  setStatsLoading: (value) => set({ statsLoading: value }),
}));

export default statsStore;
